import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import React from "react";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          textOk: 'Ok',
          FAQ: 'Help',
          English: 'English',
          Catalan: 'Català',
          Spanish: 'Español',
          French: 'Français',
          textTitle: 'Text translation page',
          documentTitle: 'Document translation page',
          loginTitle: 'Sign In',
          statisticTitle: 'Statistics page',
          textAreaAnnouncementBox1:
              'text area where you can write text to be translated',
          textAreaAnnouncementBox2:
              'text area where the text translation will appear once the translation is done.',
          AnnounceModalCloseButton: 'Close',
          AnnounceDialogOpened: 'Dialog is opened',
          AnnounceDialogClosed: 'Dialog is closed',
          AnnounceTextisCleared: 'Text is cleared',
          AnnounceCopyTransactionRecordText: 'Copy transaction record',
          AnnounceCopiedToClipboard: 'Copied to clipboard',
          AnnounceCopyTranslatedText: 'Copy translated text',
          ClearTextLabelandTippy: 'Clear text',
          AnnounceTranslatedText: 'Translated text',
          headerH1: 'Machine Translation Service',
          headerLogout: 'Sign Out',
          headerAccountSettings: 'Account Settings',
          headerVersion: 'Version',
          dropdownWebsiteLanguage: 'set website language',
          dropdownUserSetting: 'user setting',
          dropdownsourceLang: 'Source language',
          dropdowntargetLang: 'Target language',
          operations: 'Origin',
          allOperations: 'All',
          textTranslateOperations: 'Text translation',
          documentTranslateOperations: 'Document translation',
          speechDocumentOperations: 'Speech to text',
          transliteration: "Transliteration",
          websiteTranslateOperations: "Website translation",
          speechTranscription: "Speech transcription",
          words: 'Words',
          characters: 'Characters',
          scale: 'Scale',
          total: 'Total',
          linearScale: 'Linear',
          sqrtScale: 'Square Root',
          dropdownSelected: 'is selected',
          sidebarTippyText: 'Text Translator',
          sidebarText: 'Text',
          sidebarDocument: 'Document',
          sidebarTippyDocument: 'Document Translator',
          loginh1: 'Sign in required',
          loginButton: 'Sign In',
          textH2NavigateLanguageTranslationDropDown:
              'Select source and target languages',
          textTippySwap: 'Swap languages',
          textTippySwapBlocked: 'Cannot swap ‘Detect Language’ ',
          textTippySwapBlockedMulti: 'Cannot swap ‘Multilingual’ ',
          textH2NavigateTextTranslationArea: 'Text translation area',
          textSourceTextareaPlaceholder: 'Type or paste your text here...',
          textSourceTextareaButton: 'Select files',
          textTranslateButton: 'Translate',
          textCopyTippy: 'Copy text',
          documentH1: 'Document Translation',
          documentDroph2: 'Drag and drop files or',
          documentDrophSelect: 'select them on your computer',
          documentDrophMaxSize: 'Max file size <strong>50MB</strong>, ZIP files up to <strong>512MB</strong>',
          dragAndDropTooltip: 'Drag and drop',
          documentDropFileMessage: 'Supported file formats: ', //doc, docm, docx, dot, dotm, dotx, html, odp, ods, odt, ppt, pptx, rtf, tex, txt, xls, xlsm, xlsx, xml
          documentH2: 'Translations',
          documentSubHeaderDate: 'Date',
          documentSubHeaderSubName: 'Name',
          documentSubHeaderSource: 'Languages',
          documentSubHeaderTarget: 'Target language',
          documentSubHeaderFiles: 'Download',
          documentSubHeaderPages: 'Delete',
          modalMsgEmlAutoDetectError: 'Please, select the original language of your email on “Source language” to translate it successfully.',
          modalPdfAutodetectOcrError: 'Please, select the original language of your images or pdfs on “Source language” to translate them successfully.',
          mandatoryCategoryAndSubmissionNameError: 'Please, select a category and a submission name to continue with your translation.',
          mandatorySubmissionNameError: 'Please, select a submission name to continue with your translation.',
          mandatoryCategoryError: 'Please, select a category to continue with your translation.',
          submissionNameHeader: 'Submission name',
          submissionNameMaxLength: 'Submission name can be up to 180 characters',
          fileNameHeader: 'File name',
          categoryHeader: 'Category',
          categoryPlaceholder: 'No category',
          submissionNamePlaceholder: 'Type here...',
          fileStatusSuccessful: 'Translated',
          downloadPdfError: 'We can’t convert the uploaded file format to pdf.',
          downloadFailedError: 'Unable to download file because translation failed.',
          downloadingStatisticsMessage: 'Creating your report, this might take a few minutes...',
          downloadingStatisticsError: 'We found an error creating your report, please try again or contact support.',
          documentSubHeaderActions: 'Actions',
          documentSubHeaderWCount: 'Word count:',
          documentSubHeaderCCount: 'Char count:',
          documentSubHeaderStatus: 'Status',
          documentSubRowStatusDeleting: 'Deleting...',
          documentSubRowChildStatusTranslating: 'Translating',
          documentSubRowChildStatusTranslated: 'Translation successful',
          documentSubRowChildStatusTranslatedSuccess: 'Translated',
          documentSubRowChildStatusError: 'Translation failed',
          documentSubRowChildStatusFailed: 'Failed',
          documentSubRowChildStatusUploading: 'Uploading',
          documentSubRowChildDeleteTippy: 'Delete file',
          documentFileIconTippy: 'File',
          documentSubRowChildDownloadTippy: 'Download translated file',
          documentSubRowChildDownloadSourceTippy: 'Download source file',
          documentSubRowChildDownloadHeader: 'Download',
          documentSubRowChildDownloadLoadingTippy: 'Loading download',
          documentSubRowChildCopyToClipboardTippy: 'Copy to clipboard',
          documentSubRowChildCopiedToClipboardTippy: 'Copied to clipboard',
          documentSubRowChildFailedTranslationHelpTippy: 'Failed translation help',
          documentSubRowChildFailedTranslationHelpCloseTippy: 'Close failed translation help',
          documentSubRowChildDownloadSourceDropdown: 'Download source file',
          documentSubRowChildDownloadMoreOptions: 'More download options',
          documentSubRowChildExpand: 'Expand',
          documentSubRowChildCollapse: 'Collapse',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Zip and download',
          documentSubRowZipHelp: 'Zip help',
          documentSubRowDownloadZip: 'Download zip',
          documentSubRowDownloadZipError: 'Error zipping',
          documentSubRowChildHumanTranslationRequestedTippy: 'Human translation requested',
          documentSubRowChildHumanTranslationFailedTippy: 'Human translation request failed',
          documentSubRowChildRequestHumanTranslationTippy: 'Request human translation',
          documentSubRowChildCloseRequestHumanTranslationTippy: 'Close human translation menu',
          documentSubRowChildRequestHumanTranslationLoadingTippy: 'Loading request',
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: 'Opens GL Portal in a new tab',
          documentSubRowChildHumanTranslationRequestInProcess: 'Human translation request in process.',
          documentSubRowChildHumanTranslationRequestFinishOnTransport: 'Finish on GL Portal',
          documentSubRowChildHumanTranslationRequest: 'Request a human translation',
          documentSubRowChildHumanTranslationRequested: 'Human translation requested',
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: 'Check status on GL Portal',
          documentSubRowChildHumanTranslationRequestGoToTransport: 'Go to GL Portal',
          documentSubRowChildHumanTranslationRequestAdditionalCost: ' (additional cost)',
          documentSubRowChildEditTranslatedFile: 'Edit translated file',
          documentSubRowChildMoreActionsTooltip: 'More actions',
          supportEmailBody: 'Important information, do not delete:',
          helpSentencePartOne: 'For help, click ',
          helpSentencePartTwo: 'here',
          helpSentencePartTwoHover: 'This will open a new email message to Support',
          helpSentencePartThree: ' to contact support describing your issue in the body of your email.',
          documentSubRowChildDownloadPdfDropdown: 'Download as pdf',
          documentSubRowChildLanguageTo: 'to',
          documentTippyAdd: 'Select files',
          deleteModalTitle: 'Delete file',
          deleteModalProblem: 'We are having issues deleting your file. Please, close this modal and retry.',
          deleteModalCheck: 'Are you sure you want to delete this file?',
          deleteModalCancel: 'Cancel',
          deleteModalDelete: 'Delete',
          unsupportedFileMessage: 'We can’t translate one or more files. Please, convert them and try again.',
          unsupportedSourceLang: 'The detected language is not available in your organization. For more languages contact the person managing the portal in your org.',
          modalH1: 'Upload your files',
          modalH1AfterUpload: 'Files uploaded',
          modalPTop: 'You selected the following files to be translated:',
          modalTippyAdd: 'Add more files',
          modalConfirm: 'Confirm source and target languages',
          modalTippySwap: 'Swap languages',
          modalTranslate: 'Translate',
          modalLoading: 'Loading...',
          modalUploadErrorMessagePartOne:
              'We found an error uploading your files.',
          modalUploadErrorMessagePartTwo:
              'Please try again.',
          modalUploadErrorMessagePartThree:
              'If the problem persists, ',
          modalUploadErrorMessagePartFour:
              'contact support.',
          modalUploadSuccessful: 'Translation in progress',
          processingRequestMsg: 'Processing request...',
          copyright: ' Translations.com All rights reserved.',
          statistics: 'Statistics',
          statisticFrom: 'From:',
          statisticTo: 'To:',
          statisticDownload: 'Download Report',
          textTranslationReachedLimit: 'You can translate up to 10000 characters per request',
          users: 'User',
          allUsers: 'All',
          allLanguages: 'All',
          loadTimeFrame: 'Load Time Frame',
          statisticErrorTime: 'Starting date seems to be after the end date. Please, pick a starting date before the end date.',
          statisticErrorFutureDate: 'Starting date seems to be in the future. Please, pick a starting date prior to today.',
          noDataError: 'We couldn’t find any translations between these dates. If you think it’s a mistake, support is available.',
          statisticRequestError:
              'We found an error creating your report, please try again.',
          'OCR-03-A2A-FAIL': 'We found an error translating your file. Please, try again',
          'OCR-03-A2A-TIMEOUT': 'We found an error translating your file. Please, try again',
          'OCR-03-A2A-PASSWORD': 'Error, remove password from pdf',
          'OCR-03-A2A-PASSWORD-desc-pdf':
              'PDF is password protected, please remove password from pdf',
          'AP-08-001': 'Error, remove password from pdf',
          'AP-08-001-desc-pdf':
              'PDF is password protected, please remove password from pdf',
          'OCR-03-A2A-SIZE-EX':
              'Max. page size to perform pdf conversion is A4',
          quickLinksLabel: 'Quick links:',
          'OCR-03-A2A-HEALTH': 'OCR temporarily unavailable, try again',
          'MT-error': 'Error with the translation engine',
          'WFM-error': 'We found an error processing your file. Please, try again',
          'WFM-error-desc': 'We found an error processing your file. Please, try again',
          'AP-12-001': 'File seems to be empty, please check and try again.',
          'AP-12-001-desc': 'File seems to be empty, please check and try again.',
          'AP-12-001-desc-pdf':
              'No text was detected. Please, try again making sure OCR is enabled.',
          'AP-01-003-desc': 'File exceeds word limits for your organisation. Please, divide it into smaller pieces and try again.',
          'MT-error-desc': 'Error with the translation engine',
          'WFM-04-INVLANG': 'The detected language is not available in your organization. For more languages contact the person managing the portal in your org.',
          'WFM-04-INVLANG-desc': 'The detected language is not available in your organization.For more languages contact the person managing the portal in your org.',
          'WFM-04-SRCTRGSAME': 'Source and target language cannot be the same. Please, modify one of them',
          'WFM-04-SRCTRGSAME-desc':
              'Source and target language cannot be the same. Please, modify one of them',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'Source and target language cannot be the same. Please, modify one of them',
          'USRMNG-DB-1062': 'This user already exists. Please,search for it and make the corresponding changes.',
          'USRMNG-DB-1452': 'Invalid value',
          'STT-DOC-04-SIZE': 'Maximum wav size is 200MB. Please, divide your file and try again.',
          'STT-fileSIZE': 'You can translate files up to 50MB. Please, remove bigger files, divide them into smaller ones and try again.',
          'STT-fileSIZE-zip': 'You can translate zip files up to 512MB. Please, remove bigger files, divide them into smaller ones and try again.',
          TT_WAV_ERROR_MULTIPLE_FILES:
              'Please, upload one wav file at the time or try document translate to translate it',
          TT_WAV_ERROR_NO_FILES_DETECTED: "We couldn't detect any WAV files",
          TT_WAV_ERROR_AUTO_DETECT:
              'Please, select the original language of your wav files on “Source language” to translate them successfully.',
          TT_WAV_ERROR_FILE_TYPE:
              'Use document translate to translate this type of file ',
          TT_WAV_ERROR_FILE_SIZE: 'Maximum wav size is 200MB. Please, divide your file and try again',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':
              'Please, translate PDF and wav files separately',
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT:
              'Transcription is still processing. The transcription will be lost if you exit this page now.',
          transliterationTippy: 'Transliterate text',
          qualityTranslationEngineInfo: "TransPerfect Main Engine allows you to be informed about the level of fidelity our translation has according to your requirements. Test it!",
          infoQualityControlFeature: "Quality control is the feature that allows you to be informed about the level of fidelity our translation has according to your needs. The level of fidelity is defined as the accuracy of the translation we are providing you as stated by your requirements.",
          infoQualityControlFeatureTooltip: 'To ensure the quality and transparency of our product, TransPerfect Main Engine allows you to be informed about the level of fidelity our translation has according to your needs.',
          infoQualityControlFeatureTooltipLearnMore: 'Learn more',
          translationQualityGood: 'Translation looks great!',
          translationQualityAverage: 'Translation might have some errors, use with caution!',
          translationQualityBad: 'Translation is likely to be problematic, revision is recommended.',
          translationQualityGoodModal: 'Good',
          translationQualityAverageModal: 'Use with caution',
          translationQualityBadModal: 'Likely problematic',
          translationQualityGoodModalDescription: 'You can rely on this translation.',
          translationQualityAverageModalDescription: 'There might be some imperfections in the translation. We recommend its use internally. For external use, we advise you to check it with a professional translator.',
          translationQualityBadModalDescription: 'This translation might have some major errors. We advise you to correct it with a professional translator.',
          websiteTranslator: 'Website',
          websiteTranslatorTippy: 'Website Translator',
          urlInputPlaceHolder: 'Paste your URL here...',
          websiteTranslateAction: 'Translate',
          removeAllPreviousWebsiteTranslateRequests: 'Clear all',
          previousWebsiteTranslateRequestTitle: 'Request history:',
          wtLoadingCaption: 'Translating website...',
          ocrCheckboxLabel: 'I am uploading images and scanned documents',
          ocrHelpText: ' When to activate this',
          ocrCheckboxTippy:
              'OCR (Optical character recognition) detects text within images and scanned documents. Try this option if you have received an empty translation or the text content of your pdf is not selectable.',
          websitePage: 'Website translation page',
          lastWTRequestURL: 'Last website translation request:',
          openWebsiteBtnLabel: 'Open',
          invalidURL: 'Invalid URL format',
          ClearURLLabelandTippy: 'Clear URL',
          inactivityLogoutTitle: 'You have been signed out',
          inactivityLogoutMessage: 'You have been signed out due to inactivity',
          inactivityLogoutButtonLabel: 'Sign back in',
          logoutScreenMessage: 'Thanks for stopping by!',
          logoutScreenLogoutButtonLabel: 'Sign back in',
          langSelect_NoResultsMessage: 'No results found',
          textTranslationError: 'Translation failed. Support is available for help.',
          reachedQuotaLimitError: 'Your organization reached its translations limits. Contact the person managing the portal in your org to extend these limits.',
          orgExpiredError: 'Your organization has expired.Contact the person managing the portal in your org to extend these limits.',
          menuLabel: 'Menu',
          downloadSourceFileSubheader: 'Source',
          downloadTranslatedFileSubheader: "Result",
          // statistics data view
          dataView: 'Data',
          graphView: 'Graph',
          mainTitleStatisticsDataView: 'Organization statistics on selected period',
          genericColumnTitleStatisticsDataView: 'Generic',
          textTranslationTitleStatisticsDataView: 'Text Translation',
          documentTranslationTitleStatisticsDataView: 'Document Translation',
          wordsTranslatedField: 'Words translated',
          charsTranslatedField: 'Characters translated',
          ratioTextDocumentField: 'Text translation/Document translation ratio',
          mostUsedLangsField: 'Most used language pair',
          totalTextRequestsField: 'Total requests',
          totalDocsTranslatedField: 'Total docs translated',
          userColumn: 'User',
          numberOfWordsColumn: 'Number of words',
          currentQuota: 'Current quota status',
          sortTableCancel: 'Click to cancel sorting',
          sortTableAscending: 'Click to sort ascending',
          sortTableDescending: 'Click to sort descending',


          // Lang names
          af: 'Afrikaans',
          am: 'Amharic',
          ar: 'Arabic',
          as: 'Assamese',
          'oc-ES': 'Aranese',
          auto: 'Detect Language',
          ay: 'Aymara',
          eu: 'Basque',
          ba: 'Bashkir',
          bg: 'Bulgarian',
          bm: 'Bambara',
          bn: 'Bangla',
          bo: 'Tibetan',
          bs: 'Bosnian',
          ca: 'Catalan',
          co: 'Corsican',
          cs: 'Czech',
          cy: 'Welsh',
          da: 'Danish',
          dv: 'Divehi',
          ee: 'Ewe',
          gl: 'Galician',
          gn: 'Guarani',
          de: 'German',
          el: 'Greek',
          en: 'English',
          'en-CA': 'English (Canada)',
          'en-US': 'English (United States)',
          'en-GB': 'English (United Kingdom)',
          es: 'Spanish',
          'es-LA': 'Spanish (Latin America)',
          'es-MX': 'Spanish (Mexico)',
          'es-XM': 'Spanish (International)',
          'es-US': 'Spanish (United States)',
          et: 'Estonian',
          fa: 'Persian',
          'fa-AF': 'Dari',
          fi: 'Finnish',
          fo: 'Faroese',
          tl: 'Filipino',
          fj: 'Fijian',
          fr: 'French',
          'fr-BE': 'French (Belgium)',
          'fr-CA': 'French (Canada)',
          'fr-FR': 'French (France)',
          he: 'Hebrew',
          Hans: 'Chinese Simplified (transliteration)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Croatian',
          ht: 'Haitian Creole',
          hu: 'Hungarian',
          hy: 'Armenian',
          ic: 'Ilocano',
          id: 'Indonesian',
          is: 'Icelandic',
          it: 'Italian',
          iu: 'Inuktitut',
          'iu-Latn': 'Inuktitut (Latin)',
          ja: 'Japanese',
          Jpan: 'Japanese (transliteration)',
          km: 'Khmer',
          ko: 'Korean',
          ka: 'Georgian',
          ks: 'Kashmiri',
          'lb-LU': 'Luxembourgish',
          lo: 'Lao',
          ln: 'Lingala',
          lt: 'Lithuanian',
          lv: 'Latvian',
          mg: 'Malagasy',
          mk: 'Macedonian',
          mn: 'Mongolian',
          ms: 'Malay',
          mt: 'Maltese',
          my: 'Burmese',
          multi: 'Multilingual',
          mww: 'Hmong Daw',
          ne: 'Nepali',
          nk: 'Konkani',
          no: 'Norwegian',
          nn: 'Norwegian Nynorsk',
          nl: 'Dutch',
          ns: 'Sepedi',
          'nl-BE': 'Dutch (Belgium)',
          oc: 'Occitan',
          om: 'Oromo',
          otq: 'Querétaro Otomi',
          pl: 'Polish',
          pt: 'Portuguese',
          'pt-PT': 'Portuguese (Portugal)',
          'pt-BR': 'Portuguese (Brasil)',
          ro: 'Romanian',
          rw: 'Kinyarwanda',
          ru: 'Russian',
          qu: 'Quechua',
          sa: 'Sanskrit',
          sk: 'Slovak',
          sl: 'Slovenian',
          si: 'Sinhala',
          sm: 'Samoan',
          sr: 'Serbian',
          'sr-RS-CYRL': 'Serbian (Cyrillic)',
          'sr-RS-LATN': 'Serbian (Latin)',
          sv: 'Swedish',
          sw: 'Kiswahili',
          ta: 'Tamil',
          th: 'Thai',
          ti: 'Tigrinya',
          to: 'Tongan',
          tr: 'Turkish',
          tn: 'Setswana',
          ty: 'Tahitian',
          tw: 'Twi',
          uk: 'Ukrainian',
          ur: 'Urdu',
          vi: 'Vietnamese',
          yua: 'Yucatec Maya',
          yue: 'Cantonese',
          sq: 'Albanian',
          az: 'Azerbaijani',
          be: 'Belarusian',
          eo: 'Esperanto',
          gd: 'Gaelic (Scotland)',
          ga: 'Gaelic (Ireland)',
          kn: 'Kannada',
          gu: 'Gujarati',
          ha: 'Hausa',
          la: 'Latin',
          mr: 'Marathi',
          tg: 'Tajik',
          te: 'Telugu',
          uz: 'Uzbek',
          xh: 'Xhosa',
          yi: 'Yiddish',
          zu: 'Zulu',
          cb: 'Cebuano',
          fy: 'Frisian',
          hw: 'Hawaiian',
          ig: 'Igbo',
          jv: 'Javanese',
          kk: 'Kazakh',
          kmr: 'Kurdish (Northern)',
          ku: 'Kurdish',
          ky: 'Kyrgyz',
          ml: 'Malayalam',
          mi: 'Maori',
          ny: 'Nyanja',
          or: 'Odia',
          ps: 'Pashto',
          pa: 'Punjabi',
          rn: 'Rundi',
          st: 'Sesotho',
          sn: 'Shona',
          sd: 'Sindhi',
          so: 'Somali',
          su: 'Sundanese',
          tt: 'Tatar',
          tk: 'Turkmen',
          ts:'Tsonga',
          ug: 'Uyghur',
          yo: 'Yoruba',
          'zh-CN': 'Chinese (Simplified)',
          'zh-TW': 'Chinese (Traditional)',
          'zh-HK': 'Chinese (Hong Kong)',
        },
      },
      ca: {
        translations: {
          textOk: 'Entesos',
          FAQ: 'Ajuda',
          English: 'English',
          Catalan: 'Català',
          Spanish: 'Español',
          LangCode: 'ca',
          loginTitle: "Pàgina d'inici de sessió",
          textTitle: 'Pàgina de traducció de text',
          documentTitle: 'Pàgina de traducció de documents',
          statisticTitle: 'Pàgina de estadísticas',
          textAreaAnnouncementBox1:
              'àrea de text on podeu escriure text per traduir',
          textAreaAnnouncementBox2:
              'àrea de text on apareixerà la traducció de text un cop realitzada la traducció.',
          AnnounceModalCloseButton: 'Tanca',
          AnnounceDialogOpened: "S'obre el diàleg",
          AnnounceDialogClosed: 'Es tanca el diàled',
          AnnounceTextisCleared: 'El text s’esborra',
          AnnounceCopyTransactionRecordText: 'Còpia de registre de transaccions',
          AnnounceCopiedToClipboard: 'Copiat al porta-papers',
          AnnounceCopyTranslatedText: 'Copia el text traduït',
          ClearTextLabelandTippy: 'Esborra el text',
          AnnounceTranslatedText: 'Text traduït',
          headerH1: 'Servei de traducció automàtica',
          headerLogout: 'Tanca sessió',
          headerAccountSettings: 'Configuració del compte',
          headerVersion: 'Versió',
          headerAriaLabelLanguageMenu: 'seleccioneu l’idioma del lloc web',
          headerAriaLabelMenu: "menú d’usuari i d'aplicacions",
          headerAriaSetEnglish: "configura l'idioma a l'anglès",
          headerAriaSetSpanish: "configura l’idioma a l'espanyol",
          headerAriaSetCatalan: 'configura l’idioma al català',
          headerWebsiteLanguage: 'configurar l’idioma del lloc web',
          dropdownWebsiteLanguage: 'configurar l’idioma del lloc web',
          dropdownUserSetting: 'configuració d’usuari',
          dropdownsourceLang: 'Idioma d’origen',
          dropdowntargetLang: 'Idioma objectiu',
          operations: 'Origen',
          scale: 'Escala',
          linearScale: 'Lineal',
          sqrtScale: 'Arrel quadrada',
          words: 'Paraules',
          characters: 'Caràcters',
          dropdownSelected: 'està seleccionada',
          sidebarTippyText: 'Traducció de text',
          sidebarText: 'Text',
          sidebarDocument: 'Document',
          sidebarTippyDocument: 'Traducció de documents',
          sidebarTippyStatistic: 'Estadístiques',
          loginh1: 'Cal iniciar la sessió',
          logoutScreenLogoutButtonLabel: 'Torna a iniciar sessió',
          inactivityLogoutButtonLabel: 'Torna a iniciar sessió',
          logoutScreenMessage: 'Gràcies per la visita!',
          inactivityLogoutMessage: 'La teva sessió ha expirat per inactivitat',
          loginButton: 'Inicia sessió',
          textH1: 'Traducció de text',
          textH2NavigateLanguageTranslationDropDown:
              "Seleccioneu idiomes d'origen i de destí",
          textOrLabel: 'O',
          textDetectLabel: "Detecta l'idioma",
          textTippySwap: 'Intercanvia els idiomes',
          textTippySwapBlocked: '‘Detecta l’idioma’ no es pot intercanviar ',
          textTippySwapBlockedMulti: '‘Multilingüe’ no es pot intercanviar',
          textH2NavigateTextTranslationArea: 'Àrea de traducció de text',
          textSourceTextareaPlaceholder: 'Escriu o enganxa el text aquí',
          textTranslateButton: 'Tradueix',
          textCopyTippy: 'Copia el text',
          documentH1: 'Traducció de documents',
          documentOrLabel: 'O',
          documentDetectLabel: "Detecta l'idioma",
          documentTippyNoFilesSelected: 'No hi ha documents seleccionats.',
          documentTippySubNameEmpty:
              'El nom de la presentació no pot estar buit.',
          documentDroph2: 'Arrossega documents i deixa’ls anar aquí, o bé',
          documentDrophMaxSize: 'Max arxiu mida <strong>50MB</strong>, arxius ZIP fins a <strong>512MB</strong>',
          dragAndDropTooltip: 'Arrossegar i deixar anar',
          documentDrophSelect: 'seleccioneu-los en el vostre ordinador',
          documentDropFileMessage: 'Formats admesos: ', //doc, docm, docx, dot, dotm, dotx, html, odp, ods, odt, ppt, pptx, rtf, tex, txt, xls, xlsm, xlsx, xml
          documentH2: 'Traduccions',
          documentDropPAccepted:
              'Formats acceptats: doc, docx, xls, xlsx, ppt, txt, RTF, txlm, txlf',
          documentDropFormOcr: '% s pàgines OCR',
          documentDropFormWords: 'Paraules% s',
          documentDropFormPlaceholder: 'nom de la presentació',
          documentDropFormTranslate: 'Traduir',
          documentDropFormAddfiles: 'Afegir documents',
          documentDropFormCancel: 'Cancel·lar',
          documentDropUploadRowUploading: 'Carregar...',
          documentDropUploadRowUploaded: 'Carregada',
          documentSubHeaderDate: 'Data',
          documentSubHeaderSubName: 'Nom',
          documentSubHeaderSource: 'Idiomes',
          documentSubHeaderTarget: 'Idioma objectiu',
          documentSubHeaderFiles: 'Baixa',
          documentSubHeaderPages: 'Suprimeix',
          submissionNameHeader: "Nom de treball",
          submissionNameMaxLength: 'El nom de l\'entrada pot ser de fins a 180 caràcters.',
          fileNameHeader: "Nom de l’arxiu",
          categoryHeader: "Categoria",
          categoryPlaceholder: "Sense categoria",
          submissionNamePlaceholder: "Escriu aquí…",
          fileStatusSuccessful: "Traduït",
          downloadFailedError: "No es pot descarregar l’arxiu perquè hi ha hagut un error de traducció.",
          downloadingStatisticsMessage: "Creant l'arxiu, aquesta operació pot tardar uns minuts...",
          downloadingStatisticsError: "Hem trobat un error al crear l'arxiu, si us plau, torna a intentar-ho o possat en contacte amb suport.",
          documentSubHeaderActions: "Accions",
          documentSubHeaderWCount: "Recompte de paraules:",
          documentSubHeaderCCount: "Recompte de caràcters:",
          documentSubHeaderStatus: 'Estat',
          documentSubRowStatusTranslating: 'Traduir',
          documentSubRowStatusTranslated: 'Traduït',
          documentSubRowStatusDeleting: 'Suprimir...',
          documentSubRowStatusError: 'Error',
          documentSubRowChildStatusTranslating: 'Traducció en curs',
          documentSubRowChildStatusTranslated: 'La traducció ha tingut èxit',
          documentSubRowChildStatusTranslatedSuccess: "Traduït",
          documentSubRowChildStatusError: 'La traducció ha fallat',
          documentSubRowChildStatusFailed: 'Error',
          documentSubRowChildStatusUploading: 'Pujada',
          documentSubRowChildDeleteTippy: 'Esborra el document',
          documentFileIconTippy: 'Fitxer',
          documentSubRowChildDownloadTippy: 'Baixa el document traduït',
          documentSubRowChildDownloadSourceTippy: 'Baixa el document font',
          documentSubRowChildDownloadHeader: 'Descarregar',
          documentSubRowChildDownloadLoadingTippy: "Carregant la descàrrega",
          documentSubRowChildCopyToClipboardTippy: "Copiar al porta-retalls",
          documentSubRowChildCopiedToClipboardTippy: "Copiat al porta-retalls",
          documentSubRowChildFailedTranslationHelpTippy: "Ajuda sobre l’error de traducció",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Tancar Ajuda sobre l’error de traducció",
          documentSubRowChildDownloadSourceDropdown: "Descarregar com a format font",
          documentSubRowChildDownloadMoreOptions: 'Més opcions de descàrrega',
          documentSubRowChildExpand: 'Expandir',
          documentSubRowChildCollapse: 'Col·lapsar',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Zip i descàrrega',
          documentSubRowZipHelp: 'Ajuts de Zip',
          documentSubRowDownloadZip: 'Descàrrega zip',
          documentSubRowDownloadZipError: 'Errors de zipping',
          documentSubRowChildHumanTranslationRequestedTippy: "S’ha sol·licitat una traducció humana.",
          documentSubRowChildHumanTranslationFailedTippy: "Hi ha hagut un error amb la sol·licitud de traducció humana.",
          documentSubRowChildRequestHumanTranslationTippy: "Sol·licitar la traducció humana",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Tancar el menú de traducció humana",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Carregant la sol·licitud",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Obre GL Portal en una nova pestanya.",
          documentSubRowChildHumanTranslationRequestInProcess: "Sol·licitud de traducció humana en procés.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Finalitzar en GL Portal",
          documentSubRowChildHumanTranslationRequest: "Sol·licitar una traducció humana",
          documentSubRowChildHumanTranslationRequested: "S’ha sol·licitat una traducció humana.",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Comprovar l’estat a GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Anar a GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (cost addicional)",
          documentSubRowChildEditTranslatedFile: 'Edita el fitxer traduït',
          documentSubRowChildMoreActionsTooltip: 'Més accions',
          helpSentencePartOne: "Per obtenir ajuda, fes clic ",
          helpSentencePartTwo: "aquí",
          helpSentencePartTwoHover: "S'obrirà un nou missatge de correu electrònic per suport",
          helpSentencePartThree: '. Descriu el teu problema en el cos del teu correu electrònic.',
          documentSubRowChildDownloadPdfDropdown: 'Descarregar el PDF',
          documentSubRowChildLanguageTo: 'a',
          documentTippyAdd: 'Selecciona documents',
          deleteModalTitle: 'Suprimeix el document',
          deleteModalCheck: 'Segur que vols suprimir aquest document?',
          deleteModalCancel: 'Cancel·la',
          deleteModalDelete: 'Suprimeix',
          modalH1: 'Penja documents',
          modalH1AfterUpload: 'Documents penjats',
          modalPTop: 'Has seleccionat els documents següents per traduir:',
          modalTippyAdd: 'Afegeix més documents',
          modalConfirm: "Confirma els idiomes d'origen i de destí",
          modalTippySwap: 'Intercanvia els idiomes',
          modalTranslate: 'Tradueix',
          modalLoading: 'Carregant…',
          modalUploadSuccessful: 'Traducció en curs',
          processingRequestMsg: 'Processant la petició…',



          modalUploadErrorMessagePartOne:'Hem trobat un error en pujar els arxius.',
          modalUploadErrorMessagePartTwo:'Si us plau, torneu a intentar-ho.',
          modalUploadErrorMessagePartThree:'Si el problema persisteix ',
          modalUploadErrorMessagePartFour: 'communiquer avec le soutien technique.',
          modalMsgEmlAutoDetectError: 'Si us plau, seleccioni l\'idioma original del seu correu electrònic a \\"Llengua de curs\\" per traduir-lo amb èxit.',
          modalPdfAutodetectOcrError: 'Please, select the original language of your images or pdfs on “Source language” to translate them successfully.',
          mandatoryCategoryAndSubmissionNameError: 'Si us plau, seleccioneu l\'idioma original de les vostres imatges o PDFs a \\"Llengua de curs\\" per traduir-les amb èxit.',
          mandatorySubmissionNameError: 'Si us plau, seleccioneu un nom de presentació per continuar amb la vostra traducció.',
          mandatoryCategoryError: 'Seleccioneu una categoria per continuar amb la vostra traducció.',
          deleteModalProblem: 'Tenim problemes per esborrar el teu arxiu. Si us plau, tanqueu aquesta modal i torneu a provar.',
          unsupportedFileMessage: 'No es pot traduir un o més documents. Si us plau, convertiu-los i torneu a intentar-ho.',
          statisticErrorTime: 'La data d\'inici sembla ser després de la data final. Seleccioneu una data d\'inici abans de la data de finalització.',
          statisticErrorFutureDate: 'La data d’inici sembla ser en el futur. Si us plau, escolliu una data d\'inici abans d\'avui.',
          unsupportedSourceLang: 'El llenguatge detectat no està disponible a la vostra organització. Per a més idiomes contacti amb la persona que gestiona el portal en el seu org.',
          reachedQuotaLimitError: 'L’organització ha arribat als seus límits de traducció. Poseu-vos en contacte amb la persona que gestiona el portal en la vostra org per ampliar aquests límits.',
          orgExpiredError: 'No es pot traduir perquè la seva organització ha arribat a la seva data de caducitat. Poseu-vos en contacte amb la persona que gestiona el portal en la vostra org per ampliar aquests límits.',
          textTranslationError: 'La traducció ha fracassat. El suport està disponible per a l\'ajuda.',
          statisticRequestError:'Hem trobat un error en la creació del seu informe, si us plau, torneu a intentar-ho.',
          downloadPdfError: 'No podem convertir el format d\'arxiu pujat en pdf.',
          noDataError: 'No hem pogut trobar cap traducció entre aquestes dates. Si creus que és un error de suport està disponible.',
          'AP-12-001': 'L\'arxiu sembla buit, si us plau revisi i torni a provar.',
          'AP-12-001-desc': 'L\'arxiu sembla buit, si us plau revisi i torni a provar.',
          'AP-12-001-desc-pdf':'No es va detectar cap text. Si us plau, torneu a intentar que l\'OCR estigui activat.',
          'AP-01-003-desc': 'L\'arxiu supera els límits de paraules per a la seva organització. Si us plau, divideix-lo en trossos més petits i torna a intentar-ho.',
          'WFM-04-SRCTRGSAME': 'El llenguatge d’origen i objectiu no pot ser el mateix. Si us plau, modifica una de les dues',
          'WFM-04-SRCTRGSAME-desc': 'El llenguatge d’origen i objectiu no pot ser el mateix. Si us plau, modifica una de les dues',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'El llenguatge d’origen i objectiu no pot ser el mateix. Si us plau, modifica una de les dues',
          'USRMNG-DB-1062': 'Aquest usuari ja existeix. Si us plau, busqueu-lo i feu els canvis corresponents.',
          'STT-DOC-04-SIZE': 'La mida màxima  és de 200MB. Dividiu el vostre arxiu i torneu a intentar-ho.',
          'STT-fileSIZE': 'Es poden traduir arxius de fins a 50MB. Si us plau, eliminar arxius més grans, dividir-los en petits i intentar de nou.',
          'STT-fileSIZE-zip': 'Es poden traduir arxius zip de fins a 512MB. Si us plau, eliminar arxius més grans, dividir-los en petits i intentar de nou.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Si us plau, carregueu un arxiu wav en el moment o intenteu traduir el document per traduir-lo.',
          TT_WAV_ERROR_AUTO_DETECT:'Si us plau, seleccioneu l\'idioma original dels vostres arxius de wav a \\\\"Llengua de curs\\\\" per traduir-los amb èxit.',
          TT_WAV_ERROR_FILE_TYPE:'Traduir documents per traduir aquest tipus d\'arxius',
          TT_WAV_ERROR_FILE_SIZE: 'La mida màxima és de 200MB. Dividiu el vostre arxiu i torneu a intentar-ho.',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Si us plau, traduïu arxius PDF i wav per separat.',
          'WFM-error': 'Hem trobat un error en el processament del seu arxiu. Si us plau, torna a intentar-ho',
          'WFM-error-desc': 'Hem trobat un error en el processament del seu arxiu. Si us plau, torna a intentar-ho',
          'WFM-04-INVLANG': 'El llenguatge detectat no està disponible a la vostra organització. Per a més idiomes contacti amb la persona que gestiona el portal en el seu org.',
          'WFM-04-INVLANG-desc': 'El llenguatge detectat no està disponible a la vostra organització. Per a més idiomes contacti amb la persona que gestiona el portal en el seu org.',
          'OCR-03-A2A-FAIL': 'Hem trobat un error traduint el seu arxiu. Si us plau, torna a intentar-ho',
          'OCR-03-A2A-TIMEOUT': 'Hem trobat un error traduint el seu arxiu. Si us plau, torna a intentar-ho',



          statistics: 'Estadístiques',
          statisticFrom: 'Des de:',
          statisticTo: 'Fins:',
          loadTimeFrame: 'Carregar període de temps',
          users: 'Usuari',
          allUsers: 'Tots',
          allLanguages: 'Tots',
          allOperations: 'Tots',
          textTranslateOperations: 'Traducció del text',
          documentTranslateOperations: 'Traducció del document',
          speechDocumentOperations: 'Conversió de veu a text',
          transliteration: "Transliteració",
          websiteTranslateOperations: "Traducció del lloc web",
          speechTranscription: "Transcripció de veu",
          statisticDownload: 'Descarregar l’informe',
          textTranslationReachedLimit: 'Es poden traduir fins a 10.000 caràcters per sol·licitud',
          'OCR-03-A2A-PASSWORD':
              'S’ha produït un error, treu la contrasenya del PDF',
          'OCR-03-A2A-PASSWORD-desc-pdf':
              'S’ha produït un error, treu la contrasenya del PDF',
          'AP-08-001': 'S’ha produït un error, treu la contrasenya del PDF',
          'AP-08-001-desc-pdf':
              'S’ha produït un error, treu la contrasenya del PDF',
          'OCR-03-A2A-SIZE-EX':
              'La mida màxima de la pàgina ha de ser A4 per poder fer la conversió del PDF',
          'OCR-03-A2A-HEALTH':
              'El ROC no està disponible temporalment, intenta-ho un altre co.',
          'MT-error': 'S’ha produït un error amb el motor de traducció',
          textSourceTextareaButton: 'Seleccionar arxius',
          copyright: ' Translations.com Tots els drets reservats.',
          'MT-error-desc': 'Error amb el motor de traducció',
          transliterationTippy: 'Transcriure text',
          qualityTranslationEngineInfo: "TransPerfect Main Engine li permet estar informat sobre el nivell de fidelitat que la nostra traducció té segons els seus requisits. Prova-ho!",
          infoQualityControlFeature: "El control de qualitat és la característica que et permet estar informat sobre el nivell de fidelitat que té la nostra traducció segons les teves necessitats. El nivell de fidelitat es defineix com l'exactitud de la traducció que li proporcionem segons els seus requisits.",
          infoQualityControlFeatureTooltip: 'Per garantir la qualitat i transparència del nostre producte, TransPerfect Main Engine li permet estar informat sobre el nivell de fidelitat que la nostra traducció té segons les seves necessitats.',
          infoQualityControlFeatureTooltipLearnMore: 'Saber més',
          translationQualityGood: 'La traducció és genial!',
          translationQualityAverage: 'La traducció pot tenir alguns errors, utilitzeu amb precaució!',
          translationQualityBad: 'És probable que la traducció sigui problemàtica, es recomana la revisió',
          translationQualityGoodModal: 'Bé',
          translationQualityAverageModal: 'Utilitza amb precaució',
          translationQualityBadModal: 'Probablement problemàtic',
          translationQualityGoodModalDescription: 'Pots confiar en aquesta traducció',
          translationQualityAverageModalDescription: 'Pot haver-hi algunes imperfeccions en la traducció. Recomanem el seu ús intern. Per a ús extern, us recomanem que ho comproveu amb un traductor professional.',
          translationQualityBadModalDescription: 'Aquesta traducció pot tenir alguns errors importants. Us recomanem que ho corregiu amb un traductor professional.',
          websiteTranslator: 'Lloc web',
          websiteTranslatorTippy: 'Traductor del lloc web',
          urlInputPlaceHolder: 'Enganxa la teva URL aquí...',
          websiteTranslateAction: 'Traduir',
          removeAllPreviousWebsiteTranslateRequests: 'Eliminar tot',
          previousWebsiteTranslateRequestTitle: 'Sol·licituds anteriors',
          wtLoadingCaption: 'Traduint lloc web...',
          ocrCheckboxLabel:
            'Estic pujant imatges i documents escanejats',
          ocrHelpText: 'Quan s’activa aquesta',
          ocrCheckboxTippy:
              "L'OCR (reconeixement òptic de caràcters) detecta text en imatges i documents escanejats. Utilitza aquesta opció si has rebut una traducció buida o si el contingut del text del PDF no es pot seleccionar.",
          lastWTRequestURL: 'Darrera sol·licitud de traducció del lloc web:',
          openWebsiteBtnLabel: 'Obrir',
          invalidURL: "Format d'URL no vàlid",
          ClearURLLabelandTippy: "Esborrar l'adreça URL",
          reachedQuotaLimitErrorPartOne: 'No pots traduir perquè la teva empresa ha arribat als seus límits de traducció.',
          reachedQuotaLimitErrorPartTwo: 'Poseu-vos en contacte amb la persona que gestiona el portal de la vostra organització per ampliar-lo.',
          orgExpiredErrorPartOne: 'No pots traduir perquè la teva empresa ha arribat a la data de caducitat.',
          orgExpiredErrorPartTwo: 'Poseu-vos en contacte amb la persona que gestiona el portal de la vostra organització per ampliar aquests límits.',
          downloadSourceFileSubheader: 'Font',
          downloadTranslatedFileSubheader: "Resultat",
          // statistics data view
          dataView: 'Dades',
          graphView: 'Gràfica',
          mainTitleStatisticsDataView: 'Estadístiques de l\'organització en el període seleccionat',
          genericColumnTitleStatisticsDataView: 'Totals',
          textTranslationTitleStatisticsDataView: 'Traducció de text',
          documentTranslationTitleStatisticsDataView: 'Traducció de documents',
          wordsTranslatedField: 'Paraules traduïdes',
          charsTranslatedField: 'Caràcters traduïts',
          ratioTextDocumentField: 'Relació de traducció de textos/traducció de documents',
          mostUsedLangsField: 'Combinació lingüística més utilitzada',
          totalTextRequestsField: 'Sol·licituds totals',
          totalDocsTranslatedField: 'Documents totals traduïts',
          userColumn: 'Usuari',
          numberOfWordsColumn: "Nombre de paraules",
          currentQuota: 'Estat actual de la quota',
          sortTableCancel: "Clica per cancel·lar la classificació",
          sortTableAscending: "Clica per ordenar de manera ascendent",
          sortTableDescending: "Clica per ordenar de manera descendent",

          //Lang names
          af: 'Afrikaans',
          am: 'Amharic',
          ar: 'Àrab',
          'oc-ES': 'Aranès',
          auto: "Detecta l'idioma",
          eu: 'Basc',
          bg: 'Búlgar',
          bn: 'Bangla',
          bs: 'Bosnià',
          ca: 'Català',
          cb: 'Cebuà',
          co: 'Cors',
          cs: 'Txec',
          cy: 'Gal·lès',
          da: 'Danès',
          dv: 'Divehi',
          ee: 'Ewe',
          gl: 'Gallec',
          de: 'Alemany',
          el: 'Grec',
          en: 'Anglès',
          'en-CA': 'Anglès (Canadà)',
          'en-US': 'Anglès (Estats Units)',
          'en-GB': 'Anglès (Regne Unit)',
          es: 'Espanyol',
          'es-LA': 'Espanyol (Amèrica Llatina)',
          'es-MX': 'Espanyol (Mèxic)',
          'es-XM': 'Espanyol (Internacional)',
          'es-US': 'Espanyol (Estats Units)',
          et: 'Estonià',
          fa: 'Persa',
          'fa-AF': 'Darí',
          fi: 'Finlandesa',
          fo: 'Faroes',
          tl: 'Filipí',
          fj: 'Fijian',
          fr: 'Francès',
          'fr-CA': 'Francès (Canadà)',
          'fr-BE': 'Francès (Bèlgica)',
          'fr-FR': 'Francès (França)',
          Jpan: 'Japonès (transliteració)',
          ga: 'Gaèlic (Irlanda)',
          he: 'Hebreu',
          Hans: 'Xinès simplificat (transliteració)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Croat',
          ht: 'Crioll Haitià',
          hu: 'Hongarès',
          hy: 'Armeni',
          id: 'Indonèsia',
          is: 'Islandès',
          it: 'Italià',
          'iu-Latn': 'Inuktitut (llatí)',
          iu: 'Inuktitut',
          ja: 'Japonès',
          ko: 'Coreà',
          km: 'Khmer',
          ka: 'Georgian',
          ks: 'Kashmiri',
          fy: 'Frisó',
          hw: 'Hawaià',
          ig: 'Igbo',
          jv: 'Javanès',
          kk: 'Kazakh',
          kmr: 'Kurdistan (nord)',
          ku: 'Kurd',
          ky: 'Kirguís',
          'lb-LU': 'Luxemburguès',
          lo: 'Lao',
          lt: 'Lituà',
          lv: 'Letó',
          mg: 'Malgaix',
          mk: 'Macedònia',
          ml: 'Malaiàlam',
          mi: 'Maori',
          mn: 'Mongol',
          ms: 'Malai',
          mt: 'Maltès',
          my: 'Burmese',
          multi: 'Multilingüe',
          ne: 'Nepali',
          no: 'Noruec',
          nl: 'Holandès',
          'nl-BE': 'Holandès (Bèlgica)',
          ns: 'Sepedi',
          ny: 'Ngindo',
          oc: 'Occità',
          or: 'Oriya',
          otq: 'Querétaro Otomi',
          ps: 'Paixtu',
          pa: 'Panjabi',
          rn: 'Rundi',
          pl: 'Polonès',
          pt: 'Portuguès',
          'pt-PT': 'Portuguès (Portugal)',
          'pt-BR': 'Portuguès (Brazil)',
          ro: 'Romanès',
          ru: 'Rus',
          rw: 'Ruandès',
          sk: 'Eslovac',
          si: 'Singalés',
          sl: 'Eslovè',
          sm: 'Waray',
          st: 'Sotho Meridional',
          sn: 'Shona',
          sd: 'Sindi',
          so: 'Somali',
          su: 'Sondanès',
          sr: 'Serbi',
          'sr-RS-CYRL': 'Serbi (Ciríl·lic)',
          'sr-RS-LATN': 'Serbi (Llatí)',
          sv: 'Suec',
          sw: 'Kiswahili',
          ta: 'Tamil',
          th: 'Tailandès',
          ti: 'Tigrinya',
          to: 'Tongan',
          tr: 'Turc',
          tt: 'Tàtar',
          tk: 'Turcman',
          ty: 'Tahitian',
          tn: 'Setswana',
          tw: 'Twi',
          uk: 'Ucraïnès',
          ur: 'Urdú',
          vi: 'Vietnamita',
          yua: 'Yucatec Maya',
          yue: 'Cantonès',
          sq: 'Albanès',
          az: 'Azerbaijani',
          be: 'Belarusian',
          eo: 'Esperanto',
          gd: 'Gaelic',
          kn: 'Kannada',
          gu: 'Gujarati',
          ha: 'Hausa',
          la: 'Latin',
          mr: 'Marathi',
          tg: 'Tajik',
          te: 'Telugu',
          ts:'Tsonga',
          uz: 'Uzbek',
          xh: 'Xhosa',
          ug: 'Uigur',
          yo: 'Ioruba',
          yi: 'Yiddish',
          'zh-CN': 'Xinès Simplificat',
          'zh-TW': 'Xinès Tradicional',
          'zh-HK': 'Xinès Hong Kong',
          zu: 'Zulu',
        },
      },
      'fr-CA': {
        translations: {
          'AP-08-001': 'Erreur; supprimer le mot de passe du PDF',
          'AP-08-001-desc-pdf':
              'Le PDF est protégé par un mot de passe; veuillez supprimer le mot de passe du PDF',
          'MT-error': 'Erreur de moteur de traduction',
          'MT-error-desc': 'Erreur de moteur de traduction',
          'OCR-03-A2A-HEALTH':
              'Reconnaissance optique de caractères temporairement indisponible; réessayez',
          'OCR-03-A2A-PASSWORD': 'Erreur; supprimer le mot de passe du PDF',
          'OCR-03-A2A-PASSWORD-desc-pdf':
              'Le PDF est protégé par un mot de passe; veuillez supprimer le mot de passe du PDF',
          'oc-ES': 'Aranais',
          'pt-BR': 'Portugais (Brésil)',
          'pt-PT': 'Portugais (Portugal)',
          'sr-RS-CYRL': 'Serbe (cyrillique)',
          'sr-RS-LATN': 'Serbe (latin)',
          'zh-CN': 'Chinois simplifié',
          'zh-TW': 'Chinois traditionnel',
          AnnounceCopyTranslatedText: 'Copier le texte traduit',
          AnnounceDialogClosed: 'Le dialogue est fermé',
          AnnounceDialogOpened: 'Le dialogue est ouvert',
          AnnounceModalCloseButton: 'Fermer',
          AnnounceTextisCleared: 'Le texte est effacé',
          AnnounceCopyTransactionRecordText: 'Copier l\'enregistrement de transaction',
          AnnounceCopiedToClipboard: 'Copier l’enregistrement de transaction',
          AnnounceTranslatedText: 'Texte traduit',
          Catalan: 'Català',
          ClearTextLabelandTippy: 'Effacer le texte',
          ClearURLLabelandTippy: 'Effacer l’URL',
          English: 'English',
          FAQ: 'Aide',
          "OCR-03-A2A-SIZE-EX'":
              'La taille maximale de la page pour effectuer la conversion du PDF est A4',
          Spanish: 'Español',
          af: 'Afrikaans',
          am: 'Amharic',
          ar: 'Arabe',
          auto: 'Détecter la langue',
          az: 'Azerbaïdjanais',
          be: 'Bélarussien',
          bg: 'Bulgare',
          bn: 'Bangla',
          bs: 'Bosniaque',
          ca: 'Catalan',
          copyright: ' Translations.com Tous droits réservés.',
          cs: 'Tchèque',
          cy: 'Gallois',
          da: 'Danois',
          dv: 'Divehi',
          ee: 'Ewe',
          de: 'Allemand',
          deleteModalCancel: 'Annuler',
          deleteModalCheck: 'Êtes-vous sûr de vouloir supprimer ce fichier?',
          deleteModalDelete: 'Supprimer',
          deleteModalTitle: 'Supprimer le fichier',
          documentDropFileMessage: 'Formats de fichiers pris en charge',
          documentDroph2: 'Faire glisser et déposer des fichiers ou',
          documentDrophSelect: 'les sélectionner sur votre ordinateur',
          documentDrophMaxSize: 'Taille de fichier max. <strong>50MB</strong>, fichiers ZIP jusqu\'à <strong>512MB</strong>',
          dragAndDropTooltip: 'Glisser-déposer',
          documentH1: 'Traduction du document',
          documentH2: 'Traductions',
          documentSubHeaderDate: 'Date',
          documentSubHeaderFiles: 'Télécharger',
          documentSubHeaderPages: 'Supprimer',
          submissionNameHeader: "Nom de soumission",
          submissionNameMaxLength: 'Le nom de la soumission peut contenir jusqu’à 180 caractères',
          fileNameHeader: "Nom de fichier",
          categoryHeader: "Catégorie",
          categoryPlaceholder: "Aucune catégorie",
          submissionNamePlaceholder: "Inscrire ici…",
          fileStatusSuccessful: "Traduit",
          downloadFailedError: "Impossible de télécharger le fichier, car la traduction a échoué.",
          downloadingStatisticsMessage: "Création de votre rapport, cette opération peut durer quelques minutes",
          downloadingStatisticsError: "Nous avons trouvé une erreur lors de la création de votre rapport, veuillez réessayer ou contacter l'assistance",
          documentSubHeaderActions: "Actions",
          documentSubHeaderWCount: "Nombre de mots :",
          documentSubHeaderCCount: "Nombre de caractères :",
          documentSubHeaderSource: 'Langues',
          documentSubHeaderStatus: 'État',
          documentSubHeaderSubName: 'Nom',
          documentSubHeaderTarget: 'Langue cible',
          documentSubRowChildDeleteTippy: 'Supprimer le fichier',
          documentFileIconTippy: 'Fichier',
          documentSubRowChildDownloadHeader: 'Télécharger',
          documentSubRowChildDownloadLoadingTippy: "Chargement du téléchargement",
          documentSubRowChildCopyToClipboardTippy: "Copier dans le presse-papiers",
          documentSubRowChildCopiedToClipboardTippy: "Copié dans le presse-papiers",
          documentSubRowChildFailedTranslationHelpTippy: "Aide à propos de l’échec de la traduction",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Fermer l’aide à propos de l’échec de la traduction",
          documentSubRowChildDownloadSourceDropdown: "Télécharger en format source",
          documentSubRowChildDownloadMoreOptions: 'Plus d’options de téléchargement',
          documentSubRowChildExpand: 'Développer',
          documentSubRowChildCollapse: 'Réduire',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Zipper et télécharger',
          documentSubRowZipHelp: 'Aide zip',
          documentSubRowDownloadZip: 'Télécharger le zip',
          documentSubRowDownloadZipError: 'Error zip',
          documentSubRowChildHumanTranslationRequestedTippy: "Traduction humaine demandée",
          documentSubRowChildHumanTranslationFailedTippy: "Échec de la demande de traduction humaine",
          documentSubRowChildRequestHumanTranslationTippy: "Demander une traduction humaine",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Fermer le menu de traduction humaine",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Chargement de la demande",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Ouvre GL Portal dans un nouvel onglet",
          documentSubRowChildHumanTranslationRequestInProcess: "Demande de traduction humaine en cours.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Terminer sur GL Portal",
          documentSubRowChildHumanTranslationRequest: "Demander une traduction humaine",
          documentSubRowChildHumanTranslationRequested: "Traduction humaine demandée",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Vérifier l’état sur GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Aller à GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (coût supplémentaire)",
          documentSubRowChildEditTranslatedFile: 'Modifier le fichier traduit',
          documentSubRowChildMoreActionsTooltip: 'Plus d’actions',
          helpSentencePartTwoHover: "Cela ouvrira un nouvel e-mail au service d’assistance",
          helpSentencePartOne: "Pour obtenir de l’aide, communiquez avec le service de soutien ",
          helpSentencePartTwo: "ici",
          helpSentencePartThree: ". Veuillez décrire votre problème dans le corps de votre e-mail.",
          documentSubRowChildDownloadPdfDropdown: 'Télécharger au format PDF',
          documentSubRowChildDownloadTippy: 'Télécharger le fichier traduit',
          documentSubRowChildDownloadSourceTippy: 'Télécharger le fichier source',
          documentSubRowChildLanguageTo: 'à',
          documentSubRowChildStatusError: 'Échec de la traduction',
          documentSubRowChildStatusTranslated: 'Traduction réussie',
          documentSubRowChildStatusTranslatedSuccess: "Traduit",
          documentSubRowChildStatusFailed: "Échoué",
          documentSubRowChildStatusUploading: 'Téléchargement',
          documentSubRowChildStatusTranslating: 'Traduction',
          documentSubRowStatusDeleting: 'Suppression...',
          documentTippyAdd: 'Sélectionner des fichiers',
          documentTitle: 'Page de traduction du document',
          dropdownSelected: 'est sélectionné',
          dropdownUserSetting: 'paramètre utilisateur',
          dropdownWebsiteLanguage: 'définir la langue du site Web',
          dropdownsourceLang: 'Langue source',
          dropdowntargetLang: 'Langue cible',
          el: 'Grec',
          en: 'Anglais',
          eo: 'Espéranto',
          es: 'Espagnol',
          et: 'Estonien',
          eu: 'Basque',
          fa: 'Persan',
          'fa-AF': 'Dari',
          fi: 'Finnois',
          fo: 'Féroé',
          fj: 'Fidjien',
          fr: 'Français',
          'fr-CA': 'Français (Canadien)',
          ga: 'Gaélique (Irlande)',
          gd: 'Gaélique',
          gl: 'Galicien',
          gu: 'Gujarati',
          'lb-LU': 'Luxembourgeois',
          ha: 'Haoussa',
          he: 'Hébreu',
          Hans: 'Chinois simplifié (translittération)',
          headerAccountSettings: 'Paramètres du compte',
          headerH1: 'Service de traduction automatique',
          headerLogout: 'Déconnexion',
          headerVersion: 'Version',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Croate',
          ht: 'Créole haïtien',
          hu: 'Hongrois',
          hy: 'Armenian',
          id: 'Indonésien',
          inactivityLogoutButtonLabel: 'Se reconnecter',
          inactivityLogoutMessage:
              'Vous avez été déconnecté(e) pour cause d’inactivité',
          inactivityLogoutTitle: 'Vous avez été déconnecté(e)',
          invalidURL: 'Format d’URL non valide',
          is: 'Islandais',
          it: 'Italien',
          'iu-Latn': 'Inuktitut (Latin)',
          iu: 'Inuktitut',
          ja: 'Japonais',
          Jpan: 'Japonais (translittération)',
          ka: 'Géorgien',
          ks: 'Kashmiri',
          km: 'Khmer',
          kn: 'Kannada',
          ko: 'Coréen',
          la: 'Latin',
          langSelect_NoResultsMessage: 'Aucun résultat trouvé',
          lastWTRequestURL: 'Dernière demande de traduction de site Web :',
          lo: 'Lao',
          loginButton: 'Ouverture de session',
          loginTitle: 'Page de connexion',
          loginh1: 'Connexion requise',
          logoutScreenLogoutButtonLabel: 'Se reconnecter',
          logoutScreenMessage: 'Merci d’être passé!',
          lt: 'Lituanien',
          lv: 'Letton',
          menuLabel: 'Menu',
          mg: 'Malgache',
          mk: 'Macédonien',
          my: 'Burmese',
          modalConfirm: 'Confirmer les langues source et cible',
          modalH1: 'Téléverser vos fichiers',
          modalH1AfterUpload: 'Fichiers téléversés',
          modalLoading: 'Chargement...',
          modalPTop: 'Vous avez sélectionné les fichiers suivants à traduire :',
          'modalPdfAutodetectOcrError:':
              'Vous devez sélectionner une langue autre que Détecter la langue pour reconnaître le texte des numérisations et des images.',
          modalTippyAdd: 'Ajouter plus de fichiers',
          modalTippySwap: 'Inverser les langues',
          modalTranslate: 'Traduire',
          modalUploadSuccessful: 'Traduction en cours',
          mr: 'Marathi',
          ms: 'Malais',
          mt: 'Maltais',
          multi: 'Multilingue',
          mww: 'Hmong Daw',
          ne: 'Nepali',
          nl: 'Hollandais',
          ns: 'Sepedi',
          no: 'Norvégien',
          ocrCheckboxLabel:
            'Je télécharge des images et des documents numérisés',
          ocrHelpText: 'Quand activer cette',
          ocrCheckboxTippy:
              'La reconnaissance optique des caractères (ROC) détecte le texte dans les images et les documents numérisés. Essayez cette option si vous avez reçu une traduction vide ou si le contenu texte de votre PDF n’est pas sélectionnable.',
          openWebsiteBtnLabel: 'Ouvrir',
          oc: 'Occitan',
          otq: 'Querétaro Otomi',
          pl: 'Polonais',
          rn: 'Rundi',
          previousWebsiteTranslateRequestTitle: 'Historique des demandes :',
          processingRequestMsg: 'Traitement de la demande...',


          modalUploadErrorMessagePartOne:'Nous avons trouvé une erreur lors du téléversement de vos fichiers.',
          modalUploadErrorMessagePartTwo:'Veuillez réessayer.',
          modalUploadErrorMessagePartThree:'Si le problème persiste ',
          modalUploadErrorMessagePartFour: 'contacter l’assistance.',
          modalMsgEmlAutoDetectError: 'Veuillez sélectionner la langue originale de votre courriel dans « Langue source » pour le traduire avec succès.',
          modalPdfAutodetectOcrError: 'Veuillez sélectionner la langue originale de vos images ou de vos PDF sur « Langue source » pour les traduire avec succès.',
          mandatoryCategoryAndSubmissionNameError: 'Veuillez sélectionner une catégorie et un nom de soumission pour poursuivre votre traduction.',
          mandatorySubmissionNameError: 'Veuillez sélectionner un nom de soumission pour poursuivre votre traduction.',
          mandatoryCategoryError: 'Veuillez sélectionner une catégorie pour poursuivre votre traduction.',
          deleteModalProblem: 'Nous éprouvons des difficultés à supprimer votre fichier. Veuillez fermer ce modal et réessayer.',
          unsupportedFileMessage: 'Nous ne pouvons pas traduire un ou plusieurs fichiers. Veuillez les convertir et réessayer.',
          statisticErrorTime: 'La date de début semble être postérieure à la date de fin. Veuillez choisir une date de début avant la date de fin.',
          statisticErrorFutureDate: 'La date de début semble être dans le futur. Veuillez choisir une date de début avant aujourd’hui.',
          unsupportedSourceLang: 'La langue détectée n’est pas disponible dans votre organisation. Pour plus de langues, communiquez avec la personne qui gère le portail dans votre organisation.',
          reachedQuotaLimitError: 'Votre organisation a atteint ses limites de traduction. Communiquez avec la personne qui gère le portail dans votre organisation pour prolonger ces limites.',
          orgExpiredError: 'Vous ne pouvez pas traduire parce que votre organisation a atteint sa date d’expiration. Communiquez avec la personne qui gère le portail dans votre organisation pour prolonger ces limites.',
          textTranslationError: 'La traduction a échoué. Du soutien est disponible pour obtenir de l\n’ aide.',
          statisticRequestError:'Nous avons trouvé une erreur lors de la création de votre rapport. Veuillez réessayer.',
          downloadPdfError: 'Nous ne pouvons pas convertir le format de fichier téléversé au format PDF.',
          noDataError: 'Nous n’avons trouvé aucune traduction entre ces dates. Si vous pensez qu’il s’agit d’un soutien pour les erreurs, vous pouvez le faire.',
          'AP-12-001': 'Le fichier semble vide, veuillez vérifier et réessayer.',
          'AP-12-001-desc': 'Le fichier semble vide, veuillez vérifier et réessayer.',
          'AP-12-001-desc-pdf':'Aucun texte n’a été détecté. Veuillez réessayer en vous assurant que la ROC est activée.',
          'AP-01-003-desc': 'Le fichier dépasse les limites de mots pour votre organisation. Veuillez le diviser en petits morceaux et réessayer.',
          'WFM-04-SRCTRGSAME': 'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’eux',
          'WFM-04-SRCTRGSAME-desc':'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’eux',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’eux',
          'USRMNG-DB-1062': 'Cet utilisateur existe déjà. Veuillez le rechercher et apporter les modifications correspondantes.',
          'STT-DOC-04-SIZE': 'La taille maximale du fichier est de 200MB. Veuillez diviser votre fichier et réessayer.',
          'STT-fileSIZE': 'Vous pouvez traduire des fichiers jusqu\'à 50MB. Veuillez supprimer les fichiers plus volumineux, les diviser en fichiers plus petits et réessayer.',
          'STT-fileSIZE-zip': 'Vous pouvez traduire des fichiers zip jusqu\'à 512MB. Veuillez supprimer les fichiers plus volumineux, les diviser en fichiers plus petits et réessayer.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Veuillez téléverser un fichier wav à la fois ou essayer de traduire le document pour le traduire',
          TT_WAV_ERROR_AUTO_DETECT:'Veuillez sélectionner la langue originale de vos fichiers wav sur « Langue source » pour les traduire avec succès.',
          TT_WAV_ERROR_FILE_TYPE:'Utiliser la traduction de document pour traduire ce type de fichier',
          TT_WAV_ERROR_FILE_SIZE: 'La taille maximale du fichier est de 200MB. Veuillez diviser votre fichier et réessayer',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Veuillez traduire les fichiers PDF et wav séparément',
          'WFM-error': 'Une erreur s’est produite lors du traitement de votre fichier. Veuillez réessayer<',
          'WFM-error-desc': 'Une erreur s’est produite lors du traitement de votre fichier. Veuillez réessayer',
          'WFM-04-INVLANG': 'La langue détectée n’est pas disponible dans votre organisation. Pour plus de langues, communiquez avec la personne qui gère le portail dans votre organisation.\n',
          'WFM-04-INVLANG-desc': 'La langue détectée n’est pas disponible dans votre organisation. Pour plus de langues, communiquez avec la personne qui gère le portail dans votre organisation.\n',
          'OCR-03-A2A-FAIL': 'Une erreur s’est produite lors de la traduction de votre fichier. Veuillez réessayer',
          'OCR-03-A2A-TIMEOUT': 'Une erreur s’est produite lors de la traduction de votre fichier. Veuillez réessayer',


          pt: 'Portugais',
          quickLinksLabel: 'Liens rapides :',
          removeAllPreviousWebsiteTranslateRequests: 'Effacer tout',
          ro: 'Roumain',
          ru: 'Russe',
          sidebarDocument: 'Document',
          sidebarText: 'Texte',
          sidebarTippyDocument: 'Traducteur de documents',
          sidebarTippyText: 'Traducteur de texte',
          sk: 'Slovaque',
          sl: 'Slovène',
          sm: 'Samoan',
          sq: 'Albanais',
          statisticDownload: 'Télécharger le rapport',
          textTranslationReachedLimit: 'Vous pouvez traduire jusqu’à 10 000 caractères par demande',
          statisticFrom: 'De:',
          statisticTitle: 'Page de statistiques',
          statisticTo: 'À:',
          statistics: 'Statistiques',
          sv: 'Suédois',
          sw: 'Souahéli',
          ta: 'Tamoul',
          te: 'Télougou',
          textAreaAnnouncementBox1:
              'zone de texte où vous pouvez écrire du texte à traduire',
          textAreaAnnouncementBox2:
              'zone de texte où la traduction de texte apparaîtra une fois la traduction terminée.',
          textCopyTippy: 'Copier le texte',
          textH2NavigateLanguageTranslationDropDown:
              'Sélectionner les langues source et cible',
          textH2NavigateTextTranslationArea: 'Zone de traduction de texte',
          textOk: 'OK',
          textSourceTextareaButton: 'Sélectionner des fichiers',
          textSourceTextareaPlaceholder: 'Tapez ou collez votre texte ici...',
          textTippySwap: 'Inverser les langues',
          textTippySwapBlocked: 'Impossible d’inverser la langue de détection',
          textTippySwapBlockedMulti: 'Impossible d’inverser la langue de détection',
          textTitle: 'Page de traduction de texte',
          textTranslateButton: 'Traduire',
          reachedQuotaLimitErrorPartOne: 'Vous ne pouvez pas traduire car votre organisation a atteint ses limites de traduction.',
          reachedQuotaLimitErrorPartTwo: 'Contactez la personne qui gère le portail dans votre organisation pour étendre cette option.\n',
          orgExpiredErrorPartOne: 'Vous ne pouvez pas traduire car votre organisation a atteint sa date d’expiration.\n',
          orgExpiredErrorPartTwo: 'Contactez la personne qui gère le portail dans votre organisation pour étendre ces limites.',
          downloadSourceFileSubheader: 'Source',
          downloadTranslatedFileSubheader: "Résultat",
          tg: 'Tadjik',
          th: 'Thaïlandais',
          tl: 'Philippin',
          ti: 'Tigrinya',
          tn: "Setswana",
          to: 'Tongien',
          tr: 'Turc',
          ts:'Tsonga',
          transliterationTippy: 'Translittérer le texte',
          qualityTranslationEngineInfo: "Le moteur principal de TransPerfect vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos exigences. Testez-le!",
          infoQualityControlFeature: "Le contrôle qualité est la fonctionnalité qui vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos besoins. Le niveau de fidélité est défini comme l’exactitude de la traduction que nous vous fournissons, comme indiqué par vos exigences.",
          infoQualityControlFeatureTooltip: 'Pour garantir la qualité et la transparence de notre produit, le moteur principal de TransPerfect vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos besoins. ',
          infoQualityControlFeatureTooltipLearnMore: 'En savoir plus',
          translationQualityGood: 'La traduction est belle',
          translationQualityAverage: 'La traduction peut comporter des erreurs, utilisez-la avec prudence!',
          translationQualityBad: 'La traduction est probablement problématique, une révision est recommandée.',
          translationQualityGoodModal: 'Bon',
          translationQualityAverageModal: 'Utiliser avec prudence',
          translationQualityBadModal: 'Probablement problématique',
          translationQualityGoodModalDescription: 'Vous pouvez compter sur cette traduction.',
          translationQualityAverageModalDescription: 'La traduction peut comporter des imperfections. Nous recommandons son utilisation en interne. Pour un usage externe, nous vous conseillons de le vérifier auprès d’un traducteur professionnel.',
          translationQualityBadModalDescription: 'Cette traduction peut comporter des erreurs majeures. Nous vous conseillons de le corriger avec un traducteur professionnel.',
          ty: 'Tahitien',
          tw: 'Twi',
          uk: 'Ukrainien',
          ur: 'Ourdou',
          urlInputPlaceHolder: 'Collez votre URL ici...',
          uz: 'Ouzbek',
          vi: 'Vietnamien',
          websitePage: 'Page de traduction de site Web',
          websiteTranslateAction: 'Traduire',
          websiteTranslator: 'Site Web',
          websiteTranslatorTippy: 'Traducteur de site Web',
          wtLoadingCaption: 'Traduction du site Web...',
          xh: 'Xhosa',
          yi: 'Yiddish',
          yua: 'Yucatèque',
          yue: 'Cantonais',
          zu: 'Zoulou',
          // statistics data view
          dataView: 'Données',
          graphView: 'Graphique',
          mainTitleStatisticsDataView: 'Statistiques d’organisation pendant la période sélectionnée',
          genericColumnTitleStatisticsDataView: 'Générales',
          textTranslationTitleStatisticsDataView: 'Traduction de texte',
          documentTranslationTitleStatisticsDataView : 'Traduction de document',
          wordsTranslatedField: 'Mots traduits',
          charsTranslatedField : 'Caractères traduits',
          ratioTextDocumentField: 'Ratio de traduction de texte/document',
          mostUsedLangsField: 'Paire de langues la plus utilisée',
          totalTextRequestsField : 'Nombre total de demandes',
          totalDocsTranslatedField : 'Nombre total de documents traduits',
          userColumn: 'Utilisateur',
          numberOfWordsColumn: 'Nombre de mots',
          currentQuota: 'Statut du quota actuel',
          sortTableCancel: "Cliquez pour annuler le tri",
          sortTableAscending: "Cliquez pour trier en ordre croissant",
          sortTableDescending: "Cliquez pour trier en ordre décroissant",
        },
      },
      fr: {
        translations: {
          textOk: 'OK',
          FAQ: 'Aide',
          English: 'English',
          Catalan: 'Català',
          Spanish: 'Español',
          textTitle: 'Page de traduction de texte',
          documentTitle: 'Page de traduction du document',
          loginTitle: 'Page de connexion',
          statisticTitle: 'Page de statistiques',
          textAreaAnnouncementBox1:
              'zone de texte où vous pouvez écrire du texte à traduire',
          textAreaAnnouncementBox2:
              'zone de texte où la traduction de texte apparaîtra une fois la traduction terminée.',
          AnnounceModalCloseButton: 'Fermer',
          AnnounceDialogOpened: 'Le dialogue est ouvert',
          AnnounceDialogClosed: 'Le dialogue est fermé',
          AnnounceTextisCleared: 'Le texte est effacé',
          AnnounceCopyTransactionRecordText: 'Copié l\'enregistrement de transaction',
          AnnounceCopiedToClipboard: 'Copié l’enregistrement de transaction',
          AnnounceCopyTranslatedText: 'Copier le texte traduit',
          ClearTextLabelandTippy: 'Effacer le texte',
          AnnounceTranslatedText: 'Texte traduit',
          headerH1: 'Service de traduction automatique',
          headerLogout: 'Déconnexion',
          headerAccountSettings: 'Paramètres du compte',
          headerVersion: 'Version',
          dropdownWebsiteLanguage: 'définir la langue du site Web',
          dropdownUserSetting: 'paramètre utilisateur',
          dropdownsourceLang: 'Langue source',
          dropdowntargetLang: 'Langue cible',
          operations: 'Origine',
          scale: 'Échelle',
          linearScale: 'Linéaire',
          sqrtScale: 'Racines carrée',
          words: 'Mots',
          characters: 'Caractères',
          dropdownSelected: 'est sélectionné(e)',
          sidebarTippyText: 'Traducteur de texte',
          sidebarText: 'Texte',
          sidebarDocument: 'Document',
          sidebarTippyDocument: 'Traducteur de documents',
          loginh1: 'Connexion requise',
          loginButton: 'Ouverture de session',
          textH2NavigateLanguageTranslationDropDown:
              'Sélectionner les langues source et cible',
          textTippySwap: 'Inverser les langues',
          textTippySwapBlocked: 'Impossible d’inverser la langue de détection',
          textTippySwapBlockedMulti: 'Impossible d’inverser la langue de détection',
          textH2NavigateTextTranslationArea: 'Zone de traduction de texte',
          textSourceTextareaPlaceholder:
              'Saisissez ou collez votre texte ici...',
          textSourceTextareaButton: 'Sélectionner des fichiers',
          textTranslateButton: 'Traduire',
          textCopyTippy: 'Copier le texte',
          documentH1: 'Traduction du document',
          documentDroph2: 'Faire glisser et déposer des fichiers ou',
          documentDrophSelect: 'les sélectionner sur votre ordinateur',
          documentDrophMaxSize: 'Taille de fichier max. <strong>50MB</strong>, fichiers ZIP jusqu\'à <strong>512MB</strong>',
          dragAndDropTooltip: 'Glisser-déposer',
          documentDropFileMessage: 'Formats de fichiers pris en charge : ',
          documentH2: 'Traductions',
          documentSubHeaderDate: 'Date',
          documentSubHeaderSubName: 'Nom',
          documentSubHeaderSource: 'Langues',
          documentSubHeaderTarget: 'Langue cible',
          documentSubHeaderFiles: 'Télécharger',
          documentSubHeaderPages: 'Supprimer',
          submissionNameHeader: "Nom de la soumission",
          submissionNameMaxLength: 'Le nom de la soumission peut contenir jusqu’à 180 caractères',
          fileNameHeader: "Nom du fichier",
          categoryHeader: "Catégorie",
          categoryPlaceholder: "Aucune catégorie",
          submissionNamePlaceholder: "Tapez ici...",
          fileStatusSuccessful: "Traduit",
          downloadFailedError: "Impossible de télécharger le fichier, car la traduction a échoué.",
          downloadingStatisticsMessage: "Création de votre rapport, cette opération peut durer quelques minutes",
          downloadingStatisticsError: "Nous avons trouvé une erreur lors de la création de votre rapport, veuillez réessayer ou contacter l'assistance",
          documentSubHeaderActions: "Actions",
          documentSubHeaderWCount: "Nombre de mots :",
          documentSubHeaderCCount: "Nombre de caractères :",
          documentSubHeaderStatus: 'État',
          documentSubRowStatusDeleting: 'Suppression...',
          documentSubRowChildStatusTranslating: 'Traduction',
          documentSubRowChildStatusTranslated: 'Traduction réussie',
          documentSubRowChildStatusTranslatedSuccess: "Traduit",
          documentSubRowChildStatusFailed: "Échec",
          documentSubRowChildStatusUploading: 'Téléchargement',
          documentSubRowChildStatusError: 'Échec de la traduction',
          documentSubRowChildDeleteTippy: 'Supprimer le fichier',
          documentFileIconTippy: 'Fichier',
          documentSubRowChildDownloadTippy: 'Télécharger le fichier traduit',
          documentSubRowChildDownloadSourceTippy: 'Télécharger le fichier source',
          documentSubRowChildDownloadHeader: 'Télécharger',
          documentSubRowChildDownloadLoadingTippy: "Chargement du téléchargement",
          documentSubRowChildCopyToClipboardTippy: "Copier dans le presse-papiers",
          documentSubRowChildCopiedToClipboardTippy: "Copié dans le presse-papiers",
          documentSubRowChildFailedTranslationHelpTippy: "Échec de l’aide à la traduction",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Fermer l’échec de l’aide à la traduction",
          documentSubRowChildDownloadSourceDropdown: "Télécharger au format source",
          documentSubRowChildDownloadMoreOptions: 'Plus d’options de téléchargement',
          documentSubRowChildExpand: 'Développer',
          documentSubRowChildCollapse: 'Réduire',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Zipper et télécharger',
          documentSubRowZipHelp: 'Aide zip',
          documentSubRowDownloadZip: 'Télécharger le zip',
          documentSubRowChildHumanTranslationRequestedTippy: "Traduction humaine demandée",
          documentSubRowChildHumanTranslationFailedTippy: "Échec de la demande de traduction humaine",
          documentSubRowChildRequestHumanTranslationTippy: "Demander une traduction humaine",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Fermer le menu de traduction humaine",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Chargement de la demande",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Ouvre GL Portal dans un nouvel onglet",
          documentSubRowChildHumanTranslationRequestInProcess: "Demande de traduction humaine en cours.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Terminer sur GL Portal",
          documentSubRowChildHumanTranslationRequest: "Demander une traduction humaine",
          documentSubRowChildHumanTranslationRequested: "Traduction humaine demandée",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Vérifier le statut sur GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Accéder à GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (coût supplémentaire)",
          documentSubRowChildEditTranslatedFile: 'Modifier le fichier traduit',
          documentSubRowChildMoreActionsTooltip: 'Plus d’actions',
          helpSentencePartOne: "Pour obtenir de l’aide, contactez l’assistance ",
          helpSentencePartTwo: " ici",
          helpSentencePartThree: ". Veuillez décrire votre problème dans le corps de votre e-mail.",
          helpSentencePartFour: "au corps de votre e-mail.",
          helpSentencePartTwoHover: "Cela ouvrira un nouvel e-mail au service d’assistance",
          documentSubRowChildDownloadPdfDropdown: 'Télécharger au format PDF',
          documentSubRowChildLanguageTo: 'vers',
          documentTippyAdd: 'Sélectionner des fichiers',
          deleteModalTitle: 'Supprimer le fichier',
          deleteModalCheck: 'Êtes-vous sûr de vouloir supprimer ce fichier?',
          deleteModalCancel: 'Annuler',
          deleteModalDelete: 'Supprimer',
          modalH1: 'Téléverser vos fichiers',
          modalH1AfterUpload: 'Fichiers téléversés',
          modalPTop: 'Vous avez sélectionné les fichiers suivants à traduire :',
          modalTippyAdd: 'Ajouter plus de fichiers',
          modalConfirm: 'Confirmer les langues source et cible',
          modalTippySwap: 'Inverser les langues',
          modalTranslate: 'Traduire',
          modalLoading: 'Chargement...',
          modalUploadSuccessful: 'Traduction en cours',
          processingRequestMsg: 'Traitement de la demande...',

          modalUploadErrorMessagePartOne:'Une erreur est survenue lors du téléchargement de vos fichiers.',
          modalUploadErrorMessagePartTwo:'Veuillez réessayer.',
          modalUploadErrorMessagePartThree:'Si le problème persiste ',
          modalUploadErrorMessagePartFour: 'contacter l’assistance.',
          modalMsgEmlAutoDetectError: 'Veuillez sélectionner la langue d’origine de votre e-mail sur « Langue source » pour le traduire avec succès.',
          modalPdfAutodetectOcrError: 'Veuillez sélectionner la langue d’origine de vos images ou fichiers PDF sur « Langue source » pour les traduire avec succès.',
          mandatoryCategoryAndSubmissionNameError: 'Veuillez sélectionner une catégorie et un nom de soumission pour poursuivre votre traduction.',
          mandatorySubmissionNameError: 'Veuillez sélectionner un nom de soumission pour poursuivre votre traduction.',
          mandatoryCategoryError: 'Veuillez sélectionner une catégorie pour poursuivre votre traduction.',
          deleteModalProblem: 'Nous rencontrons des problèmes pour supprimer votre fichier. Veuillez fermer cette fenêtre modale et réessayer.',
          unsupportedFileMessage: 'Nous ne pouvons pas traduire un ou plusieurs fichiers. Veuillez les convertir et réessayer.',
          statisticErrorTime: 'La date de début semble être postérieure à la date de fin. Veuillez choisir une date de début avant la date de fin.',
          statisticErrorFutureDate: 'La date de début semble être dans le futur. Veuillez choisir une date de début antérieure à aujourd’hui.',
          unsupportedSourceLang: 'La langue détectée n\'est pas disponible dans votre organisation. Pour plus de langues, contactez la personne qui gère le portail dans votre organisation.',
          reachedQuotaLimitError: 'Votre organisation a atteint ses limites de traduction. Contactez la personne qui gère le portail dans votre organisation pour étendre ces limites.',
          orgExpiredError: 'Vous ne pouvez pas traduire car votre organisation a atteint sa date d’expiration. Contactez la personne qui gère le portail dans votre organisation pour étendre ces limites.',
          textTranslationError: 'La traduction a échoué. Une assistance est disponible pour vous aider.',
          statisticRequestError:'Une erreur est survenue lors de la création de votre rapport, veuillez réessayer.',
          downloadPdfError: 'Nous ne pouvons pas convertir le format de fichier téléchargé au format PDF.',
          noDataError: 'Nous n’avons trouvé aucune traduction entre ces dates. Si vous pensez qu’il s’agit d’une erreur, une assistance est disponible.',
          'AP-12-001': 'Le fichier semble vide, veuillez vérifier et réessayer.',
          'AP-12-001-desc': 'Le fichier semble vide, veuillez vérifier et réessayer.',
          'AP-12-001-desc-pdf':'Aucun texte n’a été détecté. Veuillez réessayer en vous assurant que l’OCR est activé.',
          'AP-01-003-desc': 'Le fichier dépasse les limites de mots pour votre organisation. Veuillez le diviser en petits morceaux et réessayer.',
          'WFM-04-SRCTRGSAME': 'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’entre eux',
          'WFM-04-SRCTRGSAME-desc':'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’entre eux',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'La langue source et la langue cible ne peuvent pas être identiques. Veuillez modifier l’un d’entre eux',
          'USRMNG-DB-1062': 'Cet utilisateur existe déjà. Veuillez le rechercher et effectuer les modifications correspondantes.\n',
          'STT-DOC-04-SIZE': 'La taille maximale de la tresse est de 200MB. Veuillez diviser votre fichier et réessayer.',
          'STT-fileSIZE': 'Vous pouvez traduire des fichiers jusqu\'à 50MB. Veuillez supprimer les fichiers plus volumineux, les diviser en fichiers plus petits et réessayer.',
          'STT-fileSIZE-zip': 'Vous pouvez traduire des fichiers zip jusqu\'à 512MB. Veuillez supprimer les fichiers plus volumineux, les diviser en fichiers plus petits et réessayer.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Veuillez télécharger un fichier wav à la fois ou essayer de traduire le document pour le traduire',
          TT_WAV_ERROR_AUTO_DETECT:'Veuillez sélectionner la langue d’origine de vos fichiers wav dans « Langue source » pour les traduire avec succès.',
          TT_WAV_ERROR_FILE_TYPE:'Utiliser la traduction de document pour traduire ce type de fichier',
          TT_WAV_ERROR_FILE_SIZE: 'La taille maximale du fichier est de 200MB. Veuillez diviser votre fichier et réessayer',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Veuillez traduire les fichiers PDF et wav séparément',
          'WFM-error': 'Une erreur est survenue lors du traitement de votre fichier. Veuillez réessayer',
          'WFM-error-desc': 'Une erreur est survenue lors du traitement de votre fichier. Veuillez réessayer',
          'WFM-04-INVLANG': 'La langue détectée n\'est pas disponible dans votre organisation. Pour plus de langues, contactez la personne qui gère le portail dans votre organisation.\n',
          'WFM-04-INVLANG-desc': 'La langue détectée n\'est pas disponible dans votre organisation. Pour plus de langues, contactez la personne qui gère le portail dans votre organisation.\n',
          'OCR-03-A2A-FAIL': 'Une erreur est survenue lors de la traduction de votre fichier. Veuillez réessayer',
          'OCR-03-A2A-TIMEOUT': 'Une erreur s’est produite lors de la traduction de votre fichier. Veuillez réessayer',



          copyright: ' Translations.com Tous droits réservés.',
          statistics: 'Statistiques',
          statisticFrom: 'Début :',
          statisticTo: 'Fin :',
          users: 'Utilisateur',
          allUsers: 'Tous',
          allLanguages: 'Toutes',
          allOperations: 'Toutes',
          textTranslateOperations: 'Traduction de texte',
          documentTranslateOperations: 'Traduction de document',
          speechDocumentOperations: 'Reconnaissance automatique de la parole',
          transliteration: "Translittération",
          websiteTranslateOperations: "Traduction de site Web",
          speechTranscription: "Transcription vocale",
          loadTimeFrame: 'Charger période',
          statisticDownload: 'Télécharger le rapport',
          textTranslationReachedLimit: 'Vous pouvez traduire jusqu’à 10 000 caractères par demande',
          'OCR-03-A2A-PASSWORD': 'Erreur; supprimer le mot de passe du PDF',
          'OCR-03-A2A-PASSWORD-desc-pdf':
              'Le PDF est protégé par un mot de passe; veuillez supprimer le mot de passe du PDF',
          'AP-08-001': 'Erreur; supprimer le mot de passe du PDF',
          'AP-08-001-desc-pdf':
              'Le PDF est protégé par un mot de passe; veuillez supprimer le mot de passe du PDF',
          'OCR-03-A2A-SIZE-EX':
              'La taille maximale de la page pour effectuer la conversion du PDF est A4',
          TT_WAV_ERROR_NO_FILES_DETECTED:
              "Nous n'avons pas détecté de fichiers WAV",
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT:
              'La transcription est toujours en cours. Elle sera perdue si vous fermez la page maintenant',
          quickLinksLabel: 'Liens rapides :',
          'OCR-03-A2A-HEALTH':
              'Reconnaissance optique de caractères temporairement indisponible; réessayez',
          'MT-error': 'Erreur de moteur de traduction',
          'MT-error-desc': 'Erreur de moteur de traduction',
          qualityTranslationEngineInfo: "Le moteur principal de TransPerfect vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos exigences. Testez-le!",
          infoQualityControlFeature: "Le contrôle qualité est la fonctionnalité qui vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos besoins. Le niveau de fidélité est défini comme l’exactitude de la traduction que nous vous fournissons, comme indiqué par vos exigences.",
          transliterationTippy: 'Translittération du texte',
          infoQualityControlFeatureTooltip: 'Pour garantir la qualité et la transparence de notre produit, le moteur principal de TransPerfect vous permet d’être informé du niveau de fidélité de notre traduction en fonction de vos besoins. ',
          infoQualityControlFeatureTooltipLearnMore: 'En savoir plus',
          translationQualityGood: 'La traduction est belle',
          translationQualityAverage: 'La traduction peut comporter des erreurs, utilisez-la avec prudence!',
          translationQualityBad: 'La traduction est probablement problématique, une révision est recommandée.',
          translationQualityGoodModal: 'Bon',
          translationQualityAverageModal: 'Utiliser avec prudence',
          translationQualityBadModal: 'Probablement problématique',
          translationQualityGoodModalDescription: 'Vous pouvez compter sur cette traduction.',
          translationQualityAverageModalDescription: 'La traduction peut comporter des imperfections. Nous recommandons son utilisation en interne. Pour un usage externe, nous vous conseillons de le vérifier auprès d’un traducteur professionnel.',
          translationQualityBadModalDescription: 'Cette traduction peut comporter des erreurs majeures. Nous vous conseillons de le corriger avec un traducteur professionnel.',
          websiteTranslator: 'Site Web',
          websiteTranslatorTippy: 'Traducteur de site Web',
          urlInputPlaceHolder: 'Collez votre URL ici...',
          websiteTranslateAction: 'Traduire',
          removeAllPreviousWebsiteTranslateRequests: 'Effacer tout',
          previousWebsiteTranslateRequestTitle: 'Historique des demandes :',
          wtLoadingCaption: 'Traduction du site Web...',
          ocrCheckboxLabel:
            'Je télécharge des images et des documents numérisés',
          ocrHelpText: 'Quand activer cette',
          ocrCheckboxTippy:
              'La reconnaissance optique des caractères (OCR) détecte le texte dans les images et les documents numérisés. Essayez cette option si vous avez reçu une traduction vide ou si le contenu texte de votre PDF n’est pas sélectionnable.',
          websitePage: 'Page de traduction de site Web',
          lastWTRequestURL: 'Dernière demande de traduction de site Web :',
          openWebsiteBtnLabel: 'Ouvrir',
          invalidURL: 'Format d’URL non valide',
          ClearURLLabelandTippy: 'Effacer l’URL',
          inactivityLogoutTitle: 'Vous avez été déconnecté(e)',
          inactivityLogoutMessage:
              'Vous avez été déconnecté(e) pour cause d’inactivité',
          inactivityLogoutButtonLabel: 'Se reconnecter',
          logoutScreenMessage: 'Merci d’être venu nous voir!',
          logoutScreenLogoutButtonLabel: 'Se reconnecter',
          langSelect_NoResultsMessage: 'Aucun résultat trouvé',
          reachedQuotaLimitErrorPartOne: 'Vous ne pouvez pas traduire car votre organisation a atteint ses limites de traduction.',
          reachedQuotaLimitErrorPartTwo: 'Contactez la personne qui gère le portail dans votre organisation pour étendre cette option.\n',
          orgExpiredErrorPartOne: 'Vous ne pouvez pas traduire car votre organisation a atteint sa date d’expiration.\n',
          orgExpiredErrorPartTwo: 'Contactez la personne qui gère le portail dans votre organisation pour étendre ces limites.',
          downloadSourceFileSubheader: 'Source',
          downloadTranslatedFileSubheader: "Résultat",
          menuLabel: 'Menu',
          af: 'Afrikaans',
          am: 'Amharic',
          ar: 'Arabe',
          'oc-ES': 'Aranais',
          auto: 'Détecter la langue',
          eu: 'Basque',
          bg: 'Bulgare',
          bn: 'Bangla',
          bs: 'Bosniaque',
          ca: 'Catalan',
          cb: 'Cebuano',
          co: 'Corse',
          cs: 'Tchèque',
          cy: 'Gallois',
          da: 'Danois',
          dv: 'Divehi',
          ee: 'Ewe',
          ga: 'Gaélique (Irlande)',
          gl: 'Galicien',
          de: 'Allemand',
          el: 'Grec',
          en: 'Anglais',
          'en-CA': 'Anglais (Canada)',
          'en-US': 'Anglais (États Unis)',
          'en-GB': 'Anglais (Royaume-Uni)',
          es: 'Espagnol',
          'es-LA': 'Espagnol (Amérique Latine)',
          'es-MX': 'Espagnol (Mexique)',
          'es-XM': 'Espagnol (International)',
          'es-US': 'Espagnol (États Unis)',
          et: 'Estonien',
          fa: 'Persan',
          'fa-AF': 'Dari',
          fi: 'Finnois',
          fo: 'Féroé',
          tl: 'Philippin',
          fj: 'Fidjien',
          fr: 'Français',
          'fr-CA': 'Français (Canadien)',
          'fr-BE': 'Français (Belgique)',
          'fr-FR': 'Français (France)',
          he: 'Hébreu',
          Hans: 'Chinois simplifié (translittération)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Croate',
          ht: 'Créole haïtien',
          hu: 'Hongrois',
          hy: 'Arménien',
          id: 'Indonésien',
          is: 'Islandais',
          it: 'Italien',
          'iu-Latn': 'Inuktitut (Latin)',
          iu: 'Inuktitut',
          ja: 'Japonais',
          Jpan: 'Japonais (translittération)',
          km: 'Khmer',
          ko: 'Coréen',
          ka: 'Géorgien',
          ks: 'Kashmiri',
          fy: 'Frison',
          hw: 'Hawaïen',
          ig: 'Igbo',
          jv: 'Javanais',
          kk: 'Kazakh',
          kmr: 'Kurde (Nord)',
          ku: 'Kurde',
          ky: 'Kirghize',
          lo: 'Lao',
          lt: 'Lituanien',
          'lb-LU': 'Luxembourgeois',
          lv: 'Letton',
          mi: 'Maori',
          mg: 'Malgache',
          mk: 'Macédonien',
          ml: 'Malayalam',
          mn: 'Mongol',
          ms: 'Malais',
          mt: 'Maltais',
          multi: 'Multilingue',
          my: 'Burmese',
          mww: 'Hmong Daw',
          ne: 'Nepali',
          no: 'Norvégien',
          nl: 'Hollandais',
          ns: 'Sepedi',
          'nl-BE': 'Hollandais (Belgique)',
          ny: 'Nyanja',
          oc: 'Occitan',
          or: 'Odia',
          otq: 'Querétaro Otomi',
          ps: 'Pachto',
          pa: 'Pendjabi',
          pl: 'Polonais',
          pt: 'Portugais',
          'pt-PT': 'Portugais (Portugal)',
          'pt-BR': 'Portugais (Brésil)',
          ro: 'Roumain',
          rn: 'Rundi',
          ru: 'Russe',
          rw: 'Kinyarwanda',
          sk: 'Slovaque',
          si: 'Singhalais',
          sl: 'Slovène',
          sm: 'Samoan',
          st: 'Sesotho',
          sn: 'Shona',
          sd: 'Sindhi',
          so: 'Somali',
          su: 'Soundanais',
          sr: 'Serbe',
          'sr-RS-CYRL': 'Serbe (cyrillique)',
          'sr-RS-LATN': 'Serbe (latin)',
          sv: 'Suédois',
          sw: 'Swahili',
          ta: 'Tamoul',
          th: 'Thaïlandais',
          ti: 'Tigrinya',
          tk: 'Turkmène',
          to: 'Tongien',
          tn: "Setswana",
          tr: 'Turc',
          ts:'Tsonga',
          tt: 'Tatar',
          ty: 'Tahitien',
          tw: 'Twi',
          uk: 'Ukrainien',
          ur: 'Ourdou',
          vi: 'Vietnamien',
          yua: 'Yucatèque',
          yue: 'Cantonais',
          sq: 'Albanais',
          az: 'Azerbaïdjanais',
          be: 'Bélarusse',
          eo: 'Espéranto',
          gd: 'Gaélique',
          kn: 'Kannada',
          gu: 'Gujarati',
          ha: 'Haoussa',
          la: 'Latin',
          mr: 'Marathi',
          tg: 'Tadjik',
          te: 'Télougou',
          uz: 'Ouzbek',
          xh: 'Xhosa',
          ug: 'Ouïghour',
          yi: 'Yiddish',
          yo: 'Yoruba',
          'zh-CN': 'Chinois Simplified',
          'zh-TW': 'Chinois Traditional',
          'zh-HK': 'Chinois Hong Kong',
          zu: 'Zoulou',
          // statistics data view
          dataView: 'Données',
          graphView: 'Graphique',
          mainTitleStatisticsDataView: 'Statistiques de l’organisation sur la période sélectionnée',
          genericColumnTitleStatisticsDataView: 'Données complètes',
          textTranslationTitleStatisticsDataView: 'Traduction de textes',
          documentTranslationTitleStatisticsDataView : 'Traduction de documents',
          wordsTranslatedField: 'Mots traduits',
          charsTranslatedField : 'Caractères traduits',
          ratioTextDocumentField: 'Rapport traduction de textes/traduction de documents',
          mostUsedLangsField: 'Combinaison linguistique la plus utilisée',
          totalTextRequestsField : 'Nombre total de demandes',
          totalDocsTranslatedField : 'Nombre total de documents traduits',
          userColumn: 'Utilisateur',
          numberOfWordsColumn: 'Nombre de mots',
          currentQuota: 'Statut actuel du quota',
          sortTableCancel: "Cliquer pour annuler le tri",
          sortTableAscending: "Cliquer pour trier par ordre croissant",
          sortTableDescending: "Cliquer pour trier par ordre décroissant",
        },
      },
      es: {
        translations: {
          textOk: 'De acuerdo',
          FAQ: 'Ayuda',
          English: 'English',
          Catalan: 'Català',
          Spanish: 'Español',
          LangCode: 'es',
          loginTitle: 'Página de inicio de sesión',
          textTitle: 'Página de traducción de texto',
          documentTitle: 'Página de traducción de documentos',
          statisticTitle: 'Página de estadísticas',
          textAreaAnnouncementBox1:
              'área de texto donde puede escribir texto para traducir',
          textAreaAnnouncementBox2:
              'área de texto donde aparecerá la traducción del texto una vez que se haya realizado la traducción.',
          AnnounceModalCloseButton: 'Cerrar',
          AnnounceDialogOpened: 'El diálogo se abre',
          AnnounceDialogClosed: 'El diálogo se cierra',
          AnnounceTextisCleared: 'El texto se borra',
          AnnounceCopyTransactionRecordText: 'Copiar registro de transacción',
          AnnounceCopiedToClipboard: 'Copiado al portapapeles',
          AnnounceCopyTranslatedText: 'Copiar texto traducido',
          ClearTextLabelandTippy: 'Borrar el texto',
          AnnounceTranslatedText: 'Texto traducido',
          headerLogout: 'Cerrar sesión',
          headerH1: 'Servicio de traducción automática',
          headerAccountSettings: 'Configuración de la cuenta',
          headerVersion: 'Versión',
          headerAriaLabelLanguageMenu: 'Seleccionar el idioma de la web',
          headerAriaLabelMenu: 'menú de usuario y de aplicación',
          headerAriaSetEnglish: 'configurar el idioma en inglés',
          headerAriaSetSpanish: 'configurar el idioma en español',
          headerAriaSetCatalan: 'configurar el idioma en catalán',
          dropdownWebsiteLanguage: 'configurar el idioma del sitio web',
          dropdownUserSetting: 'configuración del usuario',
          dropdownsourceLang: 'Idioma origen',
          dropdowntargetLang: 'Idioma objetivo',
          dropdownSelected: 'está seleccionado',
          operations: 'Origen',
          scale: 'Escala',
          linearScale: 'Lineal',
          sqrtScale: 'Raíz cuadrada',
          words: 'Palabras',
          characters: 'Caracteres',
          sidebarTippyText: 'Traductor de texto',
          sidebarText: 'Texto',
          sidebarDocument: 'Documento',
          sidebarTippyDocument: 'Traductor de documentos',
          sidebarTippyStatistic: 'Estadísticas',
          loginh1: 'Se requiere inicio de sesión',
          logoutScreenLogoutButtonLabel: 'Vuelve a iniciar sesión',
          inactivityLogoutButtonLabel: 'Vuelve a iniciar sesión',
          logoutScreenMessage: '¡Gracias por la visita!',
          inactivityLogoutMessage: 'Tu sesión ha caducado por inactividad',
          loginButton: 'Inicia sesión',
          textH1: 'Traducción de texto',
          textH2NavigateLanguageTranslationDropDown:
              'Seleccionar idiomas de origen y destino',
          textOrLabel: 'O',
          textDetectLabel: 'Detectar idioma',
          textTippySwap: 'Intercambiar los idiomas',
          textTippySwapBlocked: '‘Detectar el idioma’ no se puede intercambiar',
          textTippySwapBlockedMulti: '‘Multilingüe’ no se puede intercambiar',
          textH2NavigateTextTranslationArea: 'Área de traducción de texto',
          textSourceTextareaPlaceholder: 'Escribir o pegar el texto aquí',
          textTranslateButton: 'Traducir',
          textCopyTippy: 'Copiar el texto',
          documentH1: 'Traducción de documentos',
          documentOrLabel: 'O',
          documentDetectLabel: 'Detectar lenguaje',
          documentTippyNoFilesSelected: 'No hay documentos seleccionados.',
          documentTippySubNameEmpty:
              'El nombre del envío no puede estar vacío.',
          documentDroph2: 'Arrastrar documentos y soltarlos aquí, o bien',
          documentDrophSelect: 'selecciónelos en su ordenador',
          documentDrophMaxSize: 'Tamaño máximo de archivo de <strong>50MB</strong>, archivos ZIP de hasta <strong>512MB</strong>',
          dragAndDropTooltip: 'Arrastre y suelte',
          documentDropFileMessage: 'Formatos admitidos: ', //doc, docm, docx, dot, dotm, dotx, html, odp, ods, odt, ppt, pptx, rtf, tex, txt, xls, xlsm, xlsx, xml'
          documentH2: 'Traducciones',
          documentDropPAccepted:
              'Formatos aceptados: .doc, docx, xls, xlsx, ppt, txt, rtf, txlm, txlf',
          documentDropPAdd: 'Seleccionar documentos',
          documentDropFormTotal: 'Total: documentos de documentos',
          documentDropFormPlaceholder: 'nombre de la presentación',
          documentDropFormTranslate: 'Traducir',
          documentDropFormAddfiles: 'Añadir documentos',
          documentDropFormCancel: 'Cancelar',
          documentDropUploadRowOCR: '•OCRP Páginas OCR',
          documentDropUploadRowWords: 'Palabras de W',
          documentDropUploadRowChars: 'Caracteres de C',
          documentDropUploadRowUploading: 'Cargar...',
          documentDropUploadRowUploaded: 'Subido',
          documentSubHeaderDate: 'Fecha',
          documentSubHeaderSubName: 'Nombre',
          documentSubHeaderSource: 'Idiomas',
          documentSubHeaderTarget: 'Idioma objetivo',
          documentSubHeaderFiles: 'Descargar',
          documentSubHeaderPages: 'Eliminar',
          submissionNameHeader: "Nombre del trabajo",
          submissionNameMaxLength: 'El nombre del envío puede tener hasta 180 caracteres',
          fileNameHeader: "Nombre del archivo",
          categoryHeader: "Categoría",
          categoryPlaceholder: "Sin categoría",
          submissionNamePlaceholder: "Escriba aquí...",
          fileStatusSuccessful: "Traducido",
          downloadFailedError: "No se puede descargar el archivo porque la traducción ha fallado.",
          downloadingStatisticsMessage: "Creando el archivo, esta operación puede tardar unos minutos",
          downloadingStatisticsError: "Ha ocurrio un error durante la creación del archivo, inténtalo de nuevo o ponte en contacto con el soporte",
          documentSubHeaderActions: "Acciones",
          documentSubHeaderWCount: "Recuento de palabras:",
          documentSubHeaderCCount: "Recuento de caracteres:",
          documentSubHeaderStatus: 'Estado',
          documentSubRowStatusTranslated: 'Texto traducido al',
          documentSubRowStatusDeleting: 'Eliminar...',
          documentSubRowStatusError: 'Error',
          documentSubRowChildStatusTranslating: 'Traducción en curso',
          documentSubRowChildStatusTranslated: 'Traducción exitosa',
          documentSubRowChildStatusTranslatedSuccess: "Traducido",
          documentSubRowChildStatusFailed: "Error",
          documentSubRowChildStatusUploading: 'Cargando',
          documentSubRowChildStatusError: 'Traducción fallida',
          documentSubRowChildDeleteTippy: 'Borrar el documento',
          documentFileIconTippy: 'Archivo',
          documentSubRowChildDownloadTippy: 'Descargar documento traducido',
          documentSubRowChildDownloadSourceTippy: 'Descargar documento fuente',
          documentSubRowChildDownloadHeader: 'Descargar',
          documentSubRowChildDownloadLoadingTippy: "Cargando descarga",
          documentSubRowChildCopyToClipboardTippy: "Copiar al portapapeles",
          documentSubRowChildCopiedToClipboardTippy: "Copiado al portapapeles",
          documentSubRowChildFailedTranslationHelpTippy: "Ayuda sobre el error de traducción",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Cerrar ayuda sobre el error de traducción",
          documentSubRowChildDownloadSourceDropdown: "Descargar en el formato de origen",
          documentSubRowChildDownloadMoreOptions: 'Más opciones de descarga',
          documentSubRowChildExpand: 'Expandir',
          documentSubRowChildCollapse: 'Colapsar',
          documentSubRowZipping: 'Comprimiendo',
          documentSubRowZipAndDownload: 'Comprimir y descargar',
          documentSubRowZipHelp: 'Ayuda para comprimir',
          documentSubRowDownloadZip: 'Descargar zip',
          documentSubRowDownloadZipError: 'Error al comprimir',
          documentSubRowChildHumanTranslationRequestedTippy: "Traducción humana solicitada",
          documentSubRowChildHumanTranslationFailedTippy: "Error en la solicitud de traducción humana",
          documentSubRowChildRequestHumanTranslationTippy: "Solicitar traducción humana",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Cerrar menú de traducción humana",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Cargando solicitud",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Abre GL Portal en una pestaña nueva",
          documentSubRowChildHumanTranslationRequestInProcess: "Solicitud de traducción humana en proceso.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Finalizar en GL Portal",
          documentSubRowChildHumanTranslationRequest: "Solicitar una traducción humana",
          documentSubRowChildHumanTranslationRequested: "Traducción humana solicitada",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Comprobar el estado en GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Ir a GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (coste adicional)",
          documentSubRowChildEditTranslatedFile: 'Editar archivo traducido',
          documentSubRowChildMoreActionsTooltip: 'Más acciones',
          helpSentencePartOne: 'Para obtener ayuda, haga clic ',
          helpSentencePartTwo: 'aquí',
          helpSentencePartTwoHover: "Esto abrirá un nuevo mensaje de correo electrónico para Soporte",
          helpSentencePartThree: ' para ponerse en contacto con el servicio de asistencia. Describa su problema en el cuerpo de su correo electrónico.',
          documentSubRowChildDownloadPdfDropdown: 'Descargar como pdf',
          documentSubRowChildLanguageTo: 'a',
          documentTippyAdd: 'Seleccionar documentos',
          deleteModalTitle: 'Eliminar documento',
          deleteModalCheck: '¿Seguro que quieres eliminar este documento?',
          deleteModalCancel: 'Cancelar',
          deleteModalDelete: 'Eliminar',
          modalH1: 'Subir documentos',
          modalH1AfterUpload: 'Documentos subidos',
          modalPTop:
              'Has seleccionado los siguientes documentos para traducir:',
          modalTippyAdd: 'Añadir más documentos',
          modalConfirm: 'Confirmar los idiomas de origen y de destino',
          modalTippySwap: 'Intercambiar idiomas',
          modalTranslate: 'Traducir',
          modalLoading: 'Carga...',
          modalUploadSuccessful: 'Traducción en curso',
          modalPdfAutodetectError:
              'La detección de idioma no está disponible para documentos pdf',
          processingRequestMsg: 'Procesando la petición…',

          modalUploadErrorMessagePartOne:'Hemos encontrado un error al cargar sus archivos.',
          modalUploadErrorMessagePartTwo:'Inténtelo de nuevo.',
          modalUploadErrorMessagePartThree:'Si el problema persiste ',
          modalUploadErrorMessagePartFour: 'póngase en contacto con el servicio de asistencia.',
          modalMsgEmlAutoDetectError: 'Seleccione el idioma original de su correo electrónico en “Idioma de origen” para traducirlo correctamente.',
          modalPdfAutodetectOcrError: 'Seleccione el idioma original de sus imágenes o pdf en “Idioma de origen” para traducirlos correctamente.',
          mandatoryCategoryAndSubmissionNameError: 'Seleccione una categoría y un nombre de envío para continuar con su traducción.',
          mandatorySubmissionNameError: 'Seleccione un nombre de envío para continuar con su traducción.',
          mandatoryCategoryError: 'Seleccione una categoría para continuar con su traducción.',
          deleteModalProblem: 'Estamos teniendo problemas para eliminar su archivo. Cierre este modal y vuelva a intentarlo.',
          unsupportedFileMessage: 'No podemos traducir uno o más archivos. Conviértalos e inténtelo de nuevo.\n',
          statisticErrorTime: 'La fecha de inicio parece ser posterior a la fecha de finalización. Elija una fecha de inicio anterior a la fecha de finalización.',
          statisticErrorFutureDate: 'La fecha de inicio parece estar en el futuro. Elija una fecha de inicio anterior a hoy.',
          unsupportedSourceLang: 'El idioma detectado no está disponible en su organización. Para obtener más idiomas, póngase en contacto con la persona que gestiona el portal en su organización.',
          reachedQuotaLimitError: 'Su organización ha alcanzado sus límites de traducción. Póngase en contacto con la persona que gestiona el portal en su organización para ampliar estos límites.',
          orgExpiredError: 'No puede traducir porque su organización ha alcanzado su fecha de caducidad. Póngase en contacto con la persona que gestiona el portal en su organización para ampliar estos límites.',
          textTranslationError: 'Error de traducción. Hay asistencia disponible para obtener ayuda.',
          statisticRequestError:'Hemos encontrado un error al crear su informe. Inténtelo de nuevo.',
          downloadPdfError: 'No podemos convertir el formato de archivo cargado a pdf.',
          noDataError: 'No hemos podido encontrar ninguna traducción entre estas fechas. Si cree que se trata de un error, hay asistencia disponible.',
          'AP-12-001': 'El archivo parece estar vacío, compruébelo e inténtelo de nuevo.',
          'AP-12-001-desc': 'El archivo parece estar vacío, compruébelo e inténtelo de nuevo.',
          'AP-12-001-desc-pdf':'No se detectó texto. Vuelva a intentarlo asegurándose de que el OCR esté habilitado.',
          'AP-01-003-desc': 'El archivo supera los límites de palabras para su organización. Por favor, divídalo en trozos más pequeños e inténtelo de nuevo.',
          'WFM-04-SRCTRGSAME': 'El idioma de origen y de destino no puede ser el mismo. Modifique uno de ellos',
          'WFM-04-SRCTRGSAME-desc':'El idioma de origen y de destino no puede ser el mismo. Modifique uno de ellos',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'El idioma de origen y de destino no puede ser el mismo. Modifique uno de ellos',
          'USRMNG-DB-1062': 'Este usuario ya existe. Búsquela y realice los cambios correspondientes.',
          'STT-DOC-04-SIZE': 'El tamaño máximo del fichero es de 200MB. Divida su archivo e inténtelo de nuevo.',
          'STT-fileSIZE': 'Puede traducir archivos de hasta 50MB. Elimine los archivos más grandes, divídalos en otros más pequeños y vuelva a intentarlo.',
          'STT-fileSIZE-zip': 'Puede traducir archivos zip de hasta 512MB. Elimine los archivos más grandes, divídalos en otros más pequeños y vuelva a intentarlo.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Cargue un archivo wav a la vez o intente traducir el documento para traducirlo',
          TT_WAV_ERROR_AUTO_DETECT:'Seleccione el idioma original de sus archivos wav en “Idioma de origen” para traducirlos correctamente.',
          TT_WAV_ERROR_FILE_TYPE:'Usar la traducción de documentos para traducir este tipo de archivo',
          TT_WAV_ERROR_FILE_SIZE: 'El tamaño máximo del archivo es de 200MB. Divida su archivo e inténtelo de nuevo',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Traduzca los archivos PDF y wav por separado',
          'WFM-error': 'Hemos encontrado un error al procesar su archivo. Inténtelo de nuevo',
          'WFM-error-desc': 'Hemos encontrado un error al procesar su archivo. Inténtelo de nuevo',
          'WFM-04-INVLANG': 'El idioma detectado no está disponible en su organización. Para obtener más idiomas, póngase en contacto con la persona que gestiona el portal en su organización.',
          'WFM-04-INVLANG-desc': 'El idioma detectado no está disponible en su organización. Para obtener más idiomas, póngase en contacto con la persona que gestiona el portal en su organización.',
          'OCR-03-A2A-FAIL': 'Hemos encontrado un error al traducir su archivo. Inténtelo de nuevo',
          'OCR-03-A2A-TIMEOUT': 'Hemos encontrado un error al traducir su archivo. Inténtelo de nuevo',


          statistics: 'Estadísticas',
          statisticFrom: 'Desde:',
          statisticTo: 'Hasta:',
          users: 'Usuario',
          allUsers: 'Todos',
          allLanguages: 'Todos',
          allOperations: 'Todos',
          textTranslateOperations: 'Traducción de texto',
          documentTranslateOperations: 'Traducción de documento',
          speechDocumentOperations: 'Conversión de voz a texto',
          transliteration: "Transliteración",
          websiteTranslateOperations: "Traducción de sitio web",
          speechTranscription: "Transcripción de voz",
          loadTimeFrame: 'Cargar periodo de tiempo',
          statisticDownload: 'Descargar el informe',
          textTranslationReachedLimit: 'Puede traducir hasta 10 000 caracteres por solicitud',
          'OCR-03-A2A-PASSWORD':
              'Se ha producido un error, quita la contraseña del PDF',
          'OCR-03-A2A-PASSWORD-desc-pdf':
              'Se ha producido un error, quita la contraseña del PDF',
          'AP-08-001': 'Se ha producido un error, quita la contraseña del PDF',
          'AP-08-001-desc-pdf':
              'Se ha producido un error, quita la contraseña del PDF',
          'OCR-03-A2A-SIZE-EX':
              'El tamaño máximo de la página debe ser A4 para poder realizar la conversión del PDF',
          'OCR-03-A2A-HEALTH':
              'El ROC no está disponible temporalmente, inténtalo de nuevo',
          'MT-error': 'Se ha producido un error con el motor de traducción',
          textSourceTextareaButton: 'Seleccionar documentos',
          copyright: ' Translations.com Todos los derechos reservados.',
          quickLinksLabel: 'Enlaces rápidos:',
          'MT-error-desc': 'Error con el motor de traducción',
          TT_WAV_ERROR_NO_FILES_DETECTED:
              'No hemos podido detectar ningún archivo WAV',
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT:
              'Transcripción en progreso. Se cancelará la transcripción si se abandona la página',
          qualityTranslationEngineInfo: "TransPerfect Main Engine le permite estar informado sobre el nivel de fidelidad que tiene nuestra traducción de acuerdo con sus requisitos. ¡Pruebe!",
          infoQualityControlFeature: "El control de calidad es la característica que le permite estar informado sobre el nivel de fidelidad que tiene nuestra traducción según sus necesidades. El nivel de fidelidad se define como la precisión de la traducción que le proporcionamos según lo establecido por sus requisitos.",
          transliterationTippy: 'Transcribir texto',
          infoQualityControlFeatureTooltip: 'Para garantizar la calidad y transparencia de nuestro producto, TransPerfect Main Engine le permite estar informado sobre el nivel de fidelidad que tiene nuestra traducción según sus necesidades.',
          infoQualityControlFeatureTooltipLearnMore: 'Más información',
          translationQualityGood: '¡La traducción se ve genial!',
          translationQualityAverage: 'La traducción puede tener algunos errores, ¡utilícela con precaución!',
          translationQualityBad: 'Es probable que la traducción sea problemática, se recomienda una revisión.',
          translationQualityGoodModal: 'Buena',
          translationQualityAverageModal: 'Usar con precaución',
          translationQualityBadModal: 'Probablemente problemático',
          translationQualityGoodModalDescription: 'Puede confiar en esta traducción.',
          translationQualityAverageModalDescription: 'Puede haber algunas imperfecciones en la traducción. Recomendamos su uso interno. Para uso externo, le recomendamos que lo compruebe con un traductor profesional.',
          translationQualityBadModalDescription: 'Esta traducción puede tener algunos errores importantes. Le recomendamos que lo corrija con un traductor profesional.',
          websiteTranslator: 'Sitio web',
          websiteTranslatorTippy: 'Traductor de sitios web',
          urlInputPlaceHolder: 'Pega tu URL aquí...',
          websiteTranslateAction: 'Traducir',
          removeAllPreviousWebsiteTranslateRequests: 'Eliminar todo',
          previousWebsiteTranslateRequestTitle: 'Solicitudes anteriores',
          wtLoadingCaption: 'Traduciendo sitio web...',
          ocrCheckboxLabel:
            'Estoy cargando imágenes y documentos escaneados',
          ocrHelpText: 'Cuándo activar esto',
          ocrCheckboxTippy:
              'El OCR (reconocimiento óptico de caracteres) detecta texto en imágenes y documentos escaneados. Utiliza esta opción si has recibido una traducción vacía o si el contenido del texto del PDF no se puede seleccionar.',

          lastWTRequestURL: 'Última solicitud de traducción del sitio web:',
          openWebsiteBtnLabel: 'Abrir',
          invalidURL: 'Formato de URL inválido',
          ClearURLLabelandTippy: 'Borrar URL',
          reachedQuotaLimitErrorPartOne: 'No puede traducir porque su organización ha alcanzado sus límites de traducción.',
          reachedQuotaLimitErrorPartTwo: 'Póngase en contacto con la persona que gestiona el portal en su organización para ampliarlo.',
          orgExpiredErrorPartOne: 'No puede traducir porque su organización ha alcanzado su fecha de caducidad.',
          orgExpiredErrorPartTwo: 'Póngase en contacto con la persona que gestiona el portal en su organización para ampliar estos límites.',
          downloadSourceFileSubheader: 'Fuente',
          downloadTranslatedFileSubheader: "Resultado",
          // statistics data view
          dataView: 'Datos',
          graphView: 'Gráfico',
          mainTitleStatisticsDataView: 'Estadísticas de la organización sobre el período seleccionado',
          genericColumnTitleStatisticsDataView: 'Total',
          textTranslationTitleStatisticsDataView: 'Traducción de texto',
          documentTranslationTitleStatisticsDataView: 'Traducción de documentos',
          wordsTranslatedField: 'Palabras traducidas',
          charsTranslatedField: 'Caracteres traducidos',
          ratioTextDocumentField: 'Proporción de traducción de textos/traducción de documentos',
          mostUsedLangsField: 'Combinación de idiomas más frecuentes',
          totalTextRequestsField: 'Total de solicitudes',
          totalDocsTranslatedField: 'Total de documentos traducidos',
          userColumn: 'Usuario',
          numberOfWordsColumn: 'Número de palabras',
          currentQuota: 'Estado actual de la cuota',
          sortTableCancel: "Haga clic para cancelar la ordenación",
          sortTableAscending: "Haga clic para ordenar de forma ascendente",
          sortTableDescending: "Haga clic para ordenar de forma descendente",

          //Lang names
          af: 'Afrikaans',
          am: 'Amharic',
          ar: 'Árabe',
          'oc-ES': 'Aranés',
          auto: 'Detectar el idioma',
          eu: 'Vasco',
          bg: 'Búlgaro',
          bn: 'Bengalí',
          bs: 'Bosnio',
          ca: 'Catalán',
          cb: 'Cebuano',
          co: 'Corso',
          cs: 'Checo',
          cy: 'Galés',
          da: 'Danés',
          dv: 'Divehi',
          ee: 'Ewe',
          ga: 'Gaélico (Irlanda)',
          gl: 'Gallego',
          de: 'Alemán',
          el: 'Griego',
          en: 'Inglés',
          'en-CA': 'Inglés (Canadá)',
          'en-US': 'Inglés (Estados Unidos)',
          'en-GB': 'Inglés (Reino Unido)',
          es: 'Español',
          'es-LA': 'Español (America Latina)',
          'es-MX': 'Español (México)',
          'es-XM': 'Español (Internacional)',
          'es-US': 'Español (Estados Unidos)',
          et: 'Estonio',
          fa: 'Persa',
          'fa-AF': 'Darí',
          fi: 'Finlandés',
          fo: 'Faroeso',
          fj: 'Fiji',
          fr: 'Francés',
          'fr-BE': 'Francés (Bélgica)',
          'fr-CA': 'Francés (Canadá)',
          'fr-FR': 'Francés (Francia)',
          he: 'Hebreo',
          Hans: 'Chino simplificado (transliteración)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hy: 'Armenio',
          tl: 'Filipino',
          hr: 'Croata',
          ht: 'Criollo Haitiano',
          hu: 'Húngaro',
          id: 'Indonesio',
          is: 'Islandés',
          it: 'Italiano',
          'iu-Latn': 'Inuktitut (Latino)',
          iu: 'Inuktitut',
          ja: 'Japonés',
          Japn: 'Japonés (transliteración)',
          ko: 'Coreano',
          'lb-LU': 'Luxemburgués',
          lo: 'Lao',
          lt: 'Lituano',
          lv: 'Letón',
          mg: 'Malgache',
          mk: 'Macedonio',
          mn: 'Mongol',
          ms: 'Malayo',
          mt: 'Maltés',
          multi: 'Multilingüe',
          my: 'Birmano',
          mww: 'Hmong Daw',
          ne: 'Nepalí',
          no: 'Noruego',
          nl: 'Holandés',
          'nl-BE': 'Holandés (Bélgica)',
          ns: 'Sepedi',
          oc: 'Occitano',
          otq: 'Querétaro Otomi',
          pl: 'Polaco',
          pt: 'Portugués',
          'pt-PT': 'Portugués (Portugal)',
          'pt-BR': 'Portugués (Brasil)',
          ro: 'Rumano',
          ru: 'Ruso',
          rw: 'Kinyarwanda',
          si: 'Cingalés',
          sk: 'Eslovaco',
          sl: 'Esloveno',
          sm: 'Samoano',
          st: 'Sesoto',
          sn: 'Shona',
          sd: 'Sindi',
          so: 'Somalí',
          su: 'Sondanés',
          sr: 'Serbio',
          'sr-RS-CYRL': 'Serbio (Cirílico)',
          'sr-RS-LATN': 'Serbio (Latino)',
          sv: 'Sueco',
          sw: 'Kiswahili',
          ta: 'Tamil',
          th: 'Tailandés',
          tk: 'Turcomano',
          ti: 'Tigriña',
          to: 'Tonga',
          tr: 'Turco',
          tn: "Setswana",
          tt: 'Tártaro',
          ty: 'Tahitian',
          tw: 'Twi',
          uk: 'Ucraniano',
          ur: 'Urdu',
          vi: 'Vietnamita',
          yua: 'Yucateco Maya',
          yue: 'Cantonés',
          sq: 'Albanés',
          az: 'Azerbaiyano',
          be: 'Bielorruso',
          eo: 'Esperanto',
          gd: 'Gaélico',
          kn: 'Kannada',
          km: 'Khmer',
          ka: 'Georgiano',
          ks: 'Kashmiri',
          gu: 'Gujarati',
          ha: 'Hausa',
          la: 'Latín',
          mr: 'Marathi',
          tg: 'Tajik',
          te: 'Telugu',
          uz: 'Uzbeko',
          ug: 'Uigur',
          xh: 'Xhosa',
          yi: 'Yiddish',
          yo: 'Yoruba',
          zu: 'Zulú',
          fy: 'Frisón',
          hw: 'Hawaiano',
          ig: 'Igbo',
          jv: 'Javanés',
          kk: 'Kazajo',
          kmr: 'Kurdo (Norte)',
          ku: 'Kurdo',
          ky: 'Kirguís',
          mi: 'Maorí',
          ny: 'Chichewa',
          or: 'Oriya',
          ps: 'Pastún',
          pa: 'Punyabí',
          rn: 'Rundi',
          ml: 'Malayalam',
          ts:'Tsonga',
          'zh-CN': 'Chino Simplificado',
          'zh-TW': 'Chino Tradicional',
          'zh-HK': 'Chino Hong Kong',
        },
      },
      'de': {
        translations: {
          textOk: 'Ok',
          FAQ: 'Hilfe',
          textTitle: 'Seite Textübersetzung',
          documentTitle: 'Seite Dokumentübersetzung',
          loginTitle: 'Anmelden',
          statisticTitle: 'Statistik-Seite',
          textAreaAnnouncementBox1: 'Textbereich, in dem Sie einen Text zur Übersetzung eingeben können.',

          textAreaAnnouncementBox2: 'Textbereich, in dem die Textübersetzung angezeigt wird, sobald die Übersetzung abgeschlossen ist.',

          AnnounceModalCloseButton: 'Schließen',
          AnnounceDialogOpened: 'Dialog wird geöffnet',
          AnnounceDialogClosed: 'Dialog wird geschlossen',
          AnnounceTextisCleared: 'Text wird gelöscht',
          AnnounceCopyTransactionRecordText: 'Transaktionsdatensatz kopieren',
          AnnounceCopiedToClipboard: 'In Zwischenablage kopiert',
          AnnounceCopyTranslatedText: 'Übersetzten Text kopieren',
          ClearTextLabelandTippy: 'Text löschen',
          AnnounceTranslatedText: 'Übersetzter Text',
          headerH1: 'Maschineller Übersetzungsservice',
          headerLogout: 'Abmelden',
          headerAccountSettings: 'Kontoeinstellungen',
          headerVersion: 'Version',
          dropdownWebsiteLanguage: 'Website-Sprache festlegen',
          dropdownUserSetting: 'Benutzereinstellung',
          dropdownsourceLang: 'Quellsprache',
          dropdowntargetLang: 'Zielsprache',
          operations: 'Herkunft',
          allOperations: 'Alle',
          textTranslateOperations: 'Textübersetzung',
          documentTranslateOperations: 'Dokumentübersetzung',
          speechDocumentOperations: 'Sprache zu Text',
          transliteration: "Transliteration",
          websiteTranslateOperations: "Website-Übersetzung",
          speechTranscription: "Sprachtranskription",
          words: 'Wörter',
          characters: 'Zeichen',
          scale: 'Skala',
          total: 'Summe',
          linearScale: 'Linear',
          sqrtScale: 'Quadratische Wurzel',
          dropdownSelected: 'ist ausgewählt',
          sidebarTippyText: 'Textübersetzer',
          sidebarText: 'Text',
          sidebarDocument: 'Dokument',
          sidebarTippyDocument: 'Dokumentübersetzer',
          loginh1: 'Anmeldung erforderlich',
          loginButton: 'Anmelden',
          textH2NavigateLanguageTranslationDropDown: 'Quell- und Zielsprachen auswählen',

          textTippySwap: 'Sprachen austauschen',
          textTippySwapBlocked: '„Sprache erkennen“ kann nicht ausgetauscht werden ',
          textTippySwapBlockedMulti: '„Mehrsprachig“ kann nicht ausgetauscht werden ',
          textH2NavigateTextTranslationArea: 'Textübersetzungsbereich',
          textSourceTextareaPlaceholder: 'Geben Sie Ihren Text hier ein oder fügen Sie ihn ein...',
          textSourceTextareaButton: 'Dateien auswählen',
          textTranslateButton: 'Übersetzen',
          textCopyTippy: 'Text kopieren',
          documentH1: 'Dokumentübersetzung',
          documentDroph2: 'Dateien per Drag-and-Drop eingeben oder',
          documentDrophSelect: 'wählen Sie sie auf Ihrem Computer aus',
          documentDrophMaxSize: 'Max. Dateigröße <strong>50MB</strong>, ZIP-Dateien bis zu <strong>512MB</strong>',
          dragAndDropTooltip: 'Ziehen und ablegen',
          documentDropFileMessage: 'Unterstützte Dateiformate: ',
          documentH2: 'Übersetzungen',
          documentSubHeaderDate: 'Datum',
          documentSubHeaderSubName: 'Name',
          documentSubHeaderSource: 'Sprachen',
          documentSubHeaderTarget: 'Zielsprache',
          documentSubHeaderFiles: 'Download',
          documentSubHeaderPages: 'Löschen',
          submissionNameHeader: "Einreichungsname",
          submissionNameMaxLength: 'Der Einreichungsname kann bis zu 180 Zeichen lang sein',
          fileNameHeader: "Dateiname",
          categoryHeader: "Kategorie",
          categoryPlaceholder: "Keine Kategorie",
          submissionNamePlaceholder: "Text hier eingeben …",
          fileStatusSuccessful: "Übersetzt",
          downloadFailedError: "Datei kann nicht heruntergeladen werden, da die Übersetzung fehlgeschlagen ist.",
          downloadingStatisticsMessage: "Erstellen Sie Ihren Bericht, dies kann einige Minuten dauern...",
          downloadingStatisticsError: "Beim Erstellen Ihres Berichts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
          documentSubHeaderActions: "Vorgänge",
          documentSubHeaderWCount: "Wortanzahl:",
          documentSubHeaderCCount: "Zeichenanz.:",
          documentSubHeaderStatus: 'Status',
          documentSubRowStatusDeleting: 'Löschung...',
          documentSubRowChildStatusTranslating: 'Wird übersetzt',
          documentSubRowChildStatusTranslated: 'Übersetzung erfolgreich',
          documentSubRowChildStatusTranslatedSuccess: 'Übersetzt',
          documentSubRowChildStatusError: 'Übersetzung fehlgeschlagen',
          documentSubRowChildStatusFailed: 'Fehlgeschlagen',
          documentSubRowChildStatusUploading: 'Hochladen',
          documentSubRowChildDeleteTippy: 'Datei löschen',
          documentFileIconTippy: 'Datei',
          documentSubRowChildDownloadTippy: 'Übersetzte Datei Download',
          documentSubRowChildDownloadSourceTippy: 'Quelldatei Download',
          documentSubRowChildDownloadHeader: 'Download',
          documentSubRowChildDownloadLoadingTippy: "Download wird geladen",
          documentSubRowChildCopyToClipboardTippy: "In Zwischenablage kopieren",
          documentSubRowChildCopiedToClipboardTippy: "In Zwischenablage kopiert",
          documentSubRowChildFailedTranslationHelpTippy: "Hilfe für fehlgeschlagene Übersetzung",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Hilfe für fehlgeschlagene Übersetzung schließen",
          documentSubRowChildDownloadSourceDropdown: "Im Quellformat herunterladen",
          documentSubRowChildDownloadMoreOptions: 'Weitere Download-Optionen',
          documentSubRowChildExpand: 'Erweitern',
          documentSubRowChildCollapse: 'Zusammenbrechen',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Zip und Download',
          documentSubRowZipHelp: 'Hilfe mit Zip',
          documentSubRowDownloadZip: 'Zip herunterladen',
          documentSubRowDownloadZipError: 'Fehler beim Zippen.',
          documentSubRowChildHumanTranslationRequestedTippy: "Menschliche Übersetzung angefordert",
          documentSubRowChildHumanTranslationFailedTippy: "Anfrage nach menschlicher Übersetzung fehlgeschlagen",
          documentSubRowChildRequestHumanTranslationTippy: "Menschliche Übersetzung anfordern",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Menü für die menschliche Übersetzung schließen",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Anfrage wird geladen",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Öffnet GL Portal in einer neuen Registerkarte",
          documentSubRowChildHumanTranslationRequestInProcess: "Anfrage nach menschlicher Übersetzung in Bearbeitung",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Auf GL Portal abschließen",
          documentSubRowChildHumanTranslationRequest: "Eine menschliche Übersetzung anfordern",
          documentSubRowChildHumanTranslationRequested: "Menschliche Übersetzung angefordert",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Status auf GL Portal überprüfen",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Zu GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (zusätzliche Kosten)",
          documentSubRowChildEditTranslatedFile: 'Übersetzte Datei bearbeiten',
          documentSubRowChildMoreActionsTooltip: 'Weitere Aktionen',
          helpSentencePartOne: "Wenden Sie sich an den Support ",
          helpSentencePartTwo: 'hier',
          helpSentencePartTwoHover: 'Dadurch wird eine neue E-Mail-Nachricht an den Support geöffnet',
          helpSentencePartThree: 'Bitte beschreiben Sie Ihr Problem im Text Ihrer E-Mail.',
          documentSubRowChildDownloadPdfDropdown: 'Als PDF herunterladen',
          documentSubRowChildLanguageTo: '>',
          documentTippyAdd: 'Dateien auswählen',
          deleteModalTitle: 'Datei löschen',
          deleteModalCheck: 'Möchten Sie diese Datei wirklich löschen?',
          deleteModalCancel: 'Abbrechen',
          deleteModalDelete: 'Löschen',
          modalH1: 'Laden Sie Ihre Dateien hoch',
          modalH1AfterUpload: 'Dateien hochgeladen',
          modalPTop: 'Sie haben die folgenden zu übersetzenden Dateien ausgewählt:',
          modalTippyAdd: 'Weitere Dateien hinzufügen',
          modalConfirm: 'Quell- und Zielsprachen bestätigen',
          modalTippySwap: 'Sprachen austauschen',
          modalTranslate: 'Übersetzen',
          modalLoading: 'Laden...',
          modalUploadSuccessful: 'Übersetzung läuft',
          processingRequestMsg: 'Anfrage wird bearbeitet...',

          modalUploadErrorMessagePartOne:'Beim Hochladen Ihrer Dateien ist ein Fehler aufgetreten.',
          modalUploadErrorMessagePartTwo:'Bitte versuchen Sie es erneut.',
          modalUploadErrorMessagePartThree:'Wenn das Problem weiterhin besteht ',
          modalUploadErrorMessagePartFour: 'den Support kontaktieren.',
          modalMsgEmlAutoDetectError: 'Die erkannte Sprache ist in Ihrer Organisation nicht verfügbar. Weitere Sprachen erhalten Sie von der Person, die das Portal in Ihrer Organisation verwaltet.',
          modalPdfAutodetectOcrError: 'Bitte wählen Sie die Originalsprache Ihrer Bilder oder PDFs unter „Quellsprache“ aus, um sie erfolgreich zu übersetzen.',
          mandatoryCategoryAndSubmissionNameError: 'Bitte wählen Sie eine Kategorie und einen Einreichungsnamen aus, um mit Ihrer Übersetzung fortzufahren.',
          mandatorySubmissionNameError: 'Bitte wählen Sie einen Submission-Namen aus, um mit der Übersetzung fortzufahren.',
          mandatoryCategoryError: 'Bitte wählen Sie eine Kategorie aus, um mit der Übersetzung fortzufahren.',
          deleteModalProblem: 'Wir haben Probleme beim Löschen Ihrer Datei. Bitte schließen Sie dieses Modal und versuchen Sie es erneut.',
          unsupportedFileMessage: 'Wir können keine oder mehrere Dateien übersetzen. Bitte konvertieren Sie sie und versuchen Sie es erneut.',
          statisticErrorTime: 'Das Startdatum scheint nach dem Enddatum zu liegen. Bitte wählen Sie ein Startdatum vor dem Enddatum aus.',
          statisticErrorFutureDate: 'Das Startdatum scheint in der Zukunft zu liegen. Bitte wählen Sie ein Startdatum vor dem heutigen Tag aus.',
          unsupportedSourceLang: 'Die erkannte Sprache ist in Ihrer Organisation nicht verfügbar. Weitere Sprachen erhalten Sie von der Person, die das Portal in Ihrer Organisation verwaltet.',
          reachedQuotaLimitError: 'Your organization reached its translations limits. Contact the person managing the portal in your org to extend these limits.',
          orgExpiredError: 'You cannot translate because your organization has reached its expiration date. Contact the person managing the portal in your org to extend these limits.',
          textTranslationError: 'Übersetzung fehlgeschlagen. Support steht für Hilfe zur Verfügung.',
          statisticRequestError:'Bei der Erstellung Ihres Berichts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
          downloadPdfError: 'Wir können das hochgeladene Dateiformat nicht in PDF konvertieren.',
          noDataError: 'Zwischen diesen Daten konnten wir keine Übersetzungen finden. Wenn Sie der Meinung sind, dass es sich um einen Fehler handelt, steht Unterstützung zur Verfügung.',
          'AP-12-001': 'File seems to be empty, please check and try again.',
          'AP-12-001-desc': 'File seems to be empty, please check and try again.',
          'AP-12-001-desc-pdf':'No text was detected. Please, try again making sure OCR is enabled.',
          'AP-01-003-desc': 'Datei überschreitet Wortlimits für Ihr Unternehmen. Bitte teilen Sie es in kleinere Teile auf und versuchen Sie es erneut.',
          'WFM-04-SRCTRGSAME': 'Source and target language cannot be the same. Please, modify one of them',
          'WFM-04-SRCTRGSAME-desc':'Source and target language cannot be the same. Please, modify one of them',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'Source and target language cannot be the same. Please, modify one of them',
          'USRMNG-DB-1062': 'This user already exists. Please, search for it and make the corresponding changes.',
          'STT-DOC-04-SIZE': 'Maximum wav size is 200MB. Please, divide your file and try again.',
          'STT-fileSIZE': 'Sie können Dateien mit bis zu 50MB übersetzen. Bitte entfernen Sie größere Dateien, teilen Sie sie in kleinere auf und versuchen Sie es erneut.',
          'STT-fileSIZE-zip': 'Sie können zip Dateien mit bis zu 512MB übersetzen. Bitte entfernen Sie größere Dateien, teilen Sie sie in kleinere auf und versuchen Sie es erneut.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Bitte laden Sie jeweils eine wav-Datei hoch oder versuchen Sie, das Dokument zu übersetzen, um es zu übersetzen',
          TT_WAV_ERROR_AUTO_DETECT:'Bitte wählen Sie die Originalsprache Ihrer wav-Dateien in „Quellsprache“ aus, um sie erfolgreich zu übersetzen.',
          TT_WAV_ERROR_FILE_TYPE:'Dokumentübersetzung verwenden, um diesen Dateityp zu übersetzen',
          TT_WAV_ERROR_FILE_SIZE: 'Die maximale Dateigröße beträgt 200MB. Teilen Sie Ihre Datei auf und versuchen Sie es erneut',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Bitte übersetzen Sie PDF- und wav-Dateien separat',
          'WFM-error': 'We found an error processing your file. Please, try again',
          'WFM-error-desc': 'We found an error processing your file. Please, try again',
          'WFM-04-INVLANG': 'The detected language is not available in your organization. For more languages contact the person managing the portal in your org.',
          'WFM-04-INVLANG-desc': 'The detected language is not available in your organization. For more languages contact the person managing the portal in your org.',
          'OCR-03-A2A-FAIL': 'Beim Übersetzen Ihrer Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
          'OCR-03-A2A-TIMEOUT': 'Beim Übersetzen Ihrer Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',


          copyright: ' Translations.com Alle Rechte vorbehalten.',
          statistics: 'Statistiken',
          statisticFrom: 'Von:',
          statisticTo: 'An:',
          statisticDownload: 'Bericht herunterladen',
          textTranslationReachedLimit: 'Sie können bis zu 10.000 Zeichen pro Anfrage übersetzen',
          users: 'Benutzer',
          allUsers: 'Alle',
          allLanguages: 'Alle',
          loadTimeFrame: 'Zeitrahmen laden',
          'OCR-03-A2A-PASSWORD': 'Fehler, Passwort aus der PDF entfernen',
          'OCR-03-A2A-PASSWORD-desc-pdf': 'Die PDF ist passwortgeschützt, bitte entfernen Sie das Passwort aus der PDF',
          'AP-08-001': 'Fehler, Passwort aus der PDF entfernen',
          'AP-08-001-desc-pdf': 'Die PDF ist passwortgeschützt, bitte entfernen Sie das Passwort aus der PDF',
          'OCR-03-A2A-SIZE-EX': 'Die maximale Seitengröße für die PDF-Konvertierung beträgt A4',
          quickLinksLabel: 'Quicklinks:',
          'OCR-03-A2A-HEALTH': 'OCR vorübergehend nicht verfügbar, versuchen Sie es erneut',
          'MT-error': 'Fehler bei der Übersetzungs-Engine',
          'USRMNG-DB-1452': 'Ungültiger Wert',
          TT_WAV_ERROR_NO_FILES_DETECTED: "Wir konnten keine WAV-Dateien erkennen",
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: 'Die Transkription wird noch verarbeitet. Die Transkription geht verloren, wenn Sie diese Seite jetzt verlassen',
          qualityTranslationEngineInfo: "TransPerfect Main Engine ermöglicht es Ihnen, über den Grad der Genauigkeit informiert zu werden, den unsere Übersetzung gemäß Ihren Anforderungen hat. Testen Sie es!",
          infoQualityControlFeature: "Qualitätskontrolle ist die Funktion, die es Ihnen ermöglicht, über das Maß an Genauigkeit informiert zu werden, das unsere Übersetzung Ihren Bedürfnissen entsprechend hat. Der Grad der Genauigkeit ist definiert als die Genauigkeit der Übersetzung, die wir Ihnen gemäß Ihren Anforderungen zur Verfügung stellen.",
          transliterationTippy: 'Text transliterieren',
          infoQualityControlFeatureTooltip: 'Um die Qualität und Transparenz unseres Produkts zu gewährleisten, können Sie mit der TransPerfect Main Engine über den Grad der Genauigkeit informiert werden, den unsere Übersetzung Ihren Bedürfnissen entspricht.',
          infoQualityControlFeatureTooltipLearnMore: 'Weitere Informationen',
          translationQualityGood: 'Übersetzung sieht toll aus!',
          translationQualityAverage: 'Übersetzung kann einige Fehler haben, mit Vorsicht verwenden!',
          translationQualityBad: 'Übersetzung ist wahrscheinlich problematisch, Revision wird empfohlen.',
          translationQualityGoodModal: 'Gut',
          translationQualityAverageModal: 'Mit Vorsicht anwenden',
          translationQualityBadModal: 'Wahrscheinlich problematisch',
          translationQualityGoodModalDescription: 'Sie können sich auf diese Übersetzung verlassen.',
          translationQualityAverageModalDescription: 'Es kann einige Fehler in der Übersetzung geben. Wir empfehlen die interne Verwendung. Für den externen Gebrauch empfehlen wir Ihnen, dies mit einem professionellen Übersetzer zu überprüfen.\'',
          translationQualityBadModalDescription: 'Diese Übersetzung kann einige größere Fehler aufweisen. Wir empfehlen Ihnen, dies mit einem professionellen Übersetzer zu korrigieren.',
          websiteTranslator: 'Website',
          websiteTranslatorTippy: 'Website-Übersetzung',
          urlInputPlaceHolder: 'Fügen Sie hier Ihre URL ein...',
          websiteTranslateAction: 'Übersetzen',
          removeAllPreviousWebsiteTranslateRequests: 'Alles löschen',
          previousWebsiteTranslateRequestTitle: 'Verlauf anfordern:',
          wtLoadingCaption: 'Website wird übersetzt...',
          ocrCheckboxLabel: 'Ich lade Bilder und gescannte Dokumente hoch',
          ocrHelpText: 'Wann diese aktiviert werden soll',
          ocrCheckboxTippy: 'OCR (Optische Zeichenerkennung) erkennt Text in Bildern und gescannten Dokumenten. Versuchen Sie diese Option, wenn Sie eine leere Übersetzung erhalten haben oder der Textinhalt Ihrer PDF-Datei nicht auswählbar ist.',

          websitePage: 'Seite Website-Übersetzung',
          lastWTRequestURL: 'Letzte Website-Übersetzungsanfrage:',
          openWebsiteBtnLabel: 'Öffnen',
          invalidURL: 'Ungültiges URL-Format',
          ClearURLLabelandTippy: 'URL löschen',
          inactivityLogoutTitle: 'Sie wurden abgemeldet',
          inactivityLogoutMessage: 'Sie wurden aufgrund von Inaktivität abgemeldet',
          inactivityLogoutButtonLabel: 'Melden Sie sich erneut an',
          logoutScreenMessage: 'Vielen Dank für Ihren Besuch!',
          logoutScreenLogoutButtonLabel: 'Melden Sie sich erneut an',
          langSelect_NoResultsMessage: 'Keine Ergebnisse gefunden',
          reachedQuotaLimitErrorPartOne: 'Sie können nicht übersetzen, da Ihre Organisation ihre Übersetzungsgrenzen erreicht hat.',
          reachedQuotaLimitErrorPartTwo: 'Wenden Sie sich an die Person, die das Portal in Ihrer Organisation verwaltet, um dies zu erweitern.',
          orgExpiredErrorPartOne: 'Sie können nicht übersetzen, da Ihr Unternehmen sein Ablaufdatum erreicht hat.',
          orgExpiredErrorPartTwo: 'Wenden Sie sich an die Person, die das Portal in Ihrer Organisation verwaltet, um diese Grenzen zu erweitern.',
          downloadSourceFileSubheader: 'Quelle',
          downloadTranslatedFileSubheader: "Ergebnis",
          menuLabel: 'Menü',
          // statistics data view
          dataView: 'Daten',
          graphView: 'Diagramm',
          mainTitleStatisticsDataView: 'Organisationsstatistiken zum ausgewählten Zeitraum',
          genericColumnTitleStatisticsDataView: 'Generisch',
          textTranslationTitleStatisticsDataView: 'Textübersetzung',
          documentTranslationTitleStatisticsDataView: 'Dokumentübersetzung',
          wordsTranslatedField: 'Übersetzte Wörter',
          charsTranslatedField: 'Übersetzte Zeichen',
          ratioTextDocumentField: 'Verhältnis Textübersetzung/Dokumentübersetzung',
          mostUsedLangsField: 'Am häufigsten verwendete Sprachpaare',
          totalTextRequestsField: 'Summe der Anfragen',
          totalDocsTranslatedField: 'Summe der übersetzten Dokumente',
          userColumn: 'Benutzer',
          numberOfWordsColumn: 'Anzahl der Wörter',
          currentQuota: "Aktueller Quotenstatus",
          sortTableCancel: "Klicken, um das Sortieren abzubrechen",
          sortTableAscending: "Klicken, um aufsteigend zu sortieren",
          sortTableDescending: "Klicken, um absteigend zu sortieren",

          af: 'Afrikaner',
          am: 'Amharisch',
          ar: 'Arabisch',
          'oc-ES': 'Aranesisch',
          auto: 'Sprache erkennen',
          eu: 'Baskisch',
          bg: 'Bulgarisch',
          bn: 'Bengali',
          bs: 'Bosnisch',
          ca: 'Katalanisch',
          co: 'Korsisch',
          cs: 'Tschechisch',
          cy: 'Walisisch',
          da: 'Dänisch',
          dv: 'Divehi',
          ee: 'Ewe',
          gl: 'Galizisch',
          de: 'Deutsch',
          el: 'Griechisch',
          en: 'Englisch',
          'en-CA': 'Englisch (Kanada)',
          'en-US': 'Englisch (Vereinigte Staaten)',
          'en-GB': 'Englisch (Vereinigtes Königreich)',
          es: 'Spanisch',
          'es-LA': 'Spanisch (Lateinamerika)',
          'es-MX': 'Spanisch (Mexiko)',
          'es-XM': 'Spanisch (international)',
          'es-US': 'Spanisch (Vereinigte Staaten)',
          et: 'Estnisch',
          fa: 'Persisch',
          'fa-AF': 'Dari-Persisch',
          fi: 'Finnisch',
          fo: 'Färöer',
          tl: 'Filipino',
          fj: 'Fidschianisch',
          fr: 'Französisch',
          'fr-BE': 'Französisch (Belgien)',
          'fr-CA': 'Französisch (Kanada)',
          'fr-FR': 'Französisch (Frankreich)',
          he: 'Hebräisch',
          Hans: 'Chinesisch vereinfacht (Transliteration)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Kroatisch',
          ht: 'Haitianisches Kreolisch',
          hu: 'Ungarisch',
          hy: 'Armenisch',
          id: 'Indonesisch',
          is: 'Isländisch',
          it: 'Italienisch',
          iu: 'Inuktitut',
          'iu-Latn': 'Inuktitut (Lateinisch)',
          ja: 'Japanisch',
          Jpan: 'Japanisch (Transliteration)',
          km: 'Khmer',
          ko: 'Koreanisch',
          ka: 'Georgisch',
          ks: 'Kashmiri',
          'lb-LU': 'Luxemburgisch',
          lo: 'Lao',
          lt: 'Litauisch',
          lv: 'Lettisch',
          mg: 'Madagassisch',
          mk: 'Mazedonisch',
          mn: 'Mongolisch',
          ms: 'Malaiisch',
          mt: 'Maltesisch',
          my: 'Burmesisch',
          multi: 'Mehrsprachig',
          mww: 'Hmong Daw',
          ne: 'Nepali',
          no: 'Norwegisch',
          nl: 'Niederländisch',
          'nl-BE': 'Niederländisch (Belgien)',
          ns: 'Sepedi',
          oc: 'Okzitanisch',
          otq: 'Querétaro Otomi',
          pl: 'Polnisch',
          pt: 'Portugiesisch',
          'pt-PT': 'Portugiesisch (Portugal)',
          'pt-BR': 'Portugiesisch (Brasilien)',
          ro: 'Rumänisch',
          rw: 'Kinyarwanda',
          ru: 'Russisch',
          sk: 'Slowakisch',
          sl: 'Slowenisch',
          si: 'Sinhala',
          sm: 'Samoanisch',
          sr: 'Serbisch',
          'sr-RS-CYRL': 'Serbisch (Kyrillisch)',
          'sr-RS-LATN': 'Serbisch (Lateinisch)',
          sv: 'Schwedisch',
          sw: 'Kiswahili',
          ta: 'Tamilisch',
          th: 'Thailändisch',
          ti: 'Tigrinisch',
          to: 'Tongaisch',
          tr: 'Türkisch',
          tn: "Setswana",
          ty: 'Tahitianisch',
          tw: 'Twi',
          uk: 'Ukrainisch',
          ur: 'Urdu',
          vi: 'Vietnamesisch',
          yua: 'Yukatekisch Maya',
          yue: 'Kantonesisch',
          sq: 'Albanisch',
          az: 'Aserbaidschanisch',
          be: 'Weißrussisch',
          eo: 'Esperanto',
          gd: 'Gälisch (Schottland)',
          ga: 'Gälisch (Irland)',
          kn: 'Kannada',
          gu: 'Gujarati',
          ha: 'Hausa',
          la: 'Lateinisch',
          mr: 'Marathi',
          tg: 'Tadschikisch',
          te: 'Telugu',
          uz: 'Usbekisch',
          xh: 'Xhosa',
          yi: 'Jiddisch',
          zu: 'Zulu',
          cb: 'Cebuano',
          fy: 'Friesisch',
          hw: 'Hawaiianisch',
          ig: 'Igbo',
          jv: 'Javanisch',
          kk: 'Kasachisch',
          kmr: 'Kurdisch (Nordisch)',
          ku: 'Kurdisch',
          ky: 'Kirgisisch',
          ml: 'Malayalam',
          mi: 'Maori',
          ny: 'Nyanja',
          or: 'Odia',
          ps: 'Paschto',
          pa: 'Punjabi',
          rn: 'Rundi',
          st: 'Sesotho',
          sn: 'Shona',
          sd: 'Sindhi',
          so: 'Somali',
          su: 'Sundanesisch',
          tt: 'Tatarisch',
          tk: 'Turkmenisch',
          ug: 'Uigurisch',
          ts:'Tsonga',
          yo: 'Yoruba',
          'zh-CN': 'Chinesisch (vereinfacht)',
          'zh-TW': 'Chinesisch (traditionell)',
          'zh-HK': 'Chinesisch (Hongkong)',
        }
      },
      'zh-CN': {
        translations: {
          textOk: '确定',
          FAQ: '帮助',
          textTitle: '文本翻译页面',
          documentTitle: '文档翻译页面',
          loginTitle: '登录',
          statisticTitle: '统计页面',
          textAreaAnnouncementBox1: '原文文本区，可在该文本区输入要翻译的文本',

          textAreaAnnouncementBox2: '译文文本区，翻译完成后译文将显示在该文本区。',

          AnnounceModalCloseButton: '关闭',
          AnnounceDialogOpened: '对话框已打开',
          AnnounceDialogClosed: '对话框已关闭',
          AnnounceTextisCleared: '文本已清除',
          AnnounceCopyTransactionRecordText: '复制交易记录',
          AnnounceCopiedToClipboard: '复制到剪贴板',
          AnnounceCopyTranslatedText: '复制译文文本',
          ClearTextLabelandTippy: '清除文本',
          AnnounceTranslatedText: '译文文本',
          headerH1: '机器翻译服务',
          headerLogout: '退出',
          headerAccountSettings: '帐户设置',
          headerVersion: '版本',
          dropdownWebsiteLanguage: '设置网站语言',
          dropdownUserSetting: '用户设置',
          dropdownsourceLang: '源语言',
          dropdowntargetLang: '目标语言',
          operations: '内容来源',
          allOperations: '全部',
          textTranslateOperations: '文本翻译',
          documentTranslateOperations: '文档翻译',
          speechDocumentOperations: '语音转文字',
          transliteration: "音译",
          websiteTranslateOperations: "网站翻译",
          speechTranscription: "语音转录",
          words: '字',
          characters: '字符',
          scale: '等级',
          total: '总计',
          linearScale: '线性',
          sqrtScale: '平方根',
          dropdownSelected: '已选择',
          sidebarTippyText: '文本翻译器',
          sidebarText: '文本',
          sidebarDocument: '文档',
          sidebarTippyDocument: '文档翻译器',
          loginh1: '需登录',
          loginButton: '登录',
          textH2NavigateLanguageTranslationDropDown: '选择源语言和目标语言',

          textTippySwap: '互换语言',
          textTippySwapBlocked: '无法互换“检测语言” ',
          textTippySwapBlockedMulti: '无法交换“多语言”',
          textH2NavigateTextTranslationArea: '文本翻译区',
          textSourceTextareaPlaceholder: '在此键入或粘贴文本······',
          textSourceTextareaButton: '选择文件',
          textTranslateButton: '翻译',
          textCopyTippy: '复制文本',
          documentH1: '文档翻译',
          documentDroph2: '拖放文件或',
          documentDrophSelect: '在您的计算机上选择它们',
          documentDrophMaxSize: '最大文件大小为 <strong>50MB</strong>，ZIP 文件最大为 <strong>512MB</strong>',
          dragAndDropTooltip: '拖放',
          documentDropFileMessage: '支持的文件格式：',
          documentH2: '翻译文档',
          documentSubHeaderDate: '日期',
          documentSubHeaderSubName: '名称',
          documentSubHeaderSource: '语言对',
          documentSubHeaderTarget: '目标语言',
          documentSubHeaderFiles: '下载',
          documentSubHeaderPages: '删除',
          submissionNameHeader: "项目名称",
          submissionNameMaxLength: '提交名称最多可达 180 个字符',
          fileNameHeader: "文件名称",
          categoryHeader: "类别",
          categoryPlaceholder: "无类别",
          submissionNamePlaceholder: "在此处输入...",
          fileStatusSuccessful: "已翻译",
          downloadFailedError: "由于翻译失败，无法下载文件。",
          downloadingStatisticsMessage: "正在创建您的报告，这可能需要几分钟...",
          downloadingStatisticsError: "我们在创建您的报告时发现错误，请重试或联系支持人员。",
          documentSubHeaderActions: "操作",
          documentSubHeaderWCount: "字数：",
          documentSubHeaderCCount: "字符数：",
          documentSubHeaderStatus: '状态',
          documentSubRowStatusDeleting: '正在删除······',
          documentSubRowChildStatusTranslating: '正在翻译',
          documentSubRowChildStatusTranslated: '翻译成功',
          documentSubRowChildStatusTranslatedSuccess: "已翻译",
          documentSubRowChildStatusFailed: "失败",
          documentSubRowChildStatusUploading: '上传',
          documentSubRowChildStatusError: '翻译失败',
          documentSubRowChildDeleteTippy: '删除文件',
          documentFileIconTippy: '文件',
          documentSubRowChildDownloadTippy: '下载翻译文件',
          documentSubRowChildDownloadSourceTippy: '下载源文件',
          documentSubRowChildDownloadHeader: '下载',
          documentSubRowChildDownloadLoadingTippy: "正在加载下载",
          documentSubRowChildCopyToClipboardTippy: "复制到剪贴板",
          documentSubRowChildCopiedToClipboardTippy: "已复制到剪贴板",
          documentSubRowChildFailedTranslationHelpTippy: "翻译失败帮助",
          documentSubRowChildFailedTranslationHelpCloseTippy: "关闭翻译失败帮助",
          documentSubRowChildDownloadSourceDropdown: "以源文件格式下载",
          documentSubRowChildFailedTranslationHelpCloseTipp:"关闭失败的翻译帮助",
          documentSubRowChildDownloadMoreOptions: '更多下载选项',
          documentSubRowChildExpand: '展开',
          documentSubRowChildCollapse: '折叠',
          documentSubRowZipping: '拉链',
          documentSubRowZipAndDownload: '压缩并下载',
          documentSubRowZipHelp: '邮编帮助',
          documentSubRowDownloadZip: '下载',
          documentSubRowDownloadZipError: '压缩时出错',
          documentSubRowChildHumanTranslationRequestedTippy: "已请求人工翻译",
          documentSubRowChildHumanTranslationFailedTipp:"人类翻译请求失败",
          documentSubRowChildHumanTranslationFailedTippy: "请求人工翻译失败",
          documentSubRowChildRequestHumanTranslationTippy: "请求人工翻译",
          documentSubRowChildCloseRequestHumanTranslationTippy: "关闭人工翻译菜单",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "正在加载请求",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "在新选项卡中打开 TransPort",
          documentSubRowChildHumanTranslationRequestInProcess: "正在处理人工翻译请求。",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "结束 GL Portal 操作 ",
          documentSubRowChildHumanTranslationRequest: "请求人工翻译",
          documentSubRowChildHumanTranslationRequested: "已请求人工翻译",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "查看 GL Portal 中的状态 ",
          documentSubRowChildHumanTranslationRequestGoToTransport: "前往 GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " （额外费用）",
          documentSubRowChildEditTranslatedFile: '编辑已翻译文件',
          documentSubRowChildMoreActionsTooltip: '更多行动',
          helpSentencePartOne:'如需帮助，请单击 ',
          helpSentencePartTwo:'此处',
          helpSentencePartTwoHover: '这将向支持部门打开新电子邮件',
          helpSentencePartThree:' 联系支持。请在电子邮件正文中描述您的问题。',
          documentSubRowChildDownloadPdfDropdown: '下载为 pdf',
          documentSubRowChildLanguageTo: '到',
          documentTippyAdd: '选择文件',
          deleteModalTitle: '删除文件',
          deleteModalCheck: '确定要删除此文件吗？',
          deleteModalCancel: '取消',
          deleteModalDelete: '删除',
          modalH1: '上传文件',
          modalH1AfterUpload: '文件已上传',
          modalPTop: '已选择以下文件进行翻译：',
          modalTippyAdd: '添加更多文件',
          modalConfirm: '选择源语言和目标语言',
          modalTippySwap: '互换语言',
          modalTranslate: '翻译',
          modalLoading: '正在加载······',
          modalUploadSuccessful: '翻译中',
          processingRequestMsg: '处理请求中······',

          modalUploadErrorMessagePartOne:'我们发现上传您的文件时出错。',
          modalUploadErrorMessagePartTwo:'请重试。',
          modalUploadErrorMessagePartThree:'如果问题仍然存在 ',
          modalUploadErrorMessagePartFour: '联系支持人员。',
          modalMsgEmlAutoDetectError: '请选择您电子邮件的“来源语言”的原始语言，以成功翻译。\n',
          modalPdfAutodetectOcrError: '请在“源语言”上选择图像或 PDF 的原始语言，以成功翻译。\n',
          mandatoryCategoryAndSubmissionNameError: '请选择类别和提交名称以继续翻译。',
          mandatorySubmissionNameError: '请选择提交名称以继续翻译。',
          mandatoryCategoryError: '请选择一个类别以继续翻译。',
          deleteModalProblem: '删除您的文件时出现问题。 请关闭此模式并重试。\n',
          unsupportedFileMessage: '我们无法翻译一个或多个文件。 请将其转换并重试。',
          statisticErrorTime: '开始日期似乎晚于结束日期。 请在结束日期之前选择一个开始日期。',
          statisticErrorFutureDate: '开始日期似乎是未来。 请选择今天之前的开始日期。',
          unsupportedSourceLang: '检测到的语言在您的组织中不可用。 如需更多语言，请联系您组织中管理门户的人员。',
          reachedQuotaLimitError: '您的组织已达到翻译限制。 联系您组织中管理门户的人员以扩展这些限制。',
          orgExpiredError: '您无法翻译，因为您的组织已达到其到期日期。 联系您组织中管理门户的人员以扩展这些限制。',
          textTranslationError: '翻译失败。 支持可为您提供帮助。',
          statisticRequestError:'我们发现创建您的报告时出错，请重试。',
          downloadPdfError: '我们无法将上传的文件格式转换为 pdf。',
          noDataError: '我们无法在这些日期之间找到任何翻译。 如果你认为这是一个错误，可以提供支持。',
          'AP-12-001': '文件似乎为空，请检查并重试。',
          'AP-12-001-desc': '文件似乎为空，请检查并重试。',
          'AP-12-001-desc-pdf':'未检测到文本。 请重试，确保 OCR 已启用。',
          'AP-01-003-desc': '文件超出您组织的字数限制。 请将其分成较小的部分，然后重试。',
          'WFM-04-SRCTRGSAME': '源语言和目标语言不能相同。 请修改其中一项',
          'WFM-04-SRCTRGSAME-desc':'源语言和目标语言不能相同。 请修改其中一项',
          'WFM-04-SRCTRGSAME-desc-pdf' : '源语言和目标语言不能相同。 请修改其中一项',
          'USRMNG-DB-1062': '此用户已存在。 请搜索并进行相应更改。',
          'STT-DOC-04-SIZE': '最大文件大小为 200MB。 请分割您的文件，然后重试。\n',
          'STT-fileSIZE': '您可以翻译高达 50MB 的文件。 请删除较大的文件，将其分成较小的文件，然后重试。',
          'STT-fileSIZE-zip': '您可以翻译高达 512MB 的 zip 文件。 请从您的 ZIP 中删除文件，然后重试',
          TT_WAV_ERROR_MULTIPLE_FILES: '请一次上传一个 wav 文件，或尝试翻译文档以翻译',
          TT_WAV_ERROR_AUTO_DETECT:'请以“源语言”选择您的 wav 文件的原始语言，以成功翻译这些文件。',
          TT_WAV_ERROR_FILE_TYPE:'使用文档翻译来翻译此类文件',
          TT_WAV_ERROR_FILE_SIZE: '最大文件大小为 200MB。 请分割您的文件并重试',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'请分别翻译 PDF 和 wav 文件',
          'WFM-error': '我们发现处理您的文件时出错。 请再试一次',
          'WFM-error-desc': '我们发现处理您的文件时出错。 请再试一次',
          'WFM-04-INVLANG': '检测到的语言在您的组织中不可用。 如需更多语言，请联系您组织中管理门户的人员。',
          'WFM-04-INVLANG-desc': '检测到的语言在您的组织中不可用。 如需更多语言，请联系您组织中管理门户的人员。',
          'OCR-03-A2A-FAIL': '我们发现翻译您的文件时出错。 请重试',
          'OCR-03-A2A-TIMEOUT': '我们发现翻译您的文件时出错。 请重试',


          copyright: ' Translations.com 版权所有',
          statistics: '统计数据',
          statisticFrom: '从：',
          statisticTo: '到：',
          statisticDownload: '下载报告',
          textTranslationReachedLimit: '每个请求最多可翻译 10000 个字符',
          users: '用户',
          allUsers: '全部',
          allLanguages: '全部',
          loadTimeFrame: '加载时间范围',
          'OCR-03-A2A-PASSWORD': '错误，请删除 PDF 密码',
          'OCR-03-A2A-PASSWORD-desc-pdf': 'PDF 有密码保护，请删除 PDF 密码',
          'AP-08-001': '错误，请删除 PDF 密码',
          'AP-08-001-desc-pdf': 'PDF 有密码保护，请删除 PDF 密码',
          'OCR-03-A2A-SIZE-EX': '转换 PDF 的最大页面尺寸为 A4。',
          quickLinksLabel: '快捷链接：',
          'OCR-03-A2A-HEALTH': 'OCR 暂时不可用，请重试',
          'MT-error': '翻译引擎出错',
          'MT-error-desc': '翻译引擎出错',
          'USRMNG-DB-1452': '无效值',
          TT_WAV_ERROR_NO_FILES_DETECTED: "无法检测到任何 WAV 文件",
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: '转录仍在处理中。如果现在退出此页面，转录将会丢失。',
          qualityTranslationEngineInfo: "TransPerfect 主引擎允许您根据自己的要求了解我们的翻译的保真度。 测试它！",
          infoQualityControlFeature: "质量控制是让您能够根据您的需求了解我们的翻译的保真度。 保真度水平是指我们按照您的要求向您提供的翻译的准确性。",
          transliterationTippy: '音译文本',
          infoQualityControlFeatureTooltip: '为了确保我们产品的质量和透明度，TransPerfect 主引擎允许您根据自己的需求了解我们的翻译的保真度。',
          infoQualityControlFeatureTooltipLearnMore: '了解更多',
          translationQualityGood: '翻译看起来很棒！',
          translationQualityAverage: '翻译可能会有一些错误，请谨慎使用！',
          translationQualityBad: '翻译可能有问题，建议进行修订。',
          translationQualityGoodModal: '不错',
          translationQualityAverageModal: '谨慎使用',
          translationQualityBadModal: '可能有问题',
          translationQualityGoodModalDescription: '你可以依赖这个翻译。',
          translationQualityAverageModalDescription: '翻译中可能存在一些缺陷。 我们建议在内部使用。 对于外部使用，我们建议您咨询专业翻译。',
          translationQualityBadModalDescription: '这个翻译可能会有一些重大错误。 我们建议您通过专业翻译进行更正。',
          websiteTranslator: '网站',
          websiteTranslatorTippy: '网站翻译器',
          urlInputPlaceHolder: '在此处粘贴您的网址······',
          websiteTranslateAction: '翻译',
          removeAllPreviousWebsiteTranslateRequests: '全部清除',
          previousWebsiteTranslateRequestTitle: '请求历史：',
          wtLoadingCaption: '正在翻译网站······',
          ocrCheckboxLabel: '我正在上传图像和扫描文件',
          ocrHelpText: '何时激活',
          ocrCheckboxTippy: 'OCR（光学字符识别）用于检测图像和扫描文档中的文本。如果得到空白译文或无法选择 PDF 文本内容，请尝试此选项。',

          websitePage: '网站翻译页面',
          lastWTRequestURL: '最后一次网站翻译请求：',
          openWebsiteBtnLabel: '打开',
          invalidURL: '网址格式无效',
          ClearURLLabelandTippy: '清除网址',
          inactivityLogoutTitle: '已被退出',
          inactivityLogoutMessage: '无操作超时，已被退出',
          inactivityLogoutButtonLabel: '重新登录',
          logoutScreenMessage: '感谢来访！',
          logoutScreenLogoutButtonLabel: '重新登录',
          langSelect_NoResultsMessage: '未找到结果',
          downloadSourceFileSubheader: '源文件',
          reachedQuotaLimitErrorPartOne: '您无法翻译，因为您的组织已达到其翻译限制。',
          reachedQuotaLimitErrorPartTwo: '联系您组织中管理门户的人员以延长此期限。',
          orgExpiredErrorPartOne: '您无法翻译，因为您的组织已达到其到期日期。',
          orgExpiredErrorPartTwo: '联系您组织中管理门户的人员以延长这些限制。',
          downloadTranslatedFileSubheader: "结果",
          menuLabel: '菜单',
          // statistics data view
          dataView: '数据',
          graphView: '图表',
          mainTitleStatisticsDataView:'选定时间段内的企业统计数据',
          genericColumnTitleStatisticsDataView: '总数据',
          textTranslationTitleStatisticsDataView: '文本翻译',
          documentTranslationTitleStatisticsDataView: '文档翻译',
          wordsTranslatedField: '已翻译字数',
          charsTranslatedField: '已翻译字符数',
          ratioTextDocumentField:'文本与文档翻译比例',
          mostUsedLangsField: '最常用语言对',
          totalTextRequestsField: '请求总数',
          totalDocsTranslatedField: '已翻译文档总数',
          userColumn: '用户',
          numberOfWordsColumn:'字数',
          currentQuota:'占合同总字数比例',
          sortTableCancel: "单击以取消排序",
          sortTableAscending: "单击以按升序排序",
          sortTableDescending: "单击以按降序排序",

          af: '南非荷兰语',
          am: '阿姆哈拉语',
          ar: '阿拉伯语',
          'oc-ES': '阿兰尼斯语',
          auto: '检测语言',
          eu: '巴斯克语',
          bg: '保加利亚语',
          bn: '孟加拉语',
          bs: '波斯尼亚语',
          ca: '加泰罗尼亚语',
          co: '科西嘉语',
          cs: '捷克语',
          cy: '威尔士语',
          da: '丹麦语',
          dv: '迪维希',
          ee: '我们',
          gl: '加利西亚语',
          de: '德语',
          el: '希腊语',
          en: '英语',
          'en-CA': '英语（加拿大）',
          'en-US': '英语（美国）',
          'en-GB': '英语（英国）',
          es: '西班牙语',
          'es-LA': '西班牙语（拉丁美洲）',
          'es-MX': '西班牙语（墨西哥）',
          'es-XM': '西班牙语（国际）',
          'es-US': '西班牙语（美国）',
          et: '爱沙尼亚语',
          fa: '波斯语',
          'fa-AF': '达利语',
          fi: '芬兰语',
          fo: '法罗语',
          tl: '菲律宾语',
          fj: '斐济语',
          fr: '法语',
          'fr-BE': '法语（比利时）',
          'fr-CA': '法语（加拿大）',
          'fr-FR': '法语（法国）',
          he: '希伯来语',
          Hans: '简体中文（译文）',
          hi: '印地语',
          hm: '赫蒙语',
          hr: '克罗地亚语',
          ht: '海地克里奥尔语',
          hu: '匈牙利语',
          hy: '亚美尼亚语',
          id: '印度尼西亚语',
          is: '冰岛语',
          it: '意大利语',
          iu: '信息',
          'iu-Latn': '信息 (拉丁语)',
          ja: '日语',
          Jpan: '日语（翻译）',
          km: '高棉语',
          ko: '韩语',
          ka: '格鲁吉亚语',
          ks: '克什米尔',
          'lb-LU': '卢森堡语',
          lo: '老挝语',
          lt: '立陶宛语',
          lv: '拉脱维亚语',
          mg: '马尔加什语',
          mk: '马其顿语',
          mn: '蒙古语',
          ms: '马来语',
          mt: '马耳他语',
          my: '缅甸语',
          multi: '多种语言',
          mww: '白苗语',
          ne: '尼泊尔语',
          no: '挪威语',
          nl: '荷兰语',
          'nl-BE': '荷兰语（比利时）',
          ns: '塞佩迪',
          oc: '八色',
          otq: '克雷塔罗奥托米语',
          pl: '波兰语',
          pt: '葡萄牙语',
          'pt-PT': '葡萄牙语（葡萄牙）',
          'pt-BR': '葡萄牙语（巴西）',
          ro: '罗马尼亚语',
          rw: '卢旺达语',
          ru: '俄语',
          sk: '斯洛伐克语',
          sl: '斯洛文尼亚语',
          si: '僧伽罗语',
          sm: '萨摩亚语',
          sr: '塞尔维亚语',
          'sr-RS-CYRL': '塞尔维亚语（西里尔语）',
          'sr-RS-LATN': '塞尔维亚语（拉丁语）',
          sv: '瑞典语',
          sw: '斯瓦希里语',
          ta: '泰米尔语',
          th: '泰语',
          ti: '提格里尼亚语',
          to: '汤加语',
          tr: '土耳其语',
          tn: "塞茨瓦纳",
          ty: '塔希提语',
          tw: '暮',
          uk: '乌克兰语',
          ur: '乌尔都语',
          vi: '越南语',
          yua: '尤卡特克玛雅语',
          yue: '粤语',
          sq: '阿尔巴尼亚语',
          az: '阿塞拜疆语',
          be: '白俄罗斯语',
          eo: '世界语',
          gd: '盖尔语（苏格兰）',
          ga: '盖尔语（爱尔兰）',
          kn: '坎纳达语',
          gu: '古吉拉特语',
          ha: '豪萨语',
          la: '拉丁语',
          mr: '马拉地语',
          tg: '塔吉克语',
          te: '泰卢固语',
          uz: '乌兹别克语',
          xh: '科萨语',
          yi: '意第绪语',
          zu: '祖鲁语',
          cb: '宿务语',
          fy: '弗里斯兰语',
          hw: '夏威夷语',
          ig: '伊博语',
          jv: '爪哇语',
          kk: '哈萨克语',
          kmr: '库尔德语（北方）',
          ku: '库尔德语',
          ky: '吉尔吉斯语',
          ml: '马拉雅拉姆语',
          mi: '毛利语',
          ny: '尼昂加语',
          or: '奥利亚语',
          ps: '普什图语',
          pa: '旁遮普语',
          rn: '克什米尔',
          st: '塞索托语',
          sn: '修纳语',
          sd: '信德语',
          so: '索马里语',
          su: '巽他语',
          tt: '鞑靼语',
          tk: '土库曼语',
          ts:'特松加',
          ug: '维吾尔语',
          yo: '约鲁巴语',
          'zh-CN': '中文（简体）',
          'zh-TW': '中文（台湾）',
          'zh-HK': '中文（香港）',
        }
      },
      'ja': {
        translations: {
          textOk: 'OK',
          FAQ: 'ヘルプ',
          textTitle: 'テキスト翻訳ページ',
          documentTitle: 'ドキュメント翻訳ページ',
          loginTitle: 'サインイン',
          statisticTitle: '統計ページ',
          textAreaAnnouncementBox1: '翻訳対象のテキストを入力するテキストエリア',

          textAreaAnnouncementBox2: '翻訳が完了すると翻訳テキストが表示されるテキストエリア。',

          AnnounceModalCloseButton: '閉じる',
          AnnounceDialogOpened: 'ダイアログは開いています',
          AnnounceDialogClosed: 'ダイアログは閉じています',
          AnnounceTextisCleared: 'テキストをクリアしました',
          AnnounceCopyTransactionRecordText: 'トランザクションレコードをコピー',
          AnnounceCopiedToClipboard: 'クリップボードにコピー済み',
          AnnounceCopyTranslatedText: '翻訳テキストをコピー',
          ClearTextLabelandTippy: 'テキストをクリア',
          AnnounceTranslatedText: '翻訳テキスト',
          headerH1: '機械翻訳サービス',
          headerLogout: 'サインアウト',
          headerAccountSettings: 'アカウント設定',
          headerVersion: 'バージョン',
          dropdownWebsiteLanguage: 'ウェブサイト言語の設定',
          dropdownUserSetting: 'ユーザー設定',
          dropdownsourceLang: 'ソース言語',
          dropdowntargetLang: 'ターゲット言語',
          operations: 'ソースのコンテンツ',
          allOperations: '全て',
          textTranslateOperations: 'テキスト翻訳',
          documentTranslateOperations: 'ドキュメント翻訳',
          speechDocumentOperations: '音声テキスト変換',
          transliteration: "トランスリテレーション",
          websiteTranslateOperations: "ウェブサイト翻訳",
          speechTranscription: "音声の書き起こし",
          words: 'ワード',
          characters: '文字',
          scale: 'スケール',
          total: '合計',
          linearScale: '線形',
          sqrtScale: '平方根',
          dropdownSelected: 'が選択されています',
          sidebarTippyText: 'テキスト翻訳',
          sidebarText: 'テキスト',
          sidebarDocument: 'ドキュメント',
          sidebarTippyDocument: 'ドキュメント翻訳ツール',
          loginh1: 'サインインしてください',
          loginButton: 'サインイン',
          textH2NavigateLanguageTranslationDropDown: 'ソース言語とターゲット言語を選択',

          textTippySwap: '言語を入れ替える',
          textTippySwapBlocked: '「言語を検出」を入れ替えることはできません ',
          textTippySwapBlockedMulti: '多言語を入れ替えることはできません',
          textH2NavigateTextTranslationArea: 'テキスト翻訳エリア',
          textSourceTextareaPlaceholder: 'ここにテキストを入力または貼り付けます…',
          textSourceTextareaButton: 'ファイルを選択',
          textTranslateButton: '翻訳',
          textCopyTippy: 'テキストをコピー',
          documentH1: 'ドキュメント翻訳',
          documentDroph2: 'ファイルをドラッグ＆ドロップまたは',
          documentDrophSelect: 'コンピュータでそれらを選択する',
          documentDrophMaxSize: '最大ファイルサイズ<strong>50MB</strong>、ZIPファイル最大<strong>512MB</strong>',
          dragAndDropTooltip: 'ドラッグアンドドロップ',
          documentDropFileMessage: '対応ファイル形式：',
          documentH2: '翻訳',
          documentSubHeaderDate: '日付',
          documentSubHeaderSubName: 'ファイル名',
          documentSubHeaderSource: '言語',
          documentSubHeaderTarget: 'ターゲット言語',
          documentSubHeaderFiles: 'ダウンロード',
          documentSubHeaderPages: '削除',
          submissionNameHeader: "案件名",
          submissionNameMaxLength: '送信名は最大180文字です',
          fileNameHeader: "ファイル名",
          categoryHeader: "カテゴリ",
          categoryPlaceholder: "カテゴリーなし",
          submissionNamePlaceholder: "ここに入力…",
          fileStatusSuccessful: "翻訳済み",
          downloadFailedError: "翻訳に失敗したため、ファイルをダウンロードできません。",
          downloadingStatisticsMessage: "レポートの作成には数分かかる場合があります...",
          downloadingStatisticsError: "レポートの作成中にエラーが発生しました。もう一度試すか、サポートにお問い合わせください。",
          documentSubHeaderActions: "アクション",
          documentSubHeaderWCount: "ワード数：",
          documentSubHeaderCCount: "文字数：",
          documentSubHeaderStatus: 'ステータス',
          documentSubRowStatusDeleting: '削除中…',
          documentSubRowChildStatusTranslating: '翻訳中',
          documentSubRowChildStatusTranslated: '翻訳に成功しました',
          documentSubRowChildStatusTranslatedSuccess: "翻訳済み",
          documentSubRowChildStatusFailed: "失敗",
          documentSubRowChildStatusUploading: 'アップロード中',
          documentSubRowChildStatusError: '翻訳できませんでした',
          documentSubRowChildDeleteTippy: 'ファイルを削除',
          documentFileIconTippy: 'ファイル',
          documentSubRowChildDownloadTippy: '翻訳済みファイルのダウンロード',
          documentSubRowChildDownloadSourceTippy: 'ソース文書のダウンロード',
          documentSubRowChildDownloadHeader: 'ダウンロード',
          documentSubRowChildDownloadLoadingTippy: "ダウンロードを読み込み中",
          documentSubRowChildCopyToClipboardTippy: "クリップボードにコピー",
          documentSubRowChildCopiedToClipboardTippy: "クリップボードにコピーしました",
          documentSubRowChildFailedTranslationHelpTippy: "失敗した翻訳の詳細を見る",
          documentSubRowChildFailedTranslationHelpCloseTippy: "失敗した翻訳の詳細を閉じる",
          documentSubRowChildDownloadSourceDropdown: "ソース形式としてダウンロード",
          documentSubRowChildDownloadMoreOptions: 'その他のダウンロードオプション',
          documentSubRowChildExpand: '展開',
          documentSubRowChildCollapse: '折りたたむ',
          documentSubRowZipping: 'ジップ',
          documentSubRowZipAndDownload: 'とダウンロード',
          documentSubRowZipHelp: 'ヘルプ',
          documentSubRowDownloadZip: 'をダウンロード',
          documentSubRowDownloadZipError: '圧縮エラー',
          documentSubRowChildHumanTranslationRequestedTippy: "人手翻訳をリクエスト済み",
          documentSubRowChildHumanTranslationFailedTippy: "人手翻訳のリクエストに失敗しました",
          documentSubRowChildRequestHumanTranslationTippy: "人手翻訳をリクエストする",
          documentSubRowChildCloseRequestHumanTranslationTippy: "人手翻訳メニューを閉じる",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "リクエストを読み込み中",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "新しいタブでGL Portalを開く",
          documentSubRowChildHumanTranslationRequestInProcess: "人手翻訳のリクエストを処理中です。",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "GL Portalで完了する",
          documentSubRowChildHumanTranslationRequest: "人手翻訳をリクエストする",
          documentSubRowChildHumanTranslationRequested: "人手翻訳をリクエスト済み",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "GL Portalのステータスを確認",
          documentSubRowChildHumanTranslationRequestGoToTransport: "GL Portalに移動",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " （追加費用が発生します）",
          documentSubRowChildEditTranslatedFile: '翻訳されたファイルを編集',
          documentSubRowChildMoreActionsTooltip: 'その他のアクション',
          helpSentencePartOne: 'ヘルプを表示するには ',
          helpSentencePartTwo: 'ここ',
          helpSentencePartTwoHover: 'これにより、サポートへの新しい電子メールメッセージが開きます',
          helpSentencePartThree: ' サポートに連絡します。 電子メールの本文に問題について説明してください。',
          documentSubRowChildDownloadPdfDropdown: 'PDF形式でダウンロード',
          documentSubRowChildLanguageTo: 'から',
          documentTippyAdd: 'ファイルを選択',
          deleteModalTitle: 'ファイルを削除',
          deleteModalCheck: 'このファイルを削除してもよろしいですか？',
          deleteModalCancel: 'キャンセル',
          deleteModalDelete: '削除',
          modalH1: 'ファイルのアップロード',
          modalH1AfterUpload: 'ファイルがアップロードされました',
          modalPTop: '翻訳対象として選択したファイル：',
          modalTippyAdd: 'ファイルを追加',
          modalConfirm: 'ソース言語とターゲット言語を確認',
          modalTippySwap: '言語を入れ替える',
          modalTranslate: '翻訳',
          modalLoading: '読み込み中…',
          modalUploadSuccessful: '翻訳中',
          processingRequestMsg: 'リクエストを処理しています…',

          modalUploadErrorMessagePartOne:'ファイルのアップロード中にエラーが発生しました。',
          modalUploadErrorMessagePartTwo:'もう一度お試しください。',
          modalUploadErrorMessagePartThree:'問題が続く場合 ',
          modalUploadErrorMessagePartFour: 'サポートにお問い合わせください。',
          modalMsgEmlAutoDetectError: '翻訳するには、\\"ソース言語\\" でメールの元の言語を選択してください。',
          modalPdfAutodetectOcrError: 'ソース言語のオリジナル言語またはPDFを選択して、翻訳してください。',
          mandatoryCategoryAndSubmissionNameError: '翻訳を続けるには、カテゴリと案件名を選択してください。',
          mandatorySubmissionNameError: '翻訳を続行するには、案件名を選択してください。',
          mandatoryCategoryError: '翻訳を続行するには、カテゴリを選択してください。\n',
          deleteModalProblem: 'ファイルの削除中に問題が発生しました。 このモーダルを閉じて、再試行してください。\n',
          unsupportedFileMessage: '1つ以上のファイルを翻訳することはできません。 変換して、もう一度お試しください。\n',
          statisticErrorTime: '開始日が終了日より後になっているようです。 終了日より前の開始日を選択してください。\n',
          statisticErrorFutureDate: '開始日は未来のようです。 今日より前の開始日を選択してください。',
          unsupportedSourceLang: '検出された言語は、組織内では使用できません。 その他の言語については、組織のポータルを管理する担当者にお問い合わせください。',
          reachedQuotaLimitError: 'あなたの組織は翻訳の制限に達しました。 これらの制限を延長するには、組織のポータル管理者に連絡してください。',
          orgExpiredError: '組織が有効期限に達したため、翻訳できません。 これらの制限を延長するには、組織のポータル管理者に連絡してください。',
          textTranslationError: '翻訳に失敗しました。 サポートが利用できます。',
          statisticRequestError:'レポートの作成中にエラーが発生しました。もう一度お試しください。',
          downloadPdfError: 'アップロードされたファイル形式をPDFに変換できません。',
          noDataError: 'これらの日付の間に翻訳が見つかりませんでした。 もし、それが間違いだと思えば、サポートは受けられます。',
          'AP-12-001': 'ファイルが空です。確認して、もう一度お試しください。',
          'AP-12-001-desc': 'ファイルが空です。確認して、もう一度お試しください。',
          'AP-12-001-desc-pdf':'テキストは検出されませんでした。 OCR が有効になっていることを確認してください。',
          'AP-01-003-desc': 'ファイルが組織のワード制限を超えています。 もっと小さい部分に分けて、もう一度お試しください。',
          'WFM-04-SRCTRGSAME': 'ソース言語とターゲット言語を同じにすることはできません。 いずれかを修正してください\n',
          'WFM-04-SRCTRGSAME-desc':'ソース言語とターゲット言語を同じにすることはできません。 いずれかを修正してください\n',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'ソース言語とターゲット言語を同じにすることはできません。 いずれかを修正してください\n',
          'USRMNG-DB-1062': 'このユーザーは既に存在します。 検索して、対応する変更を行ってください。\n',
          'STT-DOC-04-SIZE': '最大ファイルサイズは200MBです。 ファイルを分割して、もう一度お試しください。',
          'STT-fileSIZE': '最大50MBのファイルを翻訳できます。 大きいファイルを削除し、小さいファイルに分割して再試行してください。',
          'STT-fileSIZE-zip': '最大512MBのzipファイルを翻訳できます。 ZIPからファイルを削除して、もう一度お試しください',
          TT_WAV_ERROR_MULTIPLE_FILES: '一度に 1 つの wav ファイルをアップロードするか、ドキュメントを翻訳して翻訳してください',
          TT_WAV_ERROR_AUTO_DETECT:'\\\\"Source language\\\\" で wav ファイルの元の言語を選択して、正常に翻訳してください。',
          TT_WAV_ERROR_FILE_TYPE:'ドキュメント翻訳を使用してこのタイプのファイルを翻訳する',
          TT_WAV_ERROR_FILE_SIZE: '最大ファイルサイズは200MBです。 ファイルを分割して、もう一度お試しください',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'PDFファイルとwavファイルを別々に翻訳してください',
          'WFM-error': 'ファイルの処理中にエラーが発生しました。 もう一度お試しください',
          'WFM-error-desc': 'ファイルの処理中にエラーが発生しました。 もう一度お試しください',
          'WFM-04-INVLANG': '検出された言語は、組織内では使用できません。 その他の言語については、組織のポータルを管理する担当者にお問い合わせください。\n',
          'WFM-04-INVLANG-desc': '検出された言語は、組織内では使用できません。 その他の言語については、組織のポータルを管理する担当者にお問い合わせください。',
          'OCR-03-A2A-FAIL': 'ファイルの翻訳中にエラーが発生しました。 もう一度お試しください',
          'OCR-03-A2A-TIMEOUT': 'ファイルの翻訳中にエラーが発生しました。 もう一度お試しください',



          copyright: ' Translations.com 無断複写・転載を禁じます',
          statistics: '利用履歴',
          statisticFrom: '開始日：',
          statisticTo: '終了日：',
          statisticDownload: 'ダウンロード',
          textTranslationReachedLimit: '  リクエストごとに最大10,000文字まで翻訳できます',
          users: 'ユーザー',
          allUsers: '全て',
          allLanguages: '全て',
          loadTimeFrame: '読み込み',
          'OCR-03-A2A-PASSWORD': 'エラー：PDFのパスワードを削除してください',
          'OCR-03-A2A-PASSWORD-desc-pdf': 'PDFはパスワードで保護されています。PDFのパスワードを削除してください。',

          'AP-08-001': 'エラー：PDFのパスワードを削除してください',
          'AP-08-001-desc-pdf': 'PDFはパスワードで保護されています。PDFのパスワードを削除してください。',

          'OCR-03-A2A-SIZE-EX': 'PDFに変換できる最大ページサイズはA4です。',

          quickLinksLabel: 'クイックリンク：',
          'OCR-03-A2A-HEALTH': 'OCRは一時的に利用できません。しばらくしてから、もう一度お試しください。',
          'MT-error': '翻訳エンジンにエラーが発生しました',
          'USRMNG-DB-1452': '無効な値',
          TT_WAV_ERROR_NO_FILES_DETECTED: "WAVファイルを検出できませんでした",
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: 'まだ書き起こしの処理中です。今このページを終了すると、書き起こした内容が失われます。',
          qualityTranslationEngineInfo: "TransPerfect Main Engineでは、お客様のご要望に応じて、翻訳の忠実度をご確認いただけます。 試してみよう！",
          infoQualityControlFeature: "品質管理は、お客様のニーズに応じて翻訳の忠実度レベルをお知らせする機能です。 忠実度のレベルは、お客様の要件で規定されている翻訳の正確性として定義されます。",
          transliterationTippy: 'テキストのトランスリテレーション',
          infoQualityControlFeatureTooltip: '弊社の製品の品質と透明性を確保するため、TransPerfect Main Engineでは、お客様のニーズに応じて弊社の翻訳が持つ忠実度レベルをお客様にお知らせすることができます。',
          infoQualityControlFeatureTooltipLearnMore: '詳細',
          translationQualityGood: '翻訳が素晴らしい！',
          translationQualityAverage: '翻訳にエラーがある可能性があります。慎重に使用してください。',
          translationQualityBad: '翻訳が問題になる可能性が高いので、修正を推奨します。',
          translationQualityGoodModal: '良い',
          translationQualityAverageModal: '注意して使用',
          translationQualityBadModal: '問題がある可能性が高い',
          translationQualityGoodModalDescription: 'この翻訳に頼ることができます',
          translationQualityAverageModalDescription: '翻訳に何らかの欠陥がある可能性があります。 内部での使用をお勧めします。 外部での使用については、専門の翻訳者に確認することをお勧めします。',
          translationQualityBadModalDescription: 'この翻訳には、いくつかの大きなエラーがある可能性があります。 プロの翻訳者で修正することをお勧めします。',
          websiteTranslator: 'ウェブサイト',
          websiteTranslatorTippy: 'ウェブサイト翻訳ツール',
          urlInputPlaceHolder: 'ここにURLを貼り付けます…',
          websiteTranslateAction: '翻訳',
          removeAllPreviousWebsiteTranslateRequests: 'すべてクリア',
          previousWebsiteTranslateRequestTitle: 'リクエスト履歴：',
          wtLoadingCaption: 'ウェブサイトを翻訳中…',
          ocrCheckboxLabel: '画像とスキャンした文書をアップロードしている',
          ocrHelpText: 'これをアクティブ化するタイミング',
          ocrCheckboxTippy: 'OCR（光学式文字認識）は、画像やスキャンしたドキュメント内のテキストを検出します。空の翻訳を受信した場合、またはPDFのテキストコンテンツが選択できない場合は、このオプションをお試しください。',

          websitePage: 'ウェブサイト翻訳ページ',
          lastWTRequestURL: '前回のウェブサイト翻訳リクエスト：',
          openWebsiteBtnLabel: '開く',
          invalidURL: '無効なURL形式',
          ClearURLLabelandTippy: 'URLをクリア',
          inactivityLogoutTitle: 'サインアウトしました',
          inactivityLogoutMessage: '一定時間操作されていないため、サインアウトしました',
          inactivityLogoutButtonLabel: 'もう一度サインインする',
          logoutScreenMessage: 'ご利用ありがとうございます。',
          logoutScreenLogoutButtonLabel: 'もう一度サインインする',
          langSelect_NoResultsMessage: '該当するものが見つかりません',
          reachedQuotaLimitErrorPartOne: '組織が翻訳制限に達したため、翻訳できません。',
          reachedQuotaLimitErrorPartTwo: 'これを拡張するには、組織内のポータル管理者に連絡してください。',
          orgExpiredErrorPartOne: '組織が有効期限に達したため、翻訳できません。',
          orgExpiredErrorPartTwo: '組織のポータル管理担当者に連絡して、これらの制限を延長してください。\n',
          downloadSourceFileSubheader: 'ソース',
          downloadTranslatedFileSubheader: "翻訳結果",
          menuLabel: 'メニュー',
          // statistics data view
          dataView: 'データ',
          graphView: 'グラフ',
          mainTitleStatisticsDataView: '利用履歴まとめ',
          genericColumnTitleStatisticsDataView: '全体',
          textTranslationTitleStatisticsDataView: 'テキスト翻訳',
          documentTranslationTitleStatisticsDataView: 'ドキュメント翻訳',
          wordsTranslatedField: '翻訳したワード数',
          charsTranslatedField: '翻訳した文字数',
          ratioTextDocumentField: 'テキスト翻訳／ドキュメント翻訳の比率',
          mostUsedLangsField: '最もよく使用している言語ペア',
          totalTextRequestsField: '翻訳回数',
          totalDocsTranslatedField: '翻訳したドキュメント数の合計',
          userColumn: 'ユーザー',
          numberOfWordsColumn: 'ワード数',
          currentQuota: '現在の使用状況',
          sortTableCancel: "クリックして並べ替えをキャンセル",
          sortTableAscending: "クリックして昇順に並べ替え",
          sortTableDescending: "クリックして降順に並べ替え",

          af: 'アフリカーンス語',
          am: 'アムハラ語',
          ar: 'アラビア語',
          'oc-ES': 'アラン語',
          auto: '言語を検出',
          eu: 'バスク語',
          bg: 'ブルガリア語',
          bn: 'ベンガル語',
          bs: 'ボスニア語',
          ca: 'カタルーニャ語',
          co: 'コルシカ語',
          cs: 'チェコ語',
          cy: 'ウェールズ語',
          da: 'デンマーク語',
          dv: 'ディベヒ',
          ee: 'さん、',
          gl: 'ガリシア語',
          de: 'ドイツ語',
          el: 'ギリシャ語',
          en: '英語',
          'en-CA': '英語（カナダ）',
          'en-US': '英語（米国）',
          'en-GB': '英語（英国）',
          es: 'スペイン語',
          'es-LA': 'スペイン語（ラテンアメリカ）',
          'es-MX': 'スペイン語（メキシコ）',
          'es-XM': 'スペイン語（国際）',
          'es-US': 'スペイン語（米国）',
          et: 'エストニア語',
          fa: 'ペルシャ語',
          'fa-AF': 'ダリー語',
          fi: 'フィンランド語',
          fo: 'ファロエ',
          tl: 'フィリピン語',
          fj: 'フィジー語',
          fr: 'フランス語',
          'fr-BE': 'フランス語（ベルギー）',
          'fr-CA': 'フランス語（カナダ）',
          'fr-FR': 'フランス語（フランス）',
          he: 'ヘブライ語',
          Hans: '簡体字中国語（翻訳）',
          hi: 'ヒンディー語',
          hm: 'フモン語',
          hr: 'クロアチア語',
          ht: 'ハイチクレオール語',
          hu: 'ハンガリー語',
          hy: 'アルメニア語',
          id: 'インドネシア語',
          is: 'アイスランド語',
          it: 'イタリア語',
          iu: 'イヌクチトゥー',
          'iu-Latn': 'イヌクチトゥー（ラテン）',
          ja: '日本語',
          Jpan: '日本語（翻訳）',
          km: 'クメール語',
          ko: '韓国語',
          ka: 'ジョージア語',
          ks: 'カシミリ',
          'lb-LU': 'ルクセンブルク語',
          lo: 'ラオ語',
          lt: 'リトアニア語',
          lv: 'ラトビア語',
          mg: 'マラガシ語',
          mk: 'マケドニア語',
          mn: 'モンゴル語',
          ms: 'マレー語',
          mt: 'マルタ語',
          my: 'ビルマ語',
          multi: '多言語',
          mww: 'フモンダウ語',
          ne: 'ネパール語',
          no: 'ノルウェー語',
          nl: 'オランダ語',
          'nl-BE': 'オランダ語（ベルギー）',
          ns: 'セペディ',
          oc: 'オクシタン',
          otq: 'ケレタロオトミ語',
          pl: 'ポーランド語',
          pt: 'ポルトガル語',
          'pt-PT': 'ポルトガル語（ポルトガル）',
          'pt-BR': 'ポルトガル語（ブラジル）',
          ro: 'ルーマニア語',
          rw: 'キニヤルワンダ語',
          ru: 'ロシア語',
          sk: 'スロバキア語',
          sl: 'スロベニア語',
          si: 'シンハラ語',
          sm: 'サモア語',
          sr: 'セルビア語',
          'sr-RS-CYRL': 'セルビア語（キリル文字）',
          'sr-RS-LATN': 'セルビア語（ラテン文字）',
          sv: 'スウェーデン語',
          sw: 'スワヒリ語',
          ta: 'タミル語',
          th: 'タイ語',
          ti: 'ティグリニャ語',
          to: 'トンガ語',
          tr: 'トルコ語',
          tn: "セットワナ",
          ty: 'タヒチ語',
          tw: 'トイ',
          uk: 'ウクライナ語',
          ur: 'ウルドゥー語',
          vi: 'ベトナム語',
          yua: 'ユカテカマヤ語',
          yue: '広東語',
          sq: 'アルバニア語',
          az: 'アゼルバイジャン語',
          be: 'ベラルーシ語',
          eo: 'エスペラント語',
          gd: 'ゲール語（スコットランド）',
          ga: 'ゲール語（アイルランド）',
          kn: 'カンナダ語',
          gu: 'グジャラート語',
          ha: 'ハウサ語',
          la: 'ラテン語',
          mr: 'マラーティー語',
          tg: 'タジク語',
          te: 'テルグ語',
          uz: 'ウズベク語',
          xh: 'コサ語',
          yi: 'イディッシュ語',
          zu: 'ズールー語',
          cb: 'セブアノ語',
          fy: 'フリジア語',
          hw: 'ハワイ語',
          ig: 'イボ語',
          jv: 'ジャワ語',
          kk: 'カザフ語',
          kmr: 'クルド語（北部）',
          ku: 'クルド語',
          ky: 'キルギス語',
          ml: 'マラヤーラム語',
          mi: 'マオリ語',
          ny: 'ニャンジャ語',
          or: 'オディア語',
          ps: 'パシュトー語',
          pa: 'パンジャーブ語',
          rn: 'カシミリ',
          st: 'セソト語',
          sn: 'ショナ語',
          sd: 'シンド語',
          so: 'ソマリ語',
          su: 'スンダ語',
          tt: 'タタール語',
          tk: 'トルクメン語',
          ts:'ツォンガ',
          ug: 'ウイグル語',
          yo: 'ヨルバ語',
          'zh-CN': '中国語（簡体字）',
          'zh-TW': '中国語（台湾）',
          'zh-HK': '中国語（香港）',
        }
      },
      'ko': {
        translations: {
          textOk: '확인',
          FAQ: '도움말',
          textTitle: '텍스트 번역 페이지',
          documentTitle: '문서 번역 페이지',
          loginTitle: '로그인',
          statisticTitle: '통계 페이지',
          textAreaAnnouncementBox1: '번역할 텍스트를 기입할 수 있는 텍스트 영역',

          textAreaAnnouncementBox2: '번역 완료시 번역된 텍스트가 나타나는 텍스트 영역.',

          AnnounceModalCloseButton: '닫기',
          AnnounceDialogOpened: '대화 상자가 열립니다',
          AnnounceDialogClosed: '대화 상자가 닫힙니다',
          AnnounceTextisCleared: '텍스트가 삭제됩니다',
          AnnounceCopyTransactionRecordText: '거래 기록 복사',
          AnnounceCopiedToClipboard: '클립보드에 복사됨',
          AnnounceCopyTranslatedText: '번역된 텍스트 복사하기',
          ClearTextLabelandTippy: '텍스트 지우기',
          AnnounceTranslatedText: '번역된 텍스트',
          headerH1: '기계 번역 서비스',
          headerLogout: '로그아웃',
          headerAccountSettings: '계정 설정',
          headerVersion: '버전',
          dropdownWebsiteLanguage: '웹사이트 언어 설정',
          dropdownUserSetting: '사용자 설정',
          dropdownsourceLang: '원본 언어',
          dropdowntargetLang: '요청 언어',
          operations: '출처',
          allOperations: '모두',
          textTranslateOperations: '텍스트 번역',
          documentTranslateOperations: '문서 번역',
          speechDocumentOperations: '음성을 텍스트로 변환',
          transliteration: "음역",
          websiteTranslateOperations: "웹사이트 번역",
          speechTranscription: "음성 전사",
          words: '단어',
          characters: '문자',
          scale: '축척',
          total: '합계',
          linearScale: '선형',
          sqrtScale: '제곱근',
          dropdownSelected: '선택되었습니다',
          sidebarTippyText: '텍스트 번역기',
          sidebarText: '텍스트',
          sidebarDocument: '문서',
          sidebarTippyDocument: '문서 번역기',
          loginh1: '로그인 필요',
          loginButton: '로그인',
          textH2NavigateLanguageTranslationDropDown: '원본 및 요청 언어 선택하기',

          textTippySwap: '언어 바꾸기',
          textTippySwapBlocked: '\'언어 감지하기\'를 바꿀 수 없습니다 ',
          textTippySwapBlockedMulti: '다국어를 교환할 수 없음',
          textH2NavigateTextTranslationArea: '텍스트 번역 영역',
          textSourceTextareaPlaceholder: '여기에 텍스트를 입력하거나 붙여넣기 하세요...',
          textSourceTextareaButton: '파일 선택하기',
          textTranslateButton: '번역하기',
          textCopyTippy: '텍스트 복사하기',
          documentH1: '문서 번역',
          documentDroph2: '파일 끌어다 놓기 또는',
          documentDrophSelect: '컴퓨터에서 선택',
          documentDropFileMessage: '지원되는 파일 형식: ',
          documentDrophMaxSize: '최대 파일 크기 <strong>50MB</strong>, ZIP 파일 최대 <strong>512MB</strong>',
          dragAndDropTooltip: '끌어다 놓기',
          documentH2: '번역',
          documentSubHeaderDate: '날짜',
          documentSubHeaderSubName: '이름',
          documentSubHeaderSource: '언어',
          documentSubHeaderTarget: '요청 언어',
          documentSubHeaderFiles: '다운로드',
          documentSubHeaderPages: '삭제',
          submissionNameHeader: "의뢰작업명",
          submissionNameMaxLength: '제출 이름은 최대 180자까지 가능합니다.',
          fileNameHeader: "파일명",
          categoryHeader: "범주",
          categoryPlaceholder: "카테고리 없음",
          submissionNamePlaceholder: "여기에 입력하세요...",
          fileStatusSuccessful: "번역됨",
          downloadFailedError: "번역에 실패하여 파일을 다운로드할 수 없습니다.",
          downloadingStatisticsMessage: "보고서를 생성하는 데 몇 분이 걸릴 수 있습니다...",
          downloadingStatisticsError: "보고서를 생성하는 중 오류가 발생했습니다. 다시 시도하거나 지원 부서에 문의하십시오.",
          documentSubHeaderActions: "작업",
          documentSubHeaderWCount: "단어 수:",
          documentSubHeaderCCount: "문자 수:",
          documentSubHeaderStatus: '상태',
          documentSubRowStatusDeleting: '삭제 중...',
          documentSubRowChildStatusTranslating: '번역 중',
          documentSubRowChildStatusTranslated: '번역 성공',
          documentSubRowChildStatusTranslatedSuccess: "번역됨",
          documentSubRowChildStatusFailed: "실패",
          documentSubRowChildStatusUploading: '업로드 중',
          documentSubRowChildStatusError: '번역 실패',
          documentSubRowChildDeleteTippy: '파일 삭제하기',
          documentFileIconTippy: '파일',
          documentSubRowChildDownloadTippy: '번역된 파일 다운로드',
          documentSubRowChildDownloadSourceTippy: '원본 문서 다운로드',
          documentSubRowChildDownloadHeader: '다운로드',
          documentSubRowChildDownloadLoadingTippy: "다운로드 중",
          documentSubRowChildCopyToClipboard: '클립보드에 복사',
          documentSubRowChildCopiedToClipboardTippy: "클립보드에 복사됨",
          documentSubRowChildDownloadSourceDropdown: "소스 형식으로 다운로드하기",
          documentSubRowChildDownloadMoreOptions: '추가 다운로드 옵션',
          documentSubRowChildExpand: '확장',
          documentSubRowChildCollapse: '접기',
          documentSubRowZipping: '지퍼',
          documentSubRowZipAndDownload: '우편 번호 및 다운로드',
          documentSubRowZipHelp: '도움말',
          documentSubRowDownloadZip: '다운로드',
          documentSubRowDownloadZipError: '오류',
          documentSubRowChildFailedTranslationHelpTippy: "실패한 번역에 대한 도움말",
          documentSubRowChildFailedTranslationHelpCloseTippy: "실패한 번역에 대한 도움말 닫기",
          documentSubRowChildHumanTranslationRequestedTippy: "인간 번역 요청함",
          documentSubRowChildHumanTranslationFailedTippy: "인간 번역 요청 실패함",
          documentSubRowChildRequestHumanTranslationTippy: "인간 번역 요청하기",
          documentSubRowChildCloseRequestHumanTranslationTippy: "인간 번역 메뉴 닫기",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "요청 로드 중",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "새 탭에서 GL Portal 열기",
          documentSubRowChildHumanTranslationRequestInProcess: "인간 번역 요청이 진행 중입니다.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "GL Portal에서 완료하기",
          documentSubRowChildHumanTranslationRequest: "인간 번역 요청하기",
          documentSubRowChildHumanTranslationRequested: "인간 번역 요청됨",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "GL Portal 진행상황 확인하기",
          documentSubRowChildHumanTranslationRequestGoToTransport: "GL Portal로 이동하기",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (추가 비용)",
          documentSubRowChildEditTranslatedFile: '번역된 파일 편집',
          documentSubRowChildMoreActionsTooltip: '추가 작업',
          helpSentencePartOne: '도움이 필요하면 ',
          helpSentencePartTwo: '여기',
          helpSentencePartTwoHover: '이렇게 하면 지원팀에 새 이메일 메시지가 열립니다.',
          helpSentencePartThree: ' 지원팀에 문의. 이메일 본문에 문제를 기술하십시오.',
          documentSubRowChildDownloadPdfDropdown: 'pdf로 다운로드',
          documentSubRowChildLanguageTo: '>',
          documentTippyAdd: '파일 선택하기',
          deleteModalTitle: '파일 삭제하기',
          deleteModalCheck: '이 파일을 정말 삭제하시겠습니까?',
          deleteModalCancel: '취소',
          deleteModalDelete: '삭제',
          modalH1: '파일 업로드하기',
          modalH1AfterUpload: '파일 업로드 완료',
          modalPTop: '번역을 위해 선택된 파일들은 아래와 같습니다:',
          modalTippyAdd: '파일 추가하기',
          modalConfirm: '원본 및 요청 언어 확인하기',
          modalTippySwap: '언어 바꾸기',
          modalTranslate: '번역하기',
          modalLoading: '로드 중...',
          modalUploadSuccessful: '번역 진행 중',
          processingRequestMsg: '요청 처리 중...',

          modalUploadErrorMessagePartOne:'파일을 업로드하는 중 오류가 발생했습니다.',
          modalUploadErrorMessagePartTwo:'다시 시도하십시오.',
          modalUploadErrorMessagePartThree:'I문제가 지속되는 경우 ',
          modalUploadErrorMessagePartFour: '지원팀에 문의하십시오.',
          modalMsgEmlAutoDetectError: '“소스 언어”에서 이메일의 원본 언어를 선택하여 번역하십시오.\n',
          modalPdfAutodetectOcrError: '\\"소스 언어\\"에서 이미지 또는 pdf의 원본 언어를 선택하여 번역하십시오.\n',
          mandatoryCategoryAndSubmissionNameError: '번역을 계속하려면 범주와 의뢰 이름을 선택하십시오.',
          mandatorySubmissionNameError: '번역을 계속하려면 의뢰 이름을 선택하십시오.',
          mandatoryCategoryError: '번역을 계속하려면 범주를 선택하십시오.',
          deleteModalProblem: '파일을 삭제하는 데 문제가 있습니다. 이 모달을 닫고 다시 시도하십시오.',
          unsupportedFileMessage: '하나 이상의 파일을 번역할 수 없습니다. 변환한 후 다시 시도하십시오.\n',
          statisticErrorTime: '시작 날짜가 종료 날짜 이후인 것 같습니다. 종료 날짜 이전의 시작 날짜를 선택하십시오.',
          statisticErrorFutureDate: '시작 날짜가 미래인 것 같습니다. 오늘 이전 시작 날짜를 선택하십시오.\n',
          unsupportedSourceLang: 'T검색된 언어를 조직에서 사용할 수 없습니다. 더 많은 언어는 소속 조직에서 포털을 관리하는 담당자에게 문의하십시오.',
          reachedQuotaLimitError: '귀하의 조직이 번역 한도에 도달했습니다. 소속 조직에서 포털을 관리하는 담당자에게 연락하여 이 한도를 연장하십시오.\n',
          orgExpiredError: '귀하의 조직이 만료일에 도달했기 때문에 번역할 수 없습니다. 소속 조직에서 포털을 관리하는 담당자에게 연락하여 이 한도를 연장하십시오.',
          textTranslationError: '번역에 실패했습니다. 지원은 도움을 받을 수 있습니다.',
          statisticRequestError:'보고서를 생성하는 중 오류가 발생했습니다. 다시 시도하십시오.',
          downloadPdfError: '업로드된 파일 형식을 pdf로 변환할 수 없습니다.',
          noDataError: '이 날짜 사이에 번역을 찾을 수 없습니다. 실수라고 생각되면 지원을 받을 수 있습니다.',
          'AP-12-001': '파일이 비어 있는 것 같습니다. 확인 후 다시 시도하십시오.',
          'AP-12-001-desc': '파일이 비어 있는 것 같습니다. 확인 후 다시 시도하십시오.',
          'AP-12-001-desc-pdf':'텍스트가 감지되지 않았습니다. OCR이 활성화되어 있는지 다시 시도하십시오.\n',
          'AP-01-003-desc': '파일이 조직의 단어 제한을 초과합니다. 작은 조각으로 나누어 다시 시도하십시오.',
          'WFM-04-SRCTRGSAME': '원본 언어와 대상 언어는 같을 수 없습니다. 그 중 하나를 수정하십시오.',
          'WFM-04-SRCTRGSAME-desc':'원본 언어와 대상 언어는 같을 수 없습니다. 그 중 하나를 수정하십시오.',
          'WFM-04-SRCTRGSAME-desc-pdf' : '원본 언어와 대상 언어는 같을 수 없습니다. 그 중 하나를 수정하십시오.',
          'USRMNG-DB-1062': '이 사용자는 이미 존재합니다. 검색하여 해당 내용을 변경하시기 바랍니다.',
          'STT-DOC-04-SIZE': '최대 파일 크기는 200MB입니다. 파일을 나누고 다시 시도하십시오.',
          'STT-fileSIZE': '파일을 최대 50MB까지 번역할 수 있습니다. 더 큰 파일을 제거하고 더 작은 파일로 나누고 다시 시도하십시오.',
          'STT-fileSIZE-zip': '최대 512MB의 zip 파일을 번역할 수 있습니다. ZIP에서 파일을 제거하고 다시 시도하십시오.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'P한 번에 하나의 wav 파일을 업로드하거나 문서 번역을 시도하여 번역하십시오',
          TT_WAV_ERROR_AUTO_DETECT:'\\\\"소스 언어\\\\"에서 톱 파일의 원본 언어를 선택하여 번역하십시오.',
          TT_WAV_ERROR_FILE_TYPE:'문서 번역을 사용하여 이 유형의 파일 번역',
          TT_WAV_ERROR_FILE_SIZE: '최대 파일 크기는 200MB입니다. 파일을 나누고 다시 시도하십시오.',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'PDF 및 wav 파일을 별도로 번역하십시오.',
          'WFM-error': '파일 처리 중 오류가 발생했습니다. 다시 시도하십시오.',
          'WFM-error-desc': '파일 처리 중 오류가 발생했습니다. 다시 시도하십시오.',
          'WFM-04-INVLANG': '검색된 언어를 조직에서 사용할 수 없습니다. 더 많은 언어는 소속 조직에서 포털을 관리하는 담당자에게 문의하십시오.',
          'WFM-04-INVLANG-desc': '검색된 언어를 조직에서 사용할 수 없습니다. 더 많은 언어는 소속 조직에서 포털을 관리하는 담당자에게 문의하십시오.',
          'OCR-03-A2A-FAIL': '파일을 번역하는 중 오류가 발생했습니다. 다시 시도하십시오.',
          'OCR-03-A2A-TIMEOUT': '파일을 번역하는 중 오류가 발생했습니다. 다시 시도하십시오.',


          copyright: ' Translations.com 모든 권리 보유.',
          statistics: '통계',
          statisticFrom: '시작일:',
          statisticTo: '종료일:',
          statisticDownload: '보고서 다운로드',
          textTranslationReachedLimit: '요청당 최대 10000자까지 번역할 수 있습니다.',
          users: '사용자',
          allUsers: '모두',
          allLanguages: '모두',
          loadTimeFrame: '설정기간 데이터 로드   ',

          'OCR-03-A2A-PASSWORD': '오류, pdf에서 암호를 삭제하십시오',
          'OCR-03-A2A-PASSWORD-desc-pdf':  'PDF에 암호가 설정되어 있습니다. pdf에서 암호를 제거하십시오',

          'AP-08-001': '오류, pdf에서 암호를 삭제하십시오',
          'AP-08-001-desc-pdf': 'PDF에 암호가 설정되어 있습니다. pdf에서 암호를 제거하십시오',

          'OCR-03-A2A-SIZE-EX': 'pdf 변환이 가능한 최대 페이지 크기는 A4 사이즈입니다.',

          quickLinksLabel: '빠른 링크:',
          'OCR-03-A2A-HEALTH': 'OCR 사용 임시 불가. 다시 시도하십시오',
          'MT-error': '번역 엔진 오류',
          'MT-error-desc': '번역 엔진 오류',
          'USRMNG-DB-1452': '입력값 오류',
          TT_WAV_ERROR_NO_FILES_DETECTED: "WAV 파일을 감지할 수 없습니다",
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT:  '전사가 아직 진행 중입니다. 지금 이 페이지를 종료하면 전사가 저장되지 않습니다',
          qualityTranslationEngineInfo: "TransPerfect 메인 엔진을 통해 귀사의 요구 사항에 따라 번역의 충실도에 대한 정보를 얻을 수 있습니다. 테스트하십시오!",
          infoQualityControlFeature: "품질 관리는 귀하의 필요에 따라 번역이 가지고 있는 충실도 수준에 대한 정보를 얻을 수 있는 기능입니다. 충실도 수준은 귀하의 요건에 명시된 대로 당사가 귀하에게 제공하는 번역의 정확성으로 정의됩니다.",
          transliterationTippy: '텍스트 음역하기',
          infoQualityControlFeatureTooltip: '당사 제품의 품질과 투명성을 보장하기 위해 TransPerfect 메인 엔진은 귀사의 필요에 따라 번역이 제공하는 충실도 수준에 대한 정보를 제공합니다.',
          infoQualityControlFeatureTooltipLearnMore: '자세히 알아보기',
          translationQualityGood: '번역이 훌륭해 보입니다!',
          translationQualityAverage: '번역에 오류가 있을 수 있습니다. 주의해서 사용하십시오!',
          translationQualityBad: '번역에 문제가 있을 수 있습니다. 개정이 권장됩니다.',
          translationQualityGoodModal: '좋음',
          translationQualityAverageModal: '주의하여 사용',
          translationQualityBadModal: '문제가 있을 가능성이 높음',
          translationQualityGoodModalDescription: '이번 번역에 의존할 수 있습니다.',
          translationQualityAverageModalDescription: '번역에 일부 결함이 있을 수 있습니다. 내부적으로 사용하는 것이 좋습니다. 외부용으로는 전문 번역가에게 문의하는 것이 좋습니다.',
          translationQualityBadModalDescription: '이 번역에는 몇 가지 주요 오류가 있을 수 있습니다. 전문 번역가와 함께 수정해 주시기 바랍니다.',
          websiteTranslator: '웹사이트',
          websiteTranslatorTippy: '웹사이트 번역기',
          urlInputPlaceHolder: '여기에 URL을 붙여넣기 하세요...',
          websiteTranslateAction: '번역하기',
          removeAllPreviousWebsiteTranslateRequests: '모두 지우기',
          previousWebsiteTranslateRequestTitle: '요청 기록:',
          wtLoadingCaption: '웹사이트 번역 중...',
          ocrCheckboxLabel: '이미지와 스캔 문서를 업로드하고 있습니다',
          ocrHelpText: '이것을 활성화해야 하는 경우',
          ocrCheckboxTippy:  'OCR(광학 문자 인식)은 이미지 및 스캔된 문서 내의 텍스트를 감지합니다. 번역되지 않은 빈 문서를 받았거나 pdf의 텍스트 내용을 선택할 수 없는 경우 이 옵션을 이용해 보세요.',

          websitePage: '웹사이트 번역 페이지',
          lastWTRequestURL: '마지막 웹사이트 번역 요청:',
          openWebsiteBtnLabel: '열기',
          invalidURL: 'URL 형식 오류',
          ClearURLLabelandTippy: 'URL 지우기',
          inactivityLogoutTitle: '로그아웃 되었습니다',
          inactivityLogoutMessage: '활동이 없어 로그아웃 되었습니다',
          inactivityLogoutButtonLabel: '다시 로그인 하기',
          logoutScreenMessage: '방문해 주셔서 감사합니다!',
          logoutScreenLogoutButtonLabel: '다시 로그인 하기',
          langSelect_NoResultsMessage: '검색 결과 없음',
          downloadSourceFileSubheader: '원본언어',
          documentSubRowChildCopyToClipboardTippy: "클립보드에 복사하기",
          reachedQuotaLimitErrorPartOne: '組織が翻訳制限に達したため、翻訳できません。',
          reachedQuotaLimitErrorPartTwo: 'これを拡張するには、組織内のポータル管理者に連絡してください。',
          orgExpiredErrorPartOne: '組織が有効期限に達したため、翻訳できません。',
          orgExpiredErrorPartTwo: '組織のポータル管理担当者に連絡して、これらの制限を延長してください。',
          downloadTranslatedFileSubheader: "결과",
          menuLabel: '메뉴',
          // statistics data view
          dataView: '데이터',
          graphView: '그래프',
          mainTitleStatisticsDataView: '선택한 기간의 조직 통계',
          genericColumnTitleStatisticsDataView: '일반',
          textTranslationTitleStatisticsDataView: '텍스트 번역',
          documentTranslationTitleStatisticsDataView: '문서 번역',
          wordsTranslatedField: '번역된 단어',
          charsTranslatedField: '번역된 문자',
          ratioTextDocumentField: '텍스트 번역/문서 번역 비율',
          mostUsedLangsField: '가장 많이 사용되는 언어 쌍',
          totalTextRequestsField: '총 요청 수',
          totalDocsTranslatedField: '번역된 총 문서 수',
          userColumn: '사용자',
          numberOfWordsColumn: '단어 수',
          currentQuota: '현재 할당량 상태',
          sortTableCancel: "클릭하여 정렬을 취소하세요",
          sortTableAscending: "클릭하여 오름차순으로 정렬하세요",
          sortTableDescending: "클릭하여 내림차순으로 정렬하세요",

          af: '아프리칸스어',
          am: '암하라어',
          ar: '아랍어',
          'oc-ES': '아라네스어',
          auto: '언어 감지',
          eu: '바스크어',
          bg: '불가리아어',
          bn: '방글라어',
          bs: '보스니아어',
          ca: '카탈로니아어',
          co: '코르시카어',
          cs: '체코어',
          cy: '웰시어',
          da: '덴마크어',
          dv: '디베히',
          ee: '이웨',
          gl: '갈리시아어',
          de: '독일어',
          el: '그리스어',
          en: '영어',
          'en-CA': '영어(캐나다)',
          'en-US': '영어(미국)',
          'en-GB': '영어(영국)',
          es: '스페인어',
          'es-LA': '스페인어(라틴 아메리카)',
          'es-MX': '스페인어(멕시코)',
          'es-XM': '스페인어(국제어)',
          'es-US': '스페인어(미국)',
          et: '에스토니아어',
          fa: '페르시아어',
          'fa-AF': '다리어',
          fi: '핀란드어',
          fo: '페로즈',
          tl: '필리핀어',
          fj: '피지어',
          fr: '프랑스어',
          'fr-BE': '프랑스어(벨기에)',
          'fr-CA': '프랑스어(캐나다)',
          'fr-FR': '프랑스어(프랑스)',
          he: '히브리어',
          Hans: '중국어 간체(번역)',
          hi: '힌디어',
          hm: '몽어',
          hr: '크로아티아어',
          ht: '아이티 크레올어',
          hu: '헝가리어',
          hy: '아르메니아어',
          id: '인도네시아어',
          is: '아이슬란드어',
          it: '이탈리아어',
          iu: '후크티투트',
          'iu-Latn': '이누키투트(라틴)',
          ja: '일본어',
          Jpan: '일본어(번역)',
          km: '크메르어',
          ko: '한국어',
          ka: '조지아어',
          ks: '카슈미리',
          'lb-LU': '룩셈부르크어',
          lo: '라오어',
          lt: '리투아니아어',
          lv: '라트비아어',
          mg: '말라가시어',
          mk: '마케도니아어',
          mn: '몽골어',
          ms: '말레이어',
          mt: '몰타어',
          my: '버마어',
          multi: '다국어',
          mww: '몽 도우어',
          ne: '네팔어',
          no: '노르웨이어',
          nl: '네덜란드어',
          'nl-BE': '네덜란드어(벨기에)',
          ns: '세페디',
          oc: '교황',
          otq: '케레타로 오토미어',
          pl: '폴란드어',
          pt: '포르투갈어',
          'pt-PT': '포르투갈어(포르투갈)',
          'pt-BR': '포르투갈어(브라질)',
          ro: '루마니아어',
          rw: '키냐르완다어',
          ru: '러시아어',
          sk: '슬로바키아어',
          sl: '슬로베니아어',
          si: '신할라어',
          sm: '사모아어',
          sr: '세르비아어',
          'sr-RS-CYRL': '세르비아어(키릴어)',
          'sr-RS-LATN': '세르비아어(라틴어)',
          sv: '스웨덴어',
          sw: '키스와힐리어',
          ta: '타밀어',
          th: '태국어',
          ti: '티그리냐어',
          to: '통가어',
          tn: '세트화나',
          tr: '터키어',
          ts:'송가 트손가s',
          ty: '타히티어',
          tw: '트위스',
          uk: '우크라이나어',
          ur: '우르두어',
          vi: '베트남어',
          yua: '유카텍 마야어',
          yue: '광둥어',
          sq: '알바니아어',
          az: '아제르바이잔어',
          be: '벨라루스어',
          eo: '에스페란토',
          gd: '게일어(스코틀랜드)',
          ga: '게일어(아일랜드)',
          kn: '칸나다어',
          gu: '구자라티어',
          ha: '하우사어',
          la: '라틴어',
          mr: '마라티어',
          tg: '타지크어',
          te: '텔루구어',
          uz: '우즈베크어',
          xh: '코사어',
          yi: '이디시어',
          zu: '줄루어',
          cb: '세부아노어',
          fy: '프리지아어',
          hw: '하와이어',
          ig: '이그보어',
          jv: '자바어',
          kk: '카자흐어',
          kmr: '쿠르디시어(북부)',
          ku: '쿠르드어',
          ky: '키르기스어',
          ml: '말라얄람어',
          mi: '마오리어',
          ny: '냔자어',
          or: '오리야어',
          ps: '파슈토어',
          pa: '펀자브어',
          rn: '카슈미리',
          st: '세소토어',
          sn: '쇼나어',
          sd: '신드어',
          so: '소말리어',
          su: '순다어',
          tt: '타타르어',
          tk: '투르크멘어',
          ug: '위구르어',
          yo: '요루바어',
          'zh-CN': '중국어(간체)',
          'zh-TW': '중국어(타이완)',
          'zh-HK': '중국어(홍콩)',
        }
        },
      'sv': {
        translations: {
          textOk: 'OK',
          FAQ: 'Hjälp',
          English: 'Engelska',
          Catalan: 'Katalanska',
          Spanish: 'Spanska',
          French: 'Franska',
          textTitle: 'Textöversättningssida',
          documentTitle: 'Dokumentöversättningssida',
          loginTitle: 'Logga in',
          statisticTitle: 'Statistiksida',
          textAreaAnnouncementBox1: 'textfält där du kan skriva text som ska översättas',
          textAreaAnnouncementBox2: 'textfält där den översatta texten visas när översättningen är klar.',
          AnnounceModalCloseButton: 'Stäng',
          AnnounceDialogOpened: 'Dialog öppnad',
          AnnounceDialogClosed: 'Dialog stängd',
          AnnounceTextisCleared: 'Text rensad',
          AnnounceCopyTransactionRecordText: 'Kopiera transaktionspost',
          AnnounceCopiedToClipboard: 'Kopierad till urklipp',
          AnnounceCopyTranslatedText: 'Kopiera översatt text',
          ClearTextLabelandTippy: 'Rensa text',
          AnnounceTranslatedText: 'Översatt text',
          headerH1: 'Maskinöversättningstjänst',
          headerLogout: 'Logga ut',
          headerAccountSettings: 'Kontoinställningar',
          headerVersion: 'Version',
          dropdownWebsiteLanguage: 'ange webbplatsspråk',
          dropdownUserSetting: 'användarinställningar',
          dropdownsourceLang: 'Källspråk',
          dropdowntargetLang: 'Målspråk',
          operations: 'Ursprung',
          allOperations: 'Allt',
          textTranslateOperations: 'Textöversättning',
          documentTranslateOperations: 'Dokumentöversättning',
          speechDocumentOperations: 'Tal till text',
          transliteration: 'Omskrivning',
          websiteTranslateOperations: 'Webbplatsöversättning',
          speechTranscription: 'Taltranskribering',
          words: 'Ord',
          characters: 'Tecken',
          scale: 'Skala',
          total: 'Totalt',
          linearScale: 'Linjär',
          sqrtScale: 'Kvadratrot',
          dropdownSelected: 'är markerad',
          sidebarTippyText: 'Textöversättare',
          sidebarText: 'Text',
          sidebarDocument: 'Dokument',
          sidebarTippyDocument: 'Dokumentöversättare',
          loginh1: 'Inloggning krävs',
          loginButton: 'Logga in',
          textH2NavigateLanguageTranslationDropDown: 'Välj käll- och målspråk',
          textTippySwap: 'Byt språkens plats',
          textTippySwapBlocked: 'Det går inte att byta plats med detekterat språk ',
          textTippySwapBlockedMulti: 'Kan inte byta "flerspråkig"',
          textH2NavigateTextTranslationArea: 'Textöversättningsfält',
          textSourceTextareaPlaceholder: 'Skriv eller klistra in din text här ...',
          textSourceTextareaButton: 'Välj filer',
          textTranslateButton: 'Översätt',
          textCopyTippy: 'Kopiera text',
          documentH1: 'Dokumentöversättning',
          documentDroph2: 'Dra och släpp filer eller',
          documentDrophMaxSize: 'Max filstorlek <strong>50MB</strong>, ZIP-filer upp till <strong>512MB</strong>',
          dragAndDropTooltip: 'Dra och släpp',
          documentDrophSelect: 'välj dem på din dator',
          documentDropFileMessage: 'Kompatibla bildfilformat: ',
          documentH2: 'Översättningar',
          documentSubHeaderDate: 'Datum',
          documentSubHeaderSubName: 'Namn',
          documentSubHeaderSource: 'Språk',
          documentSubHeaderTarget: 'Målspråk',
          documentSubHeaderFiles: 'Hämta',
          documentSubHeaderPages: 'Ta bort',
          submissionNameHeader: "Inlämningsnamn",
          submissionNameMaxLength: 'Inlämningsnamnet kan innehålla upp till 180 tecken',
          fileNameHeader: "Filnamn",
          categoryHeader: "Kategori",
          categoryPlaceholder: "Ingen kategori",
          submissionNamePlaceholder: "Skriv här...",
          fileStatusSuccessful: "Översatt",
          downloadFailedError: "Filen går inte att hämta eftersom översättning misslyckades.",
          downloadingStatisticsMessage: "Skapa din rapport, det kan ta några minuter...",
          downloadingStatisticsError: "Ett fel uppstod när din rapport skulle skapas. Försök igen eller kontakta support.",
          documentSubHeaderActions: "Åtgärder",
          documentSubHeaderWCount: "Antal ord:",
          documentSubHeaderCCount: "Antal tecken:",
          documentSubHeaderStatus: 'Status',
          documentSubRowStatusDeleting: 'Raderar ...',
          documentSubRowChildStatusTranslating: 'Översätter ',
          documentSubRowChildStatusTranslated: 'Översättning slutförd',
          documentSubRowChildStatusTranslatedSuccess: 'Översatt',
          documentSubRowChildStatusError: 'Översättningen misslyckades',
          documentSubRowChildStatusFailed: 'Misslyckades',
          documentSubRowChildStatusUploading: 'Laddar upp',
          documentSubRowChildDeleteTippy: 'Ta bort fil',
          documentFileIconTippy: 'Fil',
          documentSubRowChildDownloadTippy: 'Hämta översatt fil',
          documentSubRowChildDownloadSourceTippy: 'Hämta källfil',
          documentSubRowChildDownloadHeader: 'Hämta',
          documentSubRowChildDownloadLoadingTippy: "Nedladdning laddas",
          documentSubRowChildCopyToClipboardTippy: "Kopiera till urklippet",
          documentSubRowChildCopiedToClipboardTippy: "Kopierad till urklippet",
          documentSubRowChildFailedTranslationHelpTippy: "Hjälp med misslyckad översättning",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Stäng hjälp med misslyckad översättning",
          documentSubRowChildDownloadSourceDropdown: "Hämta som källformat",
          documentSubRowChildDownloadMoreOptions: 'Fler nedladdningsalternativ',
          documentSubRowChildExpand: 'Expandera',
          documentSubRowChildCollapse: 'Kollaps',
          documentSubRowZipping: 'Blixtlås',
          documentSubRowZipAndDownload: 'Zip och ladda ner',
          documentSubRowZipHelp: 'Zip-hjälp',
          documentSubRowDownloadZip: 'Hämta zip',
          documentSubRowDownloadZipError: 'Fel vid zippning',
          documentSubRowChildHumanTranslationRequestedTippy: "Mänsklig översättning begärd",
          documentSubRowChildHumanTranslationFailedTippy: "Begäran om mänsklig översättning misslyckades",
          documentSubRowChildRequestHumanTranslationTippy: "Begär mänsklig översättning",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Stäng menyn för mänsklig översättning",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Laddar begäran",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Öppnar GL Portal i en ny flik",
          documentSubRowChildHumanTranslationRequestInProcess: "Begäran om mänsklig översättning pågår.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Slutför med GL Portal",
          documentSubRowChildHumanTranslationRequest: "Begär en mänsklig översättning",
          documentSubRowChildHumanTranslationRequested: "Mänsklig översättning begärd",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Kontrollera status för GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Öppna GL Portal",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (extra kostnad)",
          documentSubRowChildEditTranslatedFile: 'Redigera översatt fil',
          documentSubRowChildMoreActionsTooltip: 'Fler åtgärder',
          helpSentencePartOne: 'För hjälp, klicka ',
          helpSentencePartTwo: "här",
          helpSentencePartTwoHover: 'Detta öppnar ett nytt e-postmeddelande till support',
          helpSentencePartThree: ' för att kontakta support. Beskriv ditt problem i e-postmeddelandets huvudtext.',
          documentSubRowChildDownloadPdfDropdown: 'Ladda ner som pdf',
          documentTippyAdd: 'Välj filer',
          deleteModalTitle: 'Ta bort fil',
          deleteModalCheck: 'Vill du verkligen ta bort filen?',
          deleteModalCancel: 'Avbryt',
          deleteModalDelete: 'Ta bort',
          modalH1: 'Ladda upp dina filer',
          modalH1AfterUpload: 'Filer uppladdade',
          modalPTop: 'Du valde följande filer för översättning:',
          modalTippyAdd: 'Lägg till fler filer',
          modalConfirm: 'Bekräfta käll- och målspråk',
          modalTippySwap: 'Byt språkens plats',
          modalTranslate: 'Översätt',
          modalLoading: 'Läser in ...',
          modalUploadSuccessful: 'Översättning pågår',

          modalUploadErrorMessagePartOne:'Ett fel uppstod när dina filer skulle laddas upp.',
          modalUploadErrorMessagePartTwo:'Försök igen.',
          modalUploadErrorMessagePartThree:'Om problemet kvarstår ',
          modalUploadErrorMessagePartFour: 'kontakta support.',
          modalMsgEmlAutoDetectError: 'Välj det ursprungliga språket för din e-post på ”Källspråk” för att översätta den.',
          modalPdfAutodetectOcrError: 'Välj originalspråk för dina bilder eller pdf:er på ”Källspråk” för att översätta dem.',
          mandatoryCategoryAndSubmissionNameError: 'Välj en kategori och ett inlämningsnamn för att fortsätta med din översättning.',
          mandatorySubmissionNameError: 'Välj ett inlämningsnamn för att fortsätta med din översättning.',
          mandatoryCategoryError: 'Välj en kategori för att fortsätta med din översättning.',
          deleteModalProblem: 'Vi har problem med att radera din fil. Stäng denna modal och försök igen.',
          unsupportedFileMessage: 'Vi kan inte översätta en eller flera filer. Konvertera dem och försök igen.',
          statisticErrorTime: 'Startdatum verkar vara efter slutdatumet. Välj ett startdatum före slutdatumet.\n',
          statisticErrorFutureDate: 'Startdatum verkar vara i framtiden. Välj ett startdatum före idag.',
          unsupportedSourceLang: 'Det detekterade språket är inte tillgängligt i din organisation. För fler språk, kontakta den person som hanterar portalen i din organisation.',
          reachedQuotaLimitError: 'Din organisation har nått sina översättningsgränser. Kontakta den person som hanterar portalen i din organisation för att förlänga dessa gränser.',
          orgExpiredError: 'Du kan inte översätta eftersom din organisation har nått sitt utgångsdatum. Kontakta den person som hanterar portalen i din organisation för att förlänga dessa gränser.',
          textTranslationError: 'Översättningen misslyckades. Support finns tillgänglig för hjälp.',
          statisticRequestError:'Ett fel uppstod när din rapport skulle skapas. Försök igen.',
          downloadPdfError: 'Vi kan inte konvertera det uppladdade filformatet till pdf.',
          noDataError: 'Vi kunde inte hitta några översättningar mellan dessa datum. Om du tror att det är ett misstag finns stöd tillgängligt.',
          'AP-12-001': 'Filen verkar vara tom, kontrollera och försök igen.',
          'AP-12-001-desc': 'Filen verkar vara tom, kontrollera och försök igen.',
          'AP-12-001-desc-pdf':'Ingen text upptäcktes. Försök igen och se till att OCR är aktiverat.',
          'AP-01-003-desc': 'Filen överskrider ordgränserna för din organisation. Dela upp den i mindre bitar och försök igen.',
          'WFM-04-SRCTRGSAME': 'Källan och målspråket kan inte vara samma. Ändra en av dem',
          'WFM-04-SRCTRGSAME-desc':'Källan och målspråket kan inte vara samma. Ändra en av dem',
          'WFM-04-SRCTRGSAME-desc-pdf' : 'Källan och målspråket kan inte vara samma. Ändra en av dem',
          'USRMNG-DB-1062': 'Den här användaren finns redan. Sök efter den och gör motsvarande ändringar.',
          'STT-DOC-04-SIZE': 'Maximal filstorlek är 200MB. Dela upp filen och försök igen.\n',
          'STT-fileSIZE': 'Du kan översätta filer upp till 50MB. Ta bort större filer, dela upp dem i mindre och försök igen.',
          'STT-fileSIZE-zip': 'Du kan översätta zip filer upp till 512MB. Ta bort större filer, dela upp dem i mindre och försök igen.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Ladda upp en wav-fil åt gången eller försök med dokumentöversättning för att översätta den',
          TT_WAV_ERROR_AUTO_DETECT:'Välj originalspråk för dina wav-filer på ”Källspråk” för att översätta dem.',
          TT_WAV_ERROR_FILE_TYPE:'Använd dokumentöversättning för att översätta denna typ av fil',
          TT_WAV_ERROR_FILE_SIZE: 'Maximal filstorlek är 200MB. Dela upp filen och försök igen',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Översätt PDF- och wav-filer separat',
          'WFM-error': 'Vi hittade ett fel vid bearbetning av din fil. Försök igen',
          'WFM-error-desc': 'Vi hittade ett fel vid bearbetning av din fil. Försök igen',
          'WFM-04-INVLANG': 'Det detekterade språket är inte tillgängligt i din organisation. För fler språk, kontakta den person som hanterar portalen i din organisation.',
          'WFM-04-INVLANG-desc': 'Det detekterade språket är inte tillgängligt i din organisation. För fler språk, kontakta den person som hanterar portalen i din organisation.',
          'OCR-03-A2A-FAIL': 'Vi hittade ett fel vid översättning av din fil. Försök igen',
          'OCR-03-A2A-TIMEOUT': 'Vi hittade ett fel vid översättning av din fil. Försök igen',


          copyright: ' Translations.com – med ensamrätt.',
          statistics: 'Statistik',
          statisticFrom: 'Från:',
          statisticTo: 'Till:',
          statisticDownload: 'Hämta rapporten',
          textTranslationReachedLimit: 'Du kan översätta upp till 10 000 tecken per begäran',
          users: 'Användare',
          allUsers: 'Allt',
          allLanguages: 'Allt',
          loadTimeFrame: 'Inläsningstid',
          " OCR-03-A2A-PASSWORD": 'Ett fel inträffade. Radera lösenordet från pdf-filen',
          "OCR-03-A2A-PASSWORD-desc-pdf": 'Lösenordsskyddad pdf-fil – radera lösenordet från filen',
          "AP-08-001": 'Ett fel inträffade. Radera lösenordet från pdf-filen',
          "AP-08-001-desc-pdf": 'Lösenordsskyddad pdf-fil – radera lösenordet från filen',
          "OCR-03-A2A-SIZE-EX": 'Maximal sidstorlek för pdf-konvertering är A4',
          quickLinksLabel: 'Snabblänkar:',
          "OCR-03-A2A-HEALTH": 'OCR tillfälligt otillgängligt. Försök igen',
          "MT-error": 'Fel i översättningsfunktionen',
          "MT-error-desc": 'Fel i översättningsfunktionen',
          "USRMNG-DB-1452": 'Ogiltigt värde',
          "TT_WAV_ERROR_NO_FILES_DETECTED": 'Det gick inte att detektera några wav-filer',
          "TT_WAV_ERROR_FILE_TYP": 'Ingen wav-fil. Använd dokumentöversättaren för att översätta denna fil',
          "TT_WAV_ERROR_FILE_SIZ": 'Wav-filen är för stor – den största tillåtna storleken är 200 MB',
          "TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT": 'Transkribering pågår fortfarande. Transkriberingen går förlorad om du lämnar denna sida nu.',
          qualityTranslationEngineInfo: "TransPerfect Main Engine låter dig få information om vilken nivå av trohet vår översättning har enligt dina krav. Testa det!",
          infoQualityControlFeature: "Kvalitetskontroll är den funktion som gör att du kan få information om vilken nivå av trohet vår översättning har enligt dina behov. Trohetsnivån definieras som riktigheten i den översättning vi tillhandahåller dig enligt dina krav.",
          transliterationTippy: 'Skriv om text',
          infoQualityControlFeatureTooltip: 'För att säkerställa kvaliteten och transparensen hos vår produkt kan TransPerfect Main Engine hjälpa dig att få information om vilken nivå av trohet vår översättning har enligt dina behov.\'',
          infoQualityControlFeatureTooltipLearnMore: 'Läs mer',
          translationQualityGood: 'Översättningen ser bra ut!',
          translationQualityAverage: 'Översättning kan ha vissa fel, använd med försiktighet!',
          translationQualityBad: 'Översättning är sannolikt problematisk, revidering rekommenderas.',
          translationQualityGoodModal: 'Bra',
          translationQualityAverageModal: 'Använd med försiktighet',
          translationQualityBadModal: 'Sannolikt problematiskt',
          translationQualityGoodModalDescription: 'Du kan lita på den här översättningen.',
          translationQualityAverageModalDescription: 'Det kan finnas vissa brister i översättningen. Vi rekommenderar att den används internt. För extern användning rekommenderar vi att du kontrollerar det med en professionell översättare.',
          translationQualityBadModalDescription: 'Denna översättning kan ha några större fel. Vi rekommenderar att du korrigerar det med en professionell översättare.',
          websiteTranslator: 'Webbplats',
          websiteTranslatorTippy: 'Webbplatsöversättare',
          urlInputPlaceHolder: 'Klistra in din webbadress här ...',
          websiteTranslateAction: 'Översätt',
          removeAllPreviousWebsiteTranslateRequests: 'Rensa allt',
          previousWebsiteTranslateRequestTitle: 'Förfrågningshistorik:',
          wtLoadingCaption: 'Översätter webbplats ...',
          ocrCheckboxLabel: 'Jag laddar upp bilder och skannade dokument',
          ocrHelpText: 'När detta ska aktiveras',
          ocrCheckboxTippy: 'OCR (optisk teckenigenkänning) detekterar text i bilder och skannade dokument. Prova detta alternativ om du har fått en tom översättning eller om textinnehållet i din pdf-fil inte kan väljas.',
          websitePage: 'Webbplatsöversättningssida',
          lastWTRequestURL: 'Senaste begäran om webbplatsöversättning:',
          openWebsiteBtnLabel: 'Öppna',
          invalidURL: 'Ogiltigt webbadressformat',
          ClearURLLabelandTippy: 'Rensa webbadressen',
          inactivityLogoutTitle: 'Du har loggats ut',
          inactivityLogoutMessage: 'Du har loggats ut på grund av inaktivitet',
          inactivityLogoutButtonLabel: 'Logga in igen',
          logoutScreenMessage: 'Tack för att du besöker oss!',
          logoutScreenLogoutButtonLabel: 'Logga in igen',
          langSelect_NoResultsMessage: 'Inga resultat hittades',
          reachedQuotaLimitErrorPartOne: 'Du kan inte översätta eftersom din organisation har nått sina översättningsgränser.',
          reachedQuotaLimitErrorPartTwo: 'Kontakta den person som hanterar portalen i din organisation för att utöka denna.',
          orgExpiredErrorPartOne: 'Du kan inte översätta eftersom din organisation har nått sitt utgångsdatum.',
          orgExpiredErrorPartTwo: 'Kontakta den person som hanterar portalen i din organisation för att utöka dessa gränser.',
          menuLabel: 'Meny',
          downloadSourceFileSubheader: 'Källa',
          downloadTranslatedFileSubheader: 'Resultat',
          dataView: 'Data',
          graphView: 'Diagram',
          mainTitleStatisticsDataView: 'Organisationsstatistik för vald period',
          genericColumnTitleStatisticsDataView: 'Generisk',
          textTranslationTitleStatisticsDataView: 'Textöversättning',
          documentTranslationTitleStatisticsDataView: 'Dokumentöversättning',
          wordsTranslatedField: 'Antal översatta ord',
          charsTranslatedField: 'Antal översatta tecken',
          ratioTextDocumentField: 'Förhållande mellan textöversättning/dokumentöversättning',
          mostUsedLangsField: 'Mest använda språkpar',
          totalTextRequestsField: 'Totalt antal förfrågningar',
          totalDocsTranslatedField: 'Totalt antal översatta dokument',
          userColumn: 'Användare',
          numberOfWordsColumn: 'Antal ord',
          currentQuota: 'Aktuell kvotstatus',
          sortTableCancel: "Klicka för att avbryta sortering",
          sortTableAscending: "Klicka för att sortera stigande",
          sortTableDescending: "Klicka för att sortera fallande",
          af: 'Afrikaans',
          am: 'Amhariska',
          ar: 'Arabiska',
          "oc-ES": 'Aranesiska',
          auto: 'Välj språk',
          eu: 'Baskiska',
          bg: 'Bulgariska',
          bn: 'Bengali',
          bs: 'Bosniska',
          ca: 'Katalanska',
          co: 'Korsikanska',
          cs: 'Tjeckiska',
          cy: 'Walesiska',
          da: 'Danska',
          dv: 'Divehi',
          ee: 'Ewe',
          gl: 'Galiciska',
          de: 'Tyska',
          el: 'Grekiska',
          en: 'Engelska',
          "en-CA": 'Engelska (Kanada)',
          "en-US": 'Engelska (USA)',
          "en-GB": 'Engelska (Storbritannien)',
          es: 'Spanska',
          "es-LA": 'Spanska (Latinamerika)',
          "es-MX": 'Spanska (Mexiko)',
          "es-XM": 'Spanska (internationell)',
          "es-US": 'Spanska (USA)',
          et: 'Estniska',
          fa: 'Persiska',
          'fa-AF': 'Dari',
          fi: 'Finska',
          fo: 'Färöiska',
          tl: 'Filippinska',
          fj: 'Fijianska',
          fr: 'Franska',
          "fr-BE": 'Franska (Belgien)',
          "fr-CA": 'Franska (Kanada)',
          "fr-FR": 'Franska (Frankrike)',
          he: 'Hebreiska',
          Hans: 'Förenklad kinesiska (transliteration)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Kroatiska',
          ht: 'Haitisk kreol',
          hu: 'Ungerska',
          hy: 'Armeniska',
          id: 'Indonesiska',
          is: 'Isländska',
          it: 'Italienska',
          iu: 'Inuktitut',
          'iu-Latn': 'Inuktitut (Latin)',
          ja: 'Japanska',
          Jpan: 'Japanska (transliteration)',
          km: 'Khmer',
          ko: 'Koreanska',
          ka: 'Georgiska',
          ks: 'Kashmiri',
          "lb-LU": 'Luxemburgska',
          lo: 'Lao',
          lt: 'Litauiska',
          lv: 'Lettiska',
          mg: 'Malagassiska',
          mk: 'Makedonska',
          mn: 'Mongoliska',
          ms: 'Malajiska',
          mt: 'Maltesiska',
          my: 'Burmesiska',
          multi: 'Flerspråkig',
          mww: 'Hmong daw',
          ne: 'Nepalesiska',
          no: 'Norska',
          nl: 'Nederländska',
          "nl-BE": 'Nederländska (Belgien)',
          ns: 'Sepedi',
          oc: 'Ockitant',
          otq: 'Querétaro otomi',
          pl: 'Polska',
          pt: 'Portugisiska',
          "pt-PT": 'Portugisiska (Portugal)',
          "pt-BR": 'Portugisiska (Brasilien)',
          ro: 'Rumänska',
          rw: 'Rwanda',
          ru: 'Ryska',
          sk: 'Slovakiska',
          sl: 'Slovenska',
          si: 'Singalesiska',
          sm: 'Samoanska',
          sr: 'Serbiska',
          "sr-RS-CYRL": 'Serbiska (kyrillisk)',
          "sr-RS-LATN": 'Serbiska (latinsk)',
          sv: 'Svenska',
          sw: 'Swahili',
          ta: 'Tamil',
          th: 'Thailändska',
          ti: 'Tigrinska',
          to: 'Tonganska',
          tn: 'Setswana',
          tr: 'Turkiska',
          ty: 'Tahitiska',
          tw: 'Twi',
          uk: 'Ukrainska',
          ur: 'Urdu',
          vi: 'Vietnamesiska',
          yua: 'Yucatec maya',
          yue: 'Kantonesiska',
          sq: 'Albanska',
          az: 'Azerbajdzjanska',
          be: 'Belarusiska',
          eo: 'Esperanto',
          gd: 'Gaeliska (Skottland)',
          ga: 'Gaeliska (Irland)',
          kn: 'Kannada',
          gu: 'Gujarati',
          ha: 'Hausa',
          la: 'Latin',
          mr: 'Marathi',
          tg: 'Tadzjikiska',
          te: 'Telugu',
          uz: 'Uzbekiska',
          xh: 'Xhosa',
          yi: 'Jiddisch',
          zu: 'Zulu',
          cb: 'Cebuano',
          fy: 'Frisiska',
          hw: 'Hawaiiska',
          ig: 'Igbo',
          jv: 'Javanesiska',
          kk: 'Kazakiska',
          kmr: 'Kurdish (norra)',
          ku: 'Kurdiska',
          ky: 'Kirgiziska',
          ml: 'Malayalam',
          mi: 'Maori',
          ny: 'Nyanja',
          or: 'Odia',
          ps: 'Pashto',
          pa: 'Punjabi',
          rn: 'Rundi',
          st: 'Sesotho',
          sn: 'Shona',
          sd: 'Sindhi',
          so: 'Somaliska',
          su: 'Sundanesiska',
          tt: 'Tatar',
          tk: 'Turkmeniska',
          ts:'Tsonga',
          ug: 'Uiguriska',
          yo: 'Yoruba',
          "zh-CN": 'Kinesiska (förenklad)',
          "zh-TW": 'Kinesiska (traditionell)',
          "zh-HK": 'Kinesiska (Hongkong)'
        }
      },
      'da' : {
        translations : {
          textOk: 'Ok',
          FAQ: 'Hjælp',
          English: 'Engelsk',
          Catalan: 'Catalansk',
          Spanish: 'Spansk',
          French: 'Fransk',
          textTitle: 'Side til oversættelse af tekst',
          documentTitle: 'Side til oversættelse af dokument',
          loginTitle: 'Log ind',
          statisticTitle: 'Statistikside',
          textAreaAnnouncementBox1: 'tekstområde, hvor du kan skrive tekst, der skal oversættes',
          textAreaAnnouncementBox2: 'tekstområde, hvor tekstoversættelsen vises, når oversættelsen er færdig.',
          AnnounceModalCloseButton: 'Luk',
          AnnounceDialogOpened: 'Dialogboks er åben',
          AnnounceDialogClosed: 'Dialogboks er lukket',
          AnnounceTextisCleared: 'Tekst er ryddet',
          AnnounceCopyTransactionRecordText: 'Kopier transaktionspost',
          AnnounceCopiedToClipboard: 'Kopieret til udklipsholder',
          AnnounceCopyTranslatedText: 'Kopiér oversat tekst',
          ClearTextLabelandTippy: 'Ryd tekst',
          AnnounceTranslatedText: 'Oversat tekst',
          headerH1: 'Maskinoversættelsestjeneste',
          headerLogout: 'Log ud',
          headerAccountSettings: 'Kontoindstillinger',
          headerVersion: 'Version',
          dropdownWebsiteLanguage: 'indstil sprog på website',
          dropdownUserSetting: 'brugerindstilling',
          dropdownsourceLang: 'Kildesprog',
          dropdowntargetLang: 'Målsprog',
          operations: 'Oprindelse',
          allOperations: 'Alle',
          textTranslateOperations: 'Tekstoversættelse',
          documentTranslateOperations: 'Dokumentoversættelse',
          speechDocumentOperations: 'Tale til tekst',
          transliteration: 'Overførsel',
          websiteTranslateOperations: 'Oversættelse af website',
          speechTranscription: 'Transskription af tale',
          words: 'Ord',
          characters: 'Tegn',
          scale: 'Skala',
          total: 'I alt',
          linearScale: 'Lineær',
          sqrtScale: 'Kvadratrod',
          dropdownSelected: 'er valgt',
          sidebarTippyText: 'Tekstoversætter',
          sidebarText: 'Tekst',
          sidebarDocument: 'Dokument',
          sidebarTippyDocument: 'Dokumentoversætter',
          loginh1: 'Log ind påkrævet',
          loginButton: 'Log ind',
          textH2NavigateLanguageTranslationDropDown: 'Vælg kilde- og målsprog',
          textTippySwap: 'Skift sprog',
          textTippySwapBlocked: 'Kan ikke skifte \'Registrér sprog\'',
          textTippySwapBlockedMulti: 'Kan ikke bytte om på "flersproget"',
          textH2NavigateTextTranslationArea: 'Tekstoversættelsesområde',
          textSourceTextareaPlaceholder: 'Indtast, eller indsæt din tekst her ...',
          textSourceTextareaButton: 'Vælg filer',
          textTranslateButton: 'Oversæt',
          textCopyTippy: 'Kopiér tekst',
          documentH1: 'Dokumentoversættelse',
          documentDroph2: 'Træk, og slip filer eller',
          documentDrophMaxSize: 'Maks. filstørrelse <strong>50MB</strong>, ZIP-filer op til <strong>512MB</strong>',
          dragAndDropTooltip: 'Træk og slip',
          documentDrophSelect: 'vælg dem på din computer',
          documentDropFileMessage: 'Understøttede filformater: ',
          documentH2: 'Oversættelser',
          documentSubHeaderDate: 'Dato',
          documentSubHeaderSubName: 'Navn',
          documentSubHeaderSource: 'Sprog',
          documentSubHeaderTarget: 'Målsprog',
          documentSubHeaderFiles: 'Download',
          documentSubHeaderPages: 'Slet',
          submissionNameHeader: "Indsendelsesnavn",
          submissionNameMaxLength: 'Indsendelsesnavn kan indeholde op til 180 tegn',
          fileNameHeader: "Filnavn",
          categoryHeader: "Kategori",
          categoryPlaceholder: "Ingen kategori",
          submissionNamePlaceholder: "Indtast her ...",
          fileStatusSuccessful: "Oversat",
          downloadFailedError: "Filen kan ikke hentes, fordi oversættelsen mislykkedes.",
          downloadingStatisticsMessage: "Oprettelse af din rapport kan tage et par minutter...",
          downloadingStatisticsError: "Vi fandt en fejl under oprettelse af din rapport. Prøv igen, eller kontakt support.",
          documentSubHeaderActions: "Handlinger",
          documentSubHeaderWCount: "Antal ord:",
          documentSubHeaderCCount: "Antal tegn:",
          documentSubHeaderStatus: 'Status',
          documentSubRowStatusDeleting: 'Sletter ...',
          documentSubRowChildStatusTranslating: 'Oversætter',
          documentSubRowChildStatusTranslated: 'Oversættelse gennemført',
          documentSubRowChildStatusTranslatedSuccess: 'Oversat',
          documentSubRowChildStatusError: 'Oversættelse mislykkedes',
          documentSubRowChildStatusFailed: 'Mislykkedes',
          documentSubRowChildStatusUploading: 'Uploader',
          documentSubRowChildDeleteTippy: 'Slet fil',
          documentFileIconTippy: 'Fil',
          documentSubRowChildDownloadTippy: 'Download oversat fil',
          documentSubRowChildDownloadSourceTippy: 'Hent kildefil',
          documentSubRowChildDownloadHeader: 'Download',
          documentSubRowChildDownloadLoadingTippy: "Indlæser hentning",
          documentSubRowChildCopyToClipboardTippy: "Kopiér til udklipsholder",
          documentSubRowChildCopiedToClipboardTippy: "Kopieret til udklipsholder",
          documentSubRowChildFailedTranslationHelpTippy: "Hjælp til mislykket oversættelse",
          documentSubRowChildFailedTranslationHelpCloseTippy: "Luk hjælp til mislykket oversættelse",
          documentSubRowChildDownloadSourceDropdown: "Hent som kildeformat",
          documentSubRowChildDownloadMoreOptions: 'Flere muligheder for download',
          documentSubRowChildExpand: 'Udvid',
          documentSubRowChildCollapse: 'Skjul',
          documentSubRowZipping: 'Zipping',
          documentSubRowZipAndDownload: 'Lyn og download',
          documentSubRowZipHelp: 'Lynlåshjælp',
          documentSubRowDownloadZip: 'Download zip',
          documentSubRowDownloadZipError: 'Fejl ved lukning af zipping',
          documentSubRowChildHumanTranslationRequestedTippy: "Menneskelig oversættelse anmodet om",
          documentSubRowChildHumanTranslationFailedTippy: "Anmodning om menneskelig oversættelse mislykkedes",
          documentSubRowChildRequestHumanTranslationTippy: "Anmod om menneskelig oversættelse",
          documentSubRowChildCloseRequestHumanTranslationTippy: "Luk menu for menneskelig oversættelse",
          documentSubRowChildRequestHumanTranslationLoadingTippy: "Indlæser anmodning",
          documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Åbner GL Portal i en ny fane",
          documentSubRowChildHumanTranslationRequestInProcess: "Anmodning om menneskelig oversættelse i gang.",
          documentSubRowChildHumanTranslationRequestFinishOnTransport: "Afslut på GL Portal",
          documentSubRowChildHumanTranslationRequest: "Anmod om en menneskelig oversættelse",
          documentSubRowChildHumanTranslationRequested: "Menneskelig oversættelse anmodet om",
          documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Tjek status på GL Portal",
          documentSubRowChildHumanTranslationRequestGoToTransport: "Gå til TransPort",
          documentSubRowChildHumanTranslationRequestAdditionalCost: " (yderligere omkostninger)",
          documentSubRowChildEditTranslatedFile: 'Redigér oversat fil',
          documentSubRowChildMoreActionsTooltip: 'Flere handlinger',
          helpSentencePartOne: 'For hjælp, klik ',
          helpSentencePartTwo: ' her ',
          helpSentencePartTwoHover: 'Dette åbner en ny e-mailmeddelelse til Support',
          helpSentencePartThree: ' Kontakt support. Beskriv dit problem i brødteksten i din e-mail.',
          documentSubRowChildDownloadPdfDropdown: 'Download som pdf',
          documentSubRowChildLanguageTo: 'til',
          documentTippyAdd: 'Vælg filer',
          deleteModalTitle: 'Slet fil',
          deleteModalCheck: 'Er du sikker på, at du vil slette denne fil?',
          deleteModalCancel: 'Annuller',
          deleteModalDelete: 'Slet',
          modalH1: 'Upload dine filer',
          modalH1AfterUpload: 'Filer uploadet',
          modalPTop: 'Du valgte følgende filer, der skal oversættes:',
          modalTippyAdd: 'Tilføj flere filer',
          modalConfirm: 'Bekræft kilde- og målsprog',
          modalTippySwap: 'Skift sprog',
          modalTranslate: 'Oversæt',
          modalLoading: 'Indlæser ...',
          modalUploadSuccessful: 'Oversættelse i gang',
          processingRequestMsg: 'Behandler anmodning ...',


          modalUploadErrorMessagePartOne:'Vi fandt en fejl ved upload af dine filer.',
          modalUploadErrorMessagePartTwo:'Prøv igen.',
          modalUploadErrorMessagePartThree:'Hvis problemet fortsætter ',
          modalUploadErrorMessagePartFour: 'Kontakt support.',
          modalMsgEmlAutoDetectError: 'Vælg originalsproget i din e-mail på "Kildesprog" for at oversætte den.',
          modalPdfAutodetectOcrError: 'Vælg originalsproget for dine billeder eller PDF\'er på "Kildesprog" for at oversætte dem.',
          mandatoryCategoryAndSubmissionNameError: 'Vælg en kategori og et indsendelsesnavn for at fortsætte med din oversættelse.',
          mandatorySubmissionNameError: 'Vælg venligst et indsendelsesnavn for at fortsætte med din oversættelse.',
          mandatoryCategoryError: 'Vælg en kategori for at fortsætte med din oversættelse.',
          deleteModalProblem: 'Vi har problemer med at slette din fil. Luk denne modal, og prøv igen.',
          unsupportedFileMessage: 'Vi kan ikke oversætte en eller flere filer. Konverter dem, og prøv igen.',
          statisticErrorTime: 'Startdatoen ser ud til at være efter slutdatoen. Vælg en startdato før slutdatoen.',
          statisticErrorFutureDate: 'Startdato ser ud til at være i fremtiden. Vælg venligst en startdato før i dag.',
          unsupportedSourceLang: 'Det registrerede sprog er ikke tilgængeligt i din organisation. Kontakt den person, der administrerer portalen i din organisation, for at få flere sprog.',
          reachedQuotaLimitError: 'Din organisation har nået sine grænser for oversættelser. Kontakt den person, der administrerer portalen i din organisation, for at udvide disse grænser.',
          orgExpiredError: 'Du kan ikke oversætte, fordi din organisation har nået sin udløbsdato. Kontakt den person, der administrerer portalen i din organisation, for at udvide disse grænser.',
          textTranslationError: 'Oversættelse mislykkedes. Support er tilgængelig for hjælp.',
          statisticRequestError:'Vi fandt en fejl under oprettelse af din rapport. Prøv igen.',
          downloadPdfError: 'Vi kan ikke konvertere det uploadede filformat til pdf.',
          noDataError: 'Vi kunne ikke finde nogen oversættelser mellem disse datoer. Hvis du mener, at det er en fejl, er der support til rådighed.',
          'AP-12-001': 'Filen ser ud til at være tom, tjek venligst og prøv igen.',
          'AP-12-001-desc': 'Filen ser ud til at være tom, tjek venligst og prøv igen.',
          'AP-12-001-desc-pdf':'Der blev ikke fundet nogen tekst. Prøv igen, og sørg for, at OCR er aktiveret.',
          'AP-01-003-desc': 'Filen overskrider ordgrænserne for din organisation. Del den op i mindre stykker, og prøv igen.',
          'WFM-04-SRCTRGSAME': 'SKilde- og målsprog kan ikke være det samme. Venligst rediger en af dem',
          'WFM-04-SRCTRGSAME-desc':'Kilde- og målsprog kan ikke være det samme. Venligst rediger en af dem',
          'USRMNG-DB-1062': 'Denne bruger findes allerede. Søg efter det, og foretag de tilsvarende ændringer.',
          'STT-DOC-04-SIZE': 'Den maksimale filstørrelse er 200MB. Del din fil, og prøv igen.',
          'STT-fileSIZE': 'Du kan oversætte filer op til 50MB. Fjern større filer, inddel dem i mindre filer, og prøv igen.',
          'STT-fileSIZE-zip': 'Du kan oversætte zip filer op til 512MB. Fjern større filer, inddel dem i mindre filer, og prøv igen.',
          TT_WAV_ERROR_MULTIPLE_FILES: 'Upload en wav-fil ad gangen, eller prøv at oversætte dokumentet for at oversætte det',
          TT_WAV_ERROR_AUTO_DETECT:'Vælg originalsproget for dine wav-filer på "Kildesprog" for at oversætte dem.',
          TT_WAV_ERROR_FILE_TYPE:'Brug dokumentoversættelse til at oversætte denne type fil',
          TT_WAV_ERROR_FILE_SIZE: 'Den maksimale filstørrelse er 200MB. Del din fil, og prøv igen',
          'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Oversæt PDF- og wav-filer separat',
          'WFM-error': 'Vi fandt en fejl under behandlingen af din fil. Prøv igen',
          'WFM-error-desc': 'Vi fandt en fejl under behandlingen af din fil. Prøv igen',
          'WFM-04-INVLANG': 'Det registrerede sprog er ikke tilgængeligt i din organisation. Kontakt den person, der administrerer portalen i din organisation, for at få flere sprog.',
          'WFM-04-INVLANG-desc': 'Det registrerede sprog er ikke tilgængeligt i din organisation. Kontakt den person, der administrerer portalen i din organisation, for at få flere sprog.',
          'OCR-03-A2A-FAIL': 'Vi fandt en fejl ved oversættelsen af din fil. Prøv igen',
          'OCR-03-A2A-TIMEOUT': 'Vi fandt en fejl ved oversættelsen af din fil. Prøv igen',


          copyright: ' Translations.com Alle rettigheder forbeholdes.',
          statistics: 'Statistik',
          statisticFrom: 'Fra:',
          statisticTo: 'Til:',
          statisticDownload: 'Download rapport',
          textTranslationReachedLimit: 'Du kan oversætte op til 10.000 tegn pr. anmodning',
          users: 'Bruger',
          allUsers: 'Alle',
          allLanguages: 'Alle',
          loadTimeFrame: 'Tidsramme for indlæsning',
          "OCR-03-A2A-PASSWORD": 'Fejl. Fjern adgangskode fra pdf',
          "OCR-03-A2A-PASSWORD-desc-pdf": 'PDF er beskyttet med adgangskode. Fjern adgangskode fra PDF',
          "AP-08-001": 'Fejl. Fjern adgangskode fra pdf',
          "AP-08-001-desc-pdf": 'PDF er beskyttet med adgangskode. Fjern adgangskode fra PDF',
          "OCR-03-A2A-SIZE-EX": 'Den maksimale sidestørrelse til at udføre PDF-konvertering er A4',
          quickLinksLabel: 'Hurtiglink:',
          "OCR-03-A2A-HEALTH": 'OCR er midlertidigt utilgængelig, prøv igen',
          "MT-error": 'Fejl i oversættelsesprogrammet',
          "MT-error-desc": 'Fejl i oversættelsesprogrammet',
          "USRMNG-DB-1452": 'Ugyldig værdi',
          TT_WAV_ERROR_NO_FILES_DETECTED: 'Vi kunne ikke finde nogen WAV-filer',
          TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: 'Transskription er stadig i gang. Transskriptionen mistes, hvis du forlader denne side nu.',
          qualityTranslationEngineInfo: "TransPerfect Main Engine giver dig mulighed for at blive informeret om det niveau af nøjagtighed, vores oversættelse har i henhold til dine krav. Test det!",
          infoQualityControlFeature: "Kvalitetskontrol er den funktion, der giver dig mulighed for at blive informeret om det niveau af nøjagtighed, vores oversættelse har i henhold til dine behov. Troskabsniveauet defineres som nøjagtigheden af den oversættelse, vi leverer til dig, som angivet i dine krav.",
          transliterationTippy: 'Translitterer tekst',
          infoQualityControlFeatureTooltip: 'For at sikre kvaliteten og gennemsigtigheden af vores produkt giver TransPerfect Main Engine dig mulighed for at blive informeret om det niveau af troværdighed, vores oversættelse har efter dine behov.',
          infoQualityControlFeatureTooltipLearnMore: 'Få mere at vide',
          translationQualityGood: 'Oversættelse ser godt ud!',
          translationQualityAverage: 'Oversættelse kan have nogle fejl, brug med forsigtighed!',
          translationQualityBad: 'Oversættelse vil sandsynligvis være problematisk, revision anbefales.',
          translationQualityGoodModal: 'Godt',
          translationQualityAverageModal: 'Brug med forsigtighed',
          translationQualityBadModal: 'Likely problematic',
          translationQualityGoodModalDescription: 'Du kan stole på denne oversættelse.',
          translationQualityAverageModalDescription: 'Der kan være nogle mangler i oversættelsen. Vi anbefaler, at den bruges internt. Til ekstern brug anbefaler vi, at du tjekker det med en professionel oversætter.',
          translationQualityBadModalDescription: '\'Denne oversættelse kan have nogle store fejl. Vi råder dig til at rette op på det med en professionel oversætter.',
          websiteTranslator: 'Website',
          websiteTranslatorTippy: 'Websiteoversætter',
          urlInputPlaceHolder: 'Indsæt URL her ...',
          websiteTranslateAction: 'Oversæt',
          removeAllPreviousWebsiteTranslateRequests: 'Ryd alle',
          previousWebsiteTranslateRequestTitle: 'Anmodningshistorik:',
          wtLoadingCaption: 'Oversætter website ...',
          ocrCheckboxLabel: 'Jeg uploader billeder og scannede dokumenter',
          ocrHelpText: 'Hvornår skal du aktivere denne',
          ocrCheckboxTippy: 'OCR (optisk tegngenkendelse) registrerer tekst i billeder og scannede dokumenter. Prøv denne mulighed, hvis du har modtaget en tom oversættelse, eller du ikke kan vælge tekstindholdet i din PDF.',
          websitePage: 'Side til websiteoversættelse',
          lastWTRequestURL: 'Seneste anmodning om websiteoversættelse:',
          openWebsiteBtnLabel: 'Åbn',
          invalidURL: 'Ugyldigt URL-format',
          ClearURLLabelandTippy: 'Ryd URL',
          inactivityLogoutTitle: 'Du er blevet logget ud',
          inactivityLogoutMessage: 'Du er blevet logget ud på grund af inaktivitet',
          inactivityLogoutButtonLabel: 'Log ind igen',
          logoutScreenMessage: 'Tak, fordi du kiggede forbi!',
          logoutScreenLogoutButtonLabel: 'Log ind igen',
          langSelect_NoResultsMessage: 'Ingen resultater fundet',
          reachedQuotaLimitErrorPartOne: 'Du kan ikke oversætte, fordi din organisation har nået sine oversættelsesgrænser.',
          reachedQuotaLimitErrorPartTwo: 'Kontakt den person, der administrerer portalen i din organisation, for at udvide dette.',
          orgExpiredErrorPartOne: 'Du kan ikke oversætte, fordi din organisation har nået sin udløbsdato.',
          orgExpiredErrorPartTwo: 'Kontakt den person, der administrerer portalen i din organisation, for at udvide disse grænser.',
          menuLabel: 'Menu',
          downloadSourceFileSubheader: 'Kilde',
          downloadTranslatedFileSubheader: 'Resultat',
          dataView: 'Data',
          graphView: 'Graf',
          mainTitleStatisticsDataView: 'Organisationsstatistikker for valgt periode',
          genericColumnTitleStatisticsDataView: 'Generisk',
          textTranslationTitleStatisticsDataView: 'Tekstoversættelse',
          documentTranslationTitleStatisticsDataView: 'Dokumentoversættelse',
          wordsTranslatedField: 'Ord oversat',
          charsTranslatedField: 'Oversatte tegn',
          ratioTextDocumentField: 'Tekstoversættelse/dokumentoversættelsesforhold',
          mostUsedLangsField: 'Mest anvendte sprogpar',
          totalTextRequestsField: 'Anmodninger i alt',
          totalDocsTranslatedField: 'Samlet antal dokumenter oversat',
          userColumn: 'Bruger',
          numberOfWordsColumn: 'Antal ord',
          currentQuota: 'Aktuel kvotestatus',
          sortTableCancel: "Klik for at annullere sortering",
          sortTableAscending: "Klik for at sortere stigende",
          sortTableDescending: "Klik for at sortere faldende",
          af: 'Afrikaans',
          am: 'Amharisk',
          ar: 'Arabisk',
          "oc-ES": 'Aranese',
          auto: 'Find sprog',
          eu: 'Baskisk',
          bg: 'Bulgarsk',
          bn: 'Bangla',
          bs: 'Bosnisk',
          ca: 'Katalansk',
          co: 'Korsikansk',
          cs: 'Tjekkisk',
          cy: 'Walisisk',
          da: 'Dansk',
          dv: 'Divehi',
          ee: 'Ewe',
          gl: 'Galicisk',
          de: 'Tysk',
          el: 'Græsk',
          en: 'Engelsk',
          "en-CA": 'Engelsk (Canada)',
          "en-US": 'Engelsk (USA)',
          "en-GB": 'Engelsk (Storbritannien)',
          es: 'Spansk',
          "es-LA": 'Spansk (Latinamerika)',
          "es-MX": 'Spansk (Mexico)',
          "es-XM": 'Spansk (International)',
          "es-US": 'Spansk (USA)',
          et: 'Estisk',
          fa: 'Persisk',
          'fa-AF': 'Dari',
          fi: 'Finsk',
          fo: 'Færøerne',
          tl: 'Filippinsk',
          fj: 'Fijansk',
          fr: 'Fransk',
          "fr-BE": 'Fransk (Belgien)',
          "fr-CA": 'Fransk (Canada)',
          "fr-FR": 'Fransk (Frankrig)',
          he: 'Hebraisk',
          Hans: 'Kinesisk simplificeret (transliteration)',
          hi: 'Hindi',
          hm: 'Hmong Daw',
          hr: 'Kroatisk',
          ht: 'Haitiansk kreolsk',
          hu: 'Ungarsk',
          hy: 'Armensk',
          id: 'Indonesisk',
          is: 'Islandsk',
          it: 'Italiensk',
          iu: 'Inuktitut',
          'iu-Latn': 'Inuktitut (Latin)',
          ja: 'Japansk',
          Jpan: 'Japansk (translitteration)',
          km: 'Khmer',
          ko: 'Koreansk',
          ka: 'Georgisk',
          ks: 'Kashmiri',
          "lb-LU": 'Luxembourgsk',
          lo: 'Lao',
          lt: 'Litauisk',
          lv: 'Lettisk',
          mg: 'Madagaskisk',
          mk: 'Makedonsk',
          mn: 'Mongolsk',
          ms: 'Malajisk',
          mt: 'Maltesisk',
          my: 'Burmesisk',
          multi: 'Flersproget',
          mww: 'Hmong Daw',
          ne: 'Nepalesisk',
          no: 'Norsk',
          nl: 'Hollandsk',
          "nl-BE": 'Hollandsk (Belgien)',
          ns: 'Sepedi',
          oc: 'Occitansk',
          otq: 'Querétaro Otomi',
          pl: 'Polsk',
          pt: 'Portugisisk',
          "pt-PT": 'Portugisisk (Portugal)',
          "pt-BR": 'Portugisisk (Brasilien)',
          ro: 'Rumænsk',
          rw: 'Kinyarwanda',
          ru: 'Russisk',
          sk: 'Slovakisk',
          sl: 'Slovensk',
          si: 'Sinhala',
          sm: 'Samoansk',
          sr: 'Serbisk',
          "sr-RS-CYRL": 'Serbisk (Kyrillisk)',
          "sr-RS-LATN": 'Serbisk (Latin)',
          sv: 'Svensk',
          sw: 'Kiswahili',
          ta: 'Tamilsk',
          th: 'Thailandsk',
          ti: 'Tigrinya',
          to: 'Tongansk',
          tr: 'Tyrkisk',
          tn: 'Setswana',
          ty: 'Tahitiansk',
          tw: 'Twi',
          uk: 'Ukrainsk',
          ur: 'Urdu',
          vi: 'Vietnamesisk',
          yua: 'Yucatec Maya',
          yue: 'Kantonesisk',
          sq: 'Albansk',
          az: 'Aserbajdsjansk',
          be: 'Hviderussisk',
          eo: 'Esperanto',
          gd: 'Gælisk (Skotland)',
          ga: 'Gælisk (Irland)',
          kn: 'Kannada',
          gu: 'Gujarat',
          ha: 'Hausa',
          la: 'Latin',
          mr: 'Marathi',
          tg: 'Tadjikisk',
          te: 'Telugu',
          uz: 'Usbekisk',
          xh: 'Xhosa',
          yi: 'Jiddisch',
          zu: 'Zulu',
          cb: 'Cebuano',
          fy: 'Frisisk',
          hw: 'Hawaiiansk',
          ig: 'Igbo',
          jv: 'Javanesisk',
          kk: 'Kasakhisk',
          kmr: 'Kurdansk (nord)',
          ku: 'Kurdisk',
          ky: 'Kirgisisk',
          ml: 'Malayalam',
          mi: 'Maori',
          ny: 'Nyanja',
          or: 'Odia',
          ps: 'Pashto',
          pa: 'Punjabi',
          rn: 'Rundi',
          st: 'Sesotho',
          sn: 'Shona',
          sd: 'Sindhi',
          so: 'Somali',
          su: 'Sundanesisk',
          tt: 'Tatarisk',
          tk: 'Turkmensk',
          ts:'Tsonga',
          ug: 'Uygurisk',
          yo: 'Yoruba',
          "zh-CN": 'Kinesisk (Forenklet)',
          "zh-TW": 'Kinesisk (Traditionelt)',
          "zh-HK": 'Kinesisk (Hong Kong)'
        }
      },
      'fi' : { translations :
            {
              textOk: 'OK',
              FAQ: 'Ohje',
              English: 'englanti',
              Catalan: 'katalaani',
              Spanish: 'espanja',
              French: 'ranska',
              textTitle: 'Tekstikäännössivu',
              documentTitle: 'Asiakirjakäännössivu',
              loginTitle: 'Kirjaudu sisään',
              statisticTitle: 'Tilastosivu',
              textAreaAnnouncementBox1: 'tekstialue, johon voit kirjoittaa käännettävän tekstin',
              textAreaAnnouncementBox2: 'tekstialue, johon tekstikäännös tulee näkyviin, kun käännös on tehty.',
              AnnounceModalCloseButton: 'Sulje',
              AnnounceDialogOpened: 'Valintaikkuna avautuu',
              AnnounceDialogClosed: 'Valintaikkuna sulkeutuu',
              AnnounceTextisCleared: 'Teksti tyhjennetään',
              AnnounceCopyTransactionRecordText: 'Kopier transaktionspost',
              AnnounceCopiedToClipboard: 'Kopioi tapahtumatietue',
              AnnounceCopyTranslatedText: 'Kopioi käännetty teksti',
              ClearTextLabelandTippy: 'Tyhjennä teksti',
              AnnounceTranslatedText: 'Käännetty teksti',
              headerH1: 'Konekäännöspalvelu',
              headerLogout: 'Kirjaudu ulos',
              headerAccountSettings: 'Tilin asetukset',
              headerVersion: 'Versio',
              dropdownWebsiteLanguage: 'aseta verkkosivuston kieli',
              dropdownUserSetting: 'käyttäjäasetus',
              dropdownsourceLang: 'Lähtökieli',
              dropdowntargetLang: 'Kohdekieli',
              operations: 'Alkuperä',
              allOperations: 'Kaikki',
              textTranslateOperations: 'Tekstikäännös',
              documentTranslateOperations: 'Asiakirjakäännös',
              speechDocumentOperations: 'Puhe tekstiksi',
              transliteration: 'Transliteraatio',
              websiteTranslateOperations: 'Verkkosivuston käännös',
              speechTranscription: 'Puheen puhtaaksikirjoitus',
              words: 'Sanat',
              characters: 'Merkit',
              scale: 'Asteikko',
              total: 'Yhteensä',
              linearScale: 'Lineaarinen',
              sqrtScale: 'Neliöjuuri',
              dropdownSelected: 'on valittu',
              sidebarTippyText: 'Tekstin kääntäjä',
              sidebarText: 'Teksti',
              sidebarDocument: 'Asiakirja',
              sidebarTippyDocument: 'Asiakirjan kääntäjä',
              loginh1: 'Sisäänkirjautuminen vaaditaan',
              loginButton: 'Kirjaudu sisään',
              textH2NavigateLanguageTranslationDropDown: 'Valitse lähtö- ja kohdekielet',
              textTippySwap: 'Vaihda kielet',
              textTippySwapBlocked: '”Kielen tunnistusta” ei voi vaihtaa ',
              textTippySwapBlockedMulti: 'Ei voi vaihtaa "monimuotoista"',
              textH2NavigateTextTranslationArea: 'Tekstikäännösalue',
              textSourceTextareaPlaceholder: 'Kirjoita tai liitä teksti tähän...',
              textSourceTextareaButton: 'Valitse tiedostot',
              textTranslateButton: 'Käännä',
              textCopyTippy: 'Kopioi teksti',
              documentH1: 'Asiakirjakäännös',
              documentDroph2: 'Vedä ja pudota tiedostoja tai',
              documentDrophMaxSize: 'Tiedoston enimmäiskoko <strong>50MB</strong>, ZIP-tiedostot enintään <strong>512MB</strong>',
              dragAndDropTooltip: 'Vedä ja pudota',
              documentDrophSelect: 'valitse ne tietokoneellasi',
              documentDropFileMessage: 'Tuetut tiedostomuodot: ',
              documentH2: 'Käännökset',
              documentSubHeaderDate: 'Päivämäärä',
              documentSubHeaderSubName: 'Nimi',
              documentSubHeaderSource: 'Kielet',
              documentSubHeaderTarget: 'Kohdekieli',
              documentSubHeaderFiles: 'Lataa',
              documentSubHeaderPages: 'Poista',
              submissionNameHeader: "Lähetyksen nimi",
              submissionNameMaxLength: 'Lähetyksen nimessä voi olla enintään 180 merkkiä',
              fileNameHeader: "Tiedoston nimi",
              categoryHeader: "Kategoria",
              categoryPlaceholder: "Ei kategoriaa",
              submissionNamePlaceholder: "Kirjoita tähän...",
              fileStatusSuccessful: "Käännetty",
              downloadFailedError: "Tiedostoa ei voi ladata, koska käännös epäonnistui.",
              downloadingStatisticsMessage: "Raporttia luodaan, tämä voi kestää muutaman minuutin...",
              downloadingStatisticsError: "Havaitsimme virheen raporttisi luomisessa. Yritä uudelleen tai ota yhteyttä tukeen.",
              documentSubHeaderActions: "Toimenpiteet",
              documentSubHeaderWCount: "Sanamäärä:",
              documentSubHeaderCCount: "Merkkimäärä:",
              documentSubHeaderStatus: 'Tila',
              documentSubRowStatusDeleting: 'Poistetaan...',
              documentSubRowChildStatusTranslating: 'Käännetään',
              documentSubRowChildStatusTranslated: 'Käännös onnistui',
              documentSubRowChildStatusTranslatedSuccess: 'Käännetty',
              documentSubRowChildStatusError: 'Käännös epäonnistui',
              documentSubRowChildStatusFailed: 'Epäonnistui',
              documentSubRowChildStatusUploading: 'Ladataan',
              documentSubRowChildDeleteTippy: 'Poista tiedosto',
              documentFileIconTippy: 'Tiedosto',
              documentSubRowChildDownloadTippy: 'Lataa käännetty tiedosto',
              documentSubRowChildDownloadSourceTippy: 'Lataa lähtötiedosto',
              documentSubRowChildDownloadHeader: 'Lataa',
              documentSubRowChildDownloadLoadingTippy: "Ladataan latausta",
              documentSubRowChildCopyToClipboardTippy: "Kopioi leikepöydälle",
              documentSubRowChildCopiedToClipboardTippy: "Kopioitu leikepöydälle",
              documentSubRowChildFailedTranslationHelpTippy: "Tietoa epäonnistuneesta käännöksestä",
              documentSubRowChildFailedTranslationHelpCloseTippy: "Sulje epäonnistuneen käännöksen tietoikkuna",
              documentSubRowChildDownloadSourceDropdown: "Lataa lähdetiedostomuodossa",
              documentSubRowChildDownloadMoreOptions: 'Lisää latausvaihtoehtoja',
              documentSubRowChildExpand: 'Laajenna',
              documentSubRowChildCollapse: 'Supista',
              documentSubRowZipping: 'Zipping',
              documentSubRowZipAndDownload: 'Postinumero ja lataus',
              documentSubRowZipHelp: 'Postinumero-ohje',
              documentSubRowDownloadZip: 'Lataa postinumero',
              documentSubRowDownloadZipError: 'Virhe pakattaessa',
              documentSubRowChildHumanTranslationRequestedTippy: "Ihmisen tekemä käännös pyydetty",
              documentSubRowChildHumanTranslationFailedTippy: "Ihmisen tekemän käännöksen pyyntö epäonnistui",
              documentSubRowChildRequestHumanTranslationTippy: "Pyydä ihmisen tekemä käännös",
              documentSubRowChildCloseRequestHumanTranslationTippy: "Sulje ihmisen tekemän käännöksen valikko",
              documentSubRowChildRequestHumanTranslationLoadingTippy: "Ladataan pyyntöä",
              documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Avaa GL Portal-sovelluksen uuteen välilehteen",
              documentSubRowChildHumanTranslationRequestInProcess: "Ihmisen tekemän käännöksen pyyntöä käsitellään.",
              documentSubRowChildHumanTranslationRequestFinishOnTransport: "Lopeta GL Portal-sovelluksessa",
              documentSubRowChildHumanTranslationRequest: "Pyydä ihmisen tekemä käännös",
              documentSubRowChildHumanTranslationRequested: "Ihmisen tekemä käännös pyydetty",
              documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Tarkista tila GL Portal-sovelluksessa",
              documentSubRowChildHumanTranslationRequestGoToTransport: "Siirry TransPort-sovellukseen",
              documentSubRowChildHumanTranslationRequestAdditionalCost: " (lisämaksu)",
              documentSubRowChildEditTranslatedFile: 'Muokkaa käännettyä tiedostoa',
              documentSubRowChildMoreActionsTooltip: 'Lisää toimintoja',
              helpSentencePartOne: 'Ohjeen saa napsauttamalla ',
              helpSentencePartTwo: 'tässä',
              helpSentencePartTwoHover: 'Tämä avaa uuden sähköpostiviestin tukipalveluun',
              helpSentencePartThree: ' ota yhteyttä tukeen. Kuvaile ongelmaasi sähköpostisi tekstissä.',
              documentSubRowChildDownloadPdfDropdown: 'Lataa pdf-tiedostona',
              documentSubRowChildLanguageTo: 'kohteeseen',
              documentTippyAdd: 'Valitse tiedostot',
              deleteModalTitle: 'Poista tiedosto',
              deleteModalCheck: 'Haluatko varmasti poistaa tämän tiedoston?',
              deleteModalCancel: 'Peruuta',
              deleteModalDelete: 'Poista',
              modalH1: 'Lataa tiedostosi',
              modalH1AfterUpload: 'Tiedostot ladattu',
              modalPTop: 'Valitsit seuraavat tiedostot käännettäviksi:',
              modalTippyAdd: 'Lisää tiedostoja',
              modalConfirm: 'Vahvista lähtö- ja kohdekielet',
              modalTippySwap: 'Vaihda kielet',
              modalTranslate: 'Käännä',
              modalLoading: 'Ladataan...',
              modalUploadSuccessful: 'Käännös käynnissä',
              processingRequestMsg: 'Käsitellään pyyntöä...',

              modalUploadErrorMessagePartOne:'Löysimme virheen tiedostojen lataamisessa.\'',
              modalUploadErrorMessagePartTwo:'Yritä uudelleen.',
              modalUploadErrorMessagePartThree:'Jos ongelma jatkuu ',
              modalUploadErrorMessagePartFour: 'ota yhteyttä tukeen.',
              modalMsgEmlAutoDetectError: 'Käännä se valitsemalla sähköpostisi alkuperäinen kieli ”Lähdekielellä”. ',
              modalPdfAutodetectOcrError: 'Käännä kuvat onnistuneesti valitsemalla kuvien tai PDF-tiedostojen alkuperäinen kieli ”Lähdekielellä”. ',
              mandatoryCategoryAndSubmissionNameError: 'Valitse luokka ja lähetyksen nimi jatkaaksesi käännöstä.',
              mandatorySubmissionNameError: 'Jatka käännöstä valitsemalla lähetyksen nimi.',
              mandatoryCategoryError: 'Valitse luokka jatkaaksesi käännöstä.',
              deleteModalProblem: 'Tiedostosi poistamisessa on ongelmia. Sulje tämä modaali ja yritä uudelleen.',
              unsupportedFileMessage: 'Yhtä tai useampaa tiedostoa ei voi kääntää. Muunna ne ja yritä uudelleen.\n',
              statisticErrorTime: 'Aloituspäivä näyttää olevan päättymispäivän jälkeen. Valitse alkamispäivä ennen päättymispäivää.',
              statisticErrorFutureDate: 'Aloituspäivä näyttää olevan tulevaisuudessa. Valitse aloituspäivä ennen tätä päivää.',
              unsupportedSourceLang: 'Havaittu kieli ei ole käytettävissä organisaatiossasi. Jos haluat lisää kieliä, ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön.',
              reachedQuotaLimitError: 'Organisaatiosi saavutti käännösrajansa. Ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön näiden rajojen laajentamiseksi.',
              orgExpiredError: 'Et voi kääntää, koska organisaatiosi on vanhentunut. Ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön näiden rajojen laajentamiseksi.',
              textTranslationError: 'Käännös epäonnistui. Tukea on saatavilla.',
              statisticRequestError:'Havaitsimme virheen raporttisi luomisessa. Yritä uudelleen.',
              downloadPdfError: 'Ladattua tiedostomuotoa ei voi muuntaa PDF-muotoon.',
              noDataError: 'Näiden päivämäärien välisiä käännöksiä ei löytynyt. Jos luulet, että se on virhetukea saatavilla.',
              'AP-12-001': 'Tiedosto näyttää olevan tyhjä, tarkista ja yritä uudelleen.',
              'AP-12-001-desc': 'Tiedosto näyttää olevan tyhjä, tarkista ja yritä uudelleen.',
              'AP-12-001-desc-pdf':'Tekstiä ei havaittu. Yritä uudelleen ja varmista, että OCR on käytössä.',
              'AP-01-003-desc': 'Tiedosto ylittää organisaatiosi sanarajat. Jaa se pienempiin osiin ja yritä uudelleen.',
              'WFM-04-SRCTRGSAME': 'Lähde- ja kohdekieli eivät voi olla samat. Muokkaa yhtä niistä',
              'WFM-04-SRCTRGSAME-desc':'Lähde- ja kohdekieli eivät voi olla samat. Muokkaa yhtä niistä',
              'WFM-04-SRCTRGSAME-desc-pdf' : 'Lähde- ja kohdekieli eivät voi olla samat. Muokkaa yhtä niistä',
              'USRMNG-DB-1062': 'Tämä käyttäjä on jo olemassa. Etsi se ja tee vastaavat muutokset.',
              'STT-DOC-04-SIZE': 'Suurin aallonkoko on 200MB. Jaa tiedosto ja yritä uudelleen.',
              'STT-fileSIZE': 'Voit kääntää tiedostoja jopa 50MB:n kokoisiksi. Poista isommat tiedostot, jaa ne pienempiin tiedostoihin ja yritä uudelleen.',
              'STT-fileSIZE-zip': 'Voit kääntää jopa 512MB:n zip-tiedostoja. Poista tiedostot postinumerostasi ja yritä uudelleen',
              TT_WAV_ERROR_MULTIPLE_FILES: 'Lataa yksi aaltotiedosto kerrallaan tai yritä kääntää asiakirja',
              TT_WAV_ERROR_AUTO_DETECT:'Käännä ne onnistuneesti valitsemalla aaltotiedostojesi alkuperäinen kieli ”Lähdekielellä”.',
              TT_WAV_ERROR_FILE_TYPE:'Käännä tämän tyyppinen tiedosto käyttämällä asiakirjan käännöstä',
              TT_WAV_ERROR_FILE_SIZE: 'Tiedoston enimmäiskoko on 200MB. Jaa tiedosto ja yritä uudelleen',
              'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Käännä PDF ja wav-tiedostot erikseen',
              'WFM-error': 'Havaitsimme virheen tiedoston käsittelyssä. Yritä uudelleen',
              'WFM-error-desc': 'Havaitsimme virheen tiedoston käsittelyssä. Yritä uudelleen',
              'WFM-04-INVLANG': 'Havaittu kieli ei ole käytettävissä organisaatiossasi. Jos haluat lisää kieliä, ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön.',
              'WFM-04-INVLANG-desc': 'Havaittu kieli ei ole käytettävissä organisaatiossasi. Jos haluat lisää kieliä, ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön.',
              'OCR-03-A2A-FAIL': 'Tiedostosi kääntämisessä tapahtui virhe. Yritä uudelleen',
              'OCR-03-A2A-TIMEOUT': 'Tiedostosi kääntämisessä tapahtui virhe. Yritä uudelleen',


              copyright: ' Translations.com Kaikki oikeudet pidätetään.',
              statistics: 'Tilastot',
              statisticFrom: 'Lähettäjä:',
              statisticTo: 'Vastaanottaja:',
              statisticDownload: 'Lataa raportti',
              textTranslationReachedLimit: 'Voit kääntää enintään 10 000 merkkiä pyyntöä kohti',
              users: 'Käyttäjä',
              allUsers: 'Kaikki',
              allLanguages: 'Kaikki',
              loadTimeFrame: 'Lataa aikaväli',
              "OCR-03-A2A-PASSWORD": 'Virhe, poista salasana PDF-tiedostosta',
              "OCR-03-A2A-PASSWORD-desc-pdf": 'PDF-tiedosto on salasanasuojattu, poista salasana PDF-tiedostosta',
              "AP-08-001": 'Virhe, poista salasana PDF-tiedostosta',
              "AP-08-001-desc-pdf": 'PDF-tiedosto on salasanasuojattu, poista salasana PDF-tiedostosta',
              "OCR-03-A2A-SIZE-EX": 'PDF-muunnoksen suurin sivukoko on A4',
              quickLinksLabel: 'Pikalinkit:',
              "OCR-03-A2A-HEALTH": 'OCR ei ole tilapäisesti käytettävissä, yritä uudelleen',
              "MT-error": 'Virhe käännöskoneessa',
              "MT-error-desc": 'Virhe käännöskoneessa',
              "USRMNG-DB-1452": 'Arvo ei kelpaa',
              "TT_WAV_ERROR_NO_FILES_DETECTED": 'Emme löytäneet yhtään WAV-tiedostoa',
              TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: 'Puhtaaksikirjoitusta käsitellään edelleen. Puhtaaksikirjoitus menetetään, jos poistut tältä sivulta nyt.',
              qualityTranslationEngineInfo: "TransPerfect-päämoottori antaa sinulle tietoa käännöstemme tarkkuustasosta vaatimustesi mukaisesti. Testaa!",
              infoQualityControlFeature: "Laadunvalvonta on ominaisuus, jonka avulla saat tietoa käännöksemme tarkkuustasosta tarpeidesi mukaan. Toimittamamme käännöksen tarkkuustaso määritellään vaatimustesi mukaisesti.",
              transliterationTippy: 'Translitteroi teksti',
              infoQualityControlFeatureTooltip: 'Tuotteemme laadun ja läpinäkyvyyden varmistamiseksi TransPerfect Main Engine antaa sinulle tietoa käännöstemme tarkkuustasosta tarpeidesi mukaan.',
              infoQualityControlFeatureTooltipLearnMore: 'Lue lisää',
              translationQualityGood: 'Käännös näyttää hyvältä!',
              translationQualityAverage: 'Käännöksessä voi olla virheitä, käytä varoen!',
              translationQualityBad: 'Käännös on todennäköisesti ongelmallinen, revisiota suositellaan.',
              translationQualityGoodModal: 'Hyvä',
              translationQualityAverageModal: 'Käytä varoen',
              translationQualityBadModal: 'Tykkää ongelmallista',
              translationQualityGoodModalDescription: 'Voit luottaa tähän käännökseen.',
              translationQualityAverageModalDescription: 'Käännöksessä voi olla joitakin virheitä. Suosittelemme sen käyttöä sisäisesti. Ulkoiseen käyttöön suosittelemme, että tarkistat sen ammattimaiselta kääntäjältä.',
              translationQualityBadModalDescription: 'Tässä käännöksessä voi olla joitakin merkittäviä virheitä. Suosittelemme korjaamaan sen ammattimaisella kääntäjällä.',
              websiteTranslator: 'Verkkosivusto',
              websiteTranslatorTippy: 'Verkkosivuston kääntäjä',
              urlInputPlaceHolder: 'Liitä URL-osoitteesi tähän...',
              websiteTranslateAction: 'Käännä',
              removeAllPreviousWebsiteTranslateRequests: 'Tyhjennä kaikki',
              previousWebsiteTranslateRequestTitle: 'Pyyntöhistoria:',
              wtLoadingCaption: 'Käännetään verkkosivustoa...',
              ocrCheckboxLabel: 'Lataan kuvia ja skannattuja asiakirjoja',
              ocrHelpText: 'Milloin tämä aktivoidaan',
              ocrCheckboxTippy: 'OCR (optical character recognition, optinen tekstintunnistus) tunnistaa kuvissa ja skannatuissa asiakirjoissa olevan tekstin. Kokeile tätä vaihtoehtoa, jos olet saanut tyhjän käännöksen tai PDF-tiedoston tekstisisältöä ei voi valita.',
              websitePage: 'Verkkosivuston käännössivu',
              lastWTRequestURL: 'Viimeinen verkkosivuston käännöspyyntö:',
              openWebsiteBtnLabel: 'Avoin',
              invalidURL: 'Virheellinen URL-muoto',
              ClearURLLabelandTippy: 'Tyhjennä URL',
              inactivityLogoutTitle: 'Sinut on kirjattu ulos',
              inactivityLogoutMessage: 'Sinut on kirjattu ulos toimettomuuden vuoksi',
              inactivityLogoutButtonLabel: 'Kirjaudu takaisin sisään',
              logoutScreenMessage: 'Kiitos käynnistä!',
              logoutScreenLogoutButtonLabel: 'Kirjaudu takaisin sisään',
              langSelect_NoResultsMessage: 'Tuloksia ei löytynyt',
              reachedQuotaLimitErrorPartOne: 'Et voi kääntää, koska organisaatiosi on saavuttanut käännösrajansa.\n',
              reachedQuotaLimitErrorPartTwo: 'Ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön laajentaaksesi tätä.',
              orgExpiredErrorPartOne: 'Et voi kääntää, koska organisaatiosi on vanhentunut.',
              orgExpiredErrorPartTwo: 'Ota yhteyttä organisaatiosi portaalia hallinnoivaan henkilöön näiden rajojen laajentamiseksi.',
              menuLabel: 'Valikko',
              downloadSourceFileSubheader: 'Lähde',
              downloadTranslatedFileSubheader: 'Tulos',
              dataView: 'Tiedot',
              graphView: 'Kaavio',
              mainTitleStatisticsDataView: 'Organisaation tilastot valitulta ajanjaksolta',
              genericColumnTitleStatisticsDataView: 'Yleinen',
              textTranslationTitleStatisticsDataView: 'Tekstikäännös',
              documentTranslationTitleStatisticsDataView: 'Asiakirjakäännös',
              wordsTranslatedField: 'Käännetyt sanat',
              charsTranslatedField: 'Käännetyt merkit',
              ratioTextDocumentField: 'Tekstikäännöksen / asiakirjakäännöksen suhde',
              mostUsedLangsField: 'Useimmin käytetty kielipari',
              totalTextRequestsField: 'Pyynnöt yhteensä',
              totalDocsTranslatedField: 'Käännetyt asiakirjat yhteensä',
              userColumn: 'Käyttäjä',
              numberOfWordsColumn: 'Sanojen määrä',
              currentQuota: 'Nykyisen kiintiön tila',
              sortTableCancel: "Peruuta lajittelu napsauttamalla",
              sortTableAscending: "Lajittele nousevasti napsauttamalla",
              sortTableDescending: "Lajittele laskevasti napsauttamalla",
              af: 'afrikaans',
              am: 'amhara',
              ar: 'arabia',
              "oc-ES": 'araneesi',
              auto: 'Tunnista kieli',
              eu: 'baski',
              bg: 'bulgaria',
              bn: 'bangla',
              bs: 'bosnia',
              ca: 'katalaani',
              co: 'korsikan kieli',
              cs: 'tšekki',
              cy: 'walesin kieli',
              da: 'tanska',
              dv: 'Divehi',
              ee: 'Ewe',
              gl: 'galicia',
              de: 'saksa',
              el: 'kreikka',
              en: 'englanti',
              "en-CA": 'englanti (Kanada)',
              "en-US": 'englanti (Yhdysvallat)',
              "en-GB": 'englanti (Iso-Britannia)',
              es: 'espanja',
              "es-LA": 'espanja (Latinalainen Amerikka)',
              "es-MX": 'espanja (Meksiko)',
              "es-XM": 'espanja (kansainvälinen)',
              "es-US": 'espanja (Yhdysvallat)',
              et: 'viro',
              fa: 'persia',
              'fa-AF': 'darin kieli',
              fi: 'suomi',
              fo: 'faroese',
              tl: 'filippiino',
              fj: 'fidžin kieli',
              fr: 'ranska',
              "fr-BE": 'ranska (Belgia)',
              "fr-CA": 'ranska (Kanada)',
              "fr-FR": 'ranska (Ranska)',
              he: 'heprea',
              Hans: 'Yksinkertaistettu kiina (translitroinnit)',
              hi: 'hindi',
              hm: 'hmong daw',
              hr: 'kroatia',
              ht: 'haitinkreoli',
              hu: 'unkari',
              hy: 'armenia',
              id: 'indonesia',
              is: 'islanti',
              it: 'italia',
              iu: 'inuktitut',
              'iu-Latn': 'Inuktitut (latinalainen)',
              ja: 'japani',
              Jpan: 'Japani (translitroinnit)',
              km: 'khmer',
              ko: 'korea',
              ka: 'georgian kieli',
              ks: 'Kashmiri',
              "lb-LU": 'luxemburg',
              lo: 'lao',
              lt: 'liettua',
              lv: 'latvia',
              mg: 'malagassi',
              mk: 'makedonia',
              mn: 'mongoli',
              ms: 'malaiji',
              mt: 'maltan kieli',
              my: 'burman kieli',
              multi: 'monikielinen',
              mww: 'hmong daw',
              ne: 'nepali',
              no: 'norja',
              nl: 'hollanti',
              "nl-BE": 'hollanti (Belgia)',
              ns: 'Sepedi',
              oc: 'Satunnainen',
              otq: 'querétaro otomi',
              pl: 'puola',
              pt: 'portugali',
              "pt-PT": 'portugali (Portugali)',
              "pt-BR": 'portugali (Brasilia)',
              ro: 'romania',
              rw: 'kinyarwanda',
              ru: 'venäjä',
              sk: 'slovakki',
              sl: 'slovenia',
              si: 'sinhala',
              sm: 'samoa',
              sr: 'serbia',
              "sr-RS-CYRL": 'serbia (kyrillinen)',
              "sr-RS-LATN": 'serbia (latinalainen)',
              sv: 'ruotsi',
              sw: 'kiswahili',
              ta: 'tamili',
              th: 'thai',
              ti: 'tigrinya',
              to: 'tonga',
              tr: 'turkki',
              tn: 'Setswana',
              ty: 'tahiti',
              tw: 'Twi',
              uk: 'ukraina',
              ur: 'urdu',
              vi: 'vietnam',
              yua: 'jukatek',
              yue: 'kantoninkiina',
              sq: 'albania',
              az: 'azeri',
              be: 'valkovenäjä',
              eo: 'esperanto',
              gd: 'gaeli (Skotlanti)',
              ga: 'gaeli (Irlanti)',
              kn: 'kannada',
              gu: 'gujarati',
              ha: 'hausa',
              la: 'latina',
              mr: 'marathi',
              tg: 'tajik',
              te: 'telugu',
              uz: 'uzbekki',
              xh: 'xhosa',
              yi: 'jiddiš',
              zu: 'zulu',
              cb: 'cebuano',
              fy: 'friisi',
              hw: 'havaiji',
              ig: 'igbo',
              jv: 'jaava',
              kk: 'kazakki',
              kmr: 'kurdi (Pohjoismaat)',
              ku: 'kurdi',
              ky: 'kirgiisi',
              ml: 'malajalam',
              mi: 'maori',
              ny: 'nyanja',
              or: 'odia',
              ps: 'pashto',
              pa: 'punjabi',
              rn: 'Rundi',
              st: 'sesotho',
              sn: 'shona',
              sd: 'sindhi',
              so: 'somali',
              su: 'sundanese',
              tt: 'tataari',
              tk: 'turkmeeni',
              ts:'Tsonga',
              ug: 'uiguuri',
              yo: 'yoruba',
              "zh-CN": 'kiina (yksinkertaistettu)',
              "zh-TW": 'kiina (perinteinen)',
              "zh-HK": 'kiina (Hongkong)'
            }
      },
        'no' : { translations :
              {
                textOk: 'OK',
                FAQ: 'Hjelp',
                English: 'Engelsk',
                Catalan: 'Katalansk',
                Spanish: 'Spansk',
                French: 'Fransk',
                textTitle: 'Tekstoversettelsesside',
                documentTitle: 'Dokumentoversettelsesside',
                loginTitle: 'Logg på',
                statisticTitle: 'Statistikkside',
                textAreaAnnouncementBox1: 'tekstområde der du kan skrive tekst som skal oversettes',
                textAreaAnnouncementBox2: 'tekstområdet der tekstoversettelsen vil vises når oversettelsen er ferdig.',
                AnnounceModalCloseButton: 'Lukk',
                AnnounceDialogOpened: 'Dialogen er åpnet',
                AnnounceDialogClosed: 'Dialogen er lukket',
                AnnounceTextisCleared: 'Teksten er fjernet',
                AnnounceCopyTransactionRecordText: 'Kopier transaksjonsoppføring',
                AnnounceCopiedToClipboard: 'Kopiert til utklippstavlen',
                AnnounceCopyTranslatedText: 'Kopier oversatt tekst',
                ClearTextLabelandTippy: 'Fjern tekst',
                AnnounceTranslatedText: 'Oversatt tekst',
                headerH1: 'Maskinoversettelsestjeneste',
                headerLogout: 'Logg av',
                headerAccountSettings: 'Kontoinnstillinger',
                headerVersion: 'Versjon',
                dropdownWebsiteLanguage: 'angi nettstedsspråk',
                dropdownUserSetting: 'brukerinnstilling',
                dropdownsourceLang: 'Kildespråk',
                dropdowntargetLang: 'Målspråk',
                operations: 'Opprinnelse',
                allOperations: 'Alle',
                textTranslateOperations: 'Tekstoversettelse',
                documentTranslateOperations: 'Dokumentoversettelse',
                speechDocumentOperations: 'Tale til tekst',
                transliteration: 'Translitterasjon',
                websiteTranslateOperations: 'Oversettelse av nettsted',
                speechTranscription: 'Taletranskripsjon',
                words: 'Ord',
                characters: 'Tegn',
                scale: 'Skala',
                total: 'Totalt',
                linearScale: 'Lineær',
                sqrtScale: 'Kvadratrot',
                dropdownSelected: 'er valgt',
                sidebarTippyText: 'Tekstoversetter',
                sidebarText: 'Tekst',
                sidebarDocument: 'Dokument',
                sidebarTippyDocument: 'Dokumentoversetter',
                loginh1: 'Innlogging kreves',
                loginButton: 'Logg på',
                textH2NavigateLanguageTranslationDropDown: 'Velg kilde- og målspråk',
                textTippySwap: 'Bytt språk',
                textTippySwapBlocked: 'Kan ikke bytte ‘Oppdag språk’ ',
                textTippySwapBlockedMulti: 'Kan ikke bytte "flerspråklig"',
                textH2NavigateTextTranslationArea: 'Tekstoversettelsesområde',
                textSourceTextareaPlaceholder: 'Skriv eller lim inn teksten her ...',
                textSourceTextareaButton: 'Velg filer',
                textTranslateButton: 'Oversett',
                textCopyTippy: 'Kopier tekst',
                documentH1: 'Dokumentoversettelse',
                documentDroph2: 'velg dem på datamaskinen din',
                documentDrophMaxSize: 'Maks. filstørrelse <strong>50MB</strong>, ZIP-filer opptil <strong>512MB</strong>',
                dragAndDropTooltip: 'Dra og slipp',
                documentDrophSelect: 'valitse ne tietokoneellasi',
                documentDropFileMessage: 'Støttede filformater: ',
                documentH2: 'Oversettelser',
                documentSubHeaderDate: 'Dato',
                documentSubHeaderSubName: 'Navn',
                documentSubHeaderSource: 'Språk',
                documentSubHeaderTarget: 'Målspråk',
                documentSubHeaderFiles: 'Last ned',
                documentSubHeaderPages: 'Slett',
                submissionNameHeader: "Innsendingsnavn",
                submissionNameMaxLength: 'Innsendingsnavnet kan bestå av opptil 180 tegn',
                fileNameHeader: "Filnavn",
                categoryHeader: "Kategori",
                categoryPlaceholder: "Ingen kategori",
                submissionNamePlaceholder: "Skriv her ...",
                fileStatusSuccessful: "Oversatt",
                downloadFailedError: "Kan ikke laste ned filen fordi oversettelsen mislyktes.",
                downloadingStatisticsMessage: "Oppretter rapporten din, dette kan ta noen minutter...",
                downloadingStatisticsError: "Vi fant en feil under oppretting av rapporten. Prøv igjen eller kontakt kundestøtte.",
                documentSubHeaderActions: "Handlinger",
                documentSubHeaderWCount: "Antall ord:",
                documentSubHeaderCCount: "Antall tegn:",
                documentSubHeaderStatus: 'Status',
                documentSubRowStatusDeleting: 'Sletter ...',
                documentSubRowChildStatusTranslating: 'Oversetter',
                documentSubRowChildStatusTranslated: 'Oversettelse vellykket',
                documentSubRowChildStatusTranslatedSuccess: 'Oversatt',
                documentSubRowChildStatusError: 'Oversettelse mislyktes',
                documentSubRowChildStatusFailed: 'Mislykket',
                documentSubRowChildStatusUploading: 'Laster opp',
                documentSubRowChildDeleteTippy: 'Slett fil',
                documentFileIconTippy: 'Fil',
                documentSubRowChildDownloadTippy: 'Last ned oversatt fil',
                documentSubRowChildDownloadSourceTippy: 'Last ned kildefil',
                documentSubRowChildDownloadHeader: 'Last ned',
                documentSubRowChildDownloadLoadingTippy: "Laster inn nedlasting",
                documentSubRowChildCopyToClipboardTippy: "Kopier til utklippstavlen",
                documentSubRowChildCopiedToClipboardTippy: "Kopiert til utklippstavlen",
                documentSubRowChildFailedTranslationHelpTippy: "Hjelp for mislykket oversettelse",
                documentSubRowChildFailedTranslationHelpCloseTippy: "Lukk hjelp for mislykket oversettelse",
                documentSubRowChildDownloadSourceDropdown: "Last ned som kildeformat",
                documentSubRowChildDownloadMoreOptions: 'Flere nedlastingsalternativer',
                documentSubRowChildExpand: 'Utvid',
                documentSubRowChildCollapse: 'Skjul',
                documentSubRowZipping: 'Zipping',
                documentSubRowZipAndDownload: 'Zip og last ned',
                documentSubRowZipHelp: 'Ziphjelp',
                documentSubRowDownloadZip: 'Last ned zip',
                documentSubRowDownloadZipError: 'Feil ved zipping',
                documentSubRowChildHumanTranslationRequestedTippy: "Menneskelig oversettelse forespurt",
                documentSubRowChildHumanTranslationFailedTippy: "Forespørsel om menneskelig oversettelse mislyktes",
                documentSubRowChildRequestHumanTranslationTippy: "Be om menneskelig oversettelse",
                documentSubRowChildCloseRequestHumanTranslationTippy: "Lukk menyen for menneskelig oversettelse",
                documentSubRowChildRequestHumanTranslationLoadingTippy: "Laster inn forespørsel",
                documentSubRowChildRequestHumanTranslationOpenTransportTippy: "Åpner GL Portal i en ny fane",
                documentSubRowChildHumanTranslationRequestInProcess: "Forespørsel om menneskelig oversettelse pågår.",
                documentSubRowChildHumanTranslationRequestFinishOnTransport: "Fullfør i GL Portal",
                documentSubRowChildHumanTranslationRequest: "Forespør en menneskelig oversettelse",
                documentSubRowChildHumanTranslationRequested: "Menneskelig oversettelse forespurt",
                documentSubRowChildHumanTranslationRequestCheckStatusOnTransport: "Sjekk status på GL Portal",
                documentSubRowChildHumanTranslationRequestGoToTransport: "Gå til TransPort",
                documentSubRowChildHumanTranslationRequestAdditionalCost: " (merkostnad)",
                documentSubRowChildEditTranslatedFile: 'Rediger oversatt fil',
                documentSubRowChildMoreActionsTooltip: 'Flere handlinger',
                helpSentencePartOne: 'For hjelp, klikk ',
                helpSentencePartTwo: 'her',
                helpSentencePartTwoHover: 'Dette vil åpne en ny e-postmelding til Support',
                helpSentencePartThree: ' for å kontakte kundestøtte. Beskriv problemet ditt i brødteksten i e-posten din.',
                documentSubRowChildDownloadPdfDropdown: 'Last ned som pdf',
                documentSubRowChildLanguageTo: 'til',
                documentTippyAdd: 'Velg filer',
                deleteModalTitle: 'Slett fil',
                deleteModalCheck: 'Er du sikker på at du vil slette denne filen?',
                deleteModalCancel: 'Avbryt',
                deleteModalDelete: 'Slett',
                modalH1: 'Last opp filene dine',
                modalH1AfterUpload: 'Filer lastet opp',
                modalPTop: 'Du valgte følgende filer som skal oversettes:',
                modalTippyAdd: 'Legg til flere filer',
                modalConfirm: 'Bekreft kilde- og målspråk',
                modalTippySwap: 'Bytt språk',
                modalTranslate: 'Oversett',
                modalLoading: 'Laster ...',
                modalUploadSuccessful: 'Oversettelse pågår',
                processingRequestMsg: 'Behandler forespørsel ...',

                modalUploadErrorMessagePartOne:'Vi fant en feil ved opplasting av filene dine.',
                modalUploadErrorMessagePartTwo:'Prøv igjen.',
                modalUploadErrorMessagePartThree:'Hvis problemet vedvarer ',
                modalUploadErrorMessagePartFour: 'kontakt støtte.',
                modalMsgEmlAutoDetectError: 'Velg det opprinnelige språket i e-posten din på "Kildespråk" for å oversette det.',
                modalPdfAutodetectOcrError: 'Velg originalspråket for bildene eller PDF-filene dine på "Kildespråk" for å oversette dem.',
                mandatoryCategoryAndSubmissionNameError: 'Velg en kategori og et innsendingsnavn for å fortsette med oversettelsen.',
                mandatorySubmissionNameError: 'Velg et innsendingsnavn for å fortsette med oversettelsen.',
                mandatoryCategoryError: 'Velg en kategori for å fortsette med oversettelsen.\n',
                deleteModalProblem: 'Vi har problemer med å slette filen din. Lukk denne modaliteten og prøv på nytt.',
                unsupportedFileMessage: 'Vi kan ikke oversette én eller flere filer. Konverter dem og prøv på nytt.\n',
                statisticErrorTime: 'Startdatoen ser ut til å være etter sluttdatoen. Velg en startdato før sluttdatoen.',
                statisticErrorFutureDate: 'Startdato ser ut til å være i fremtiden. Velg en startdato før i dag.',
                unsupportedSourceLang: 'Det registrerte språket er ikke tilgjengelig i organisasjonen din. For flere språk, kontakt personen som administrerer portalen i din organisasjon.',
                reachedQuotaLimitError: 'Organisasjonen din har nådd sine oversettelsesgrenser. Kontakt personen som administrerer portalen i din organisasjon for å utvide disse grensene.',
                orgExpiredError: 'Du kan ikke oversette fordi organisasjonen din har nådd utløpsdatoen. Kontakt personen som administrerer portalen i din organisasjon for å utvide disse grensene.',
                textTranslationError: 'Oversettelse mislyktes. Støtte er tilgjengelig for hjelp.',
                statisticRequestError:'Vi fant en feil under oppretting av rapporten din, vennligst prøv igjen.',
                downloadPdfError: 'Vi kan ikke konvertere det opplastede filformatet til pdf.',
                noDataError: 'Vi fant ingen oversettelser mellom disse datoene. Hvis du tror det er en feil støtte tilgjengelig.',
                'AP-12-001': 'Filen ser ut til å være tom, kontroller og prøv på nytt.',
                'AP-12-001-desc': 'Filen ser ut til å være tom, kontroller og prøv på nytt.',
                'AP-12-001-desc-pdf':'Ingen tekst ble oppdaget. Prøv igjen og sørg for at OCR er aktivert.',
                'AP-01-003-desc': 'Filen overskrider ordgrensene for organisasjonen din. Del den i mindre biter og prøv igjen.',
                'WFM-04-SRCTRGSAME': 'Kilde- og målspråk kan ikke være det samme. Endre en av dem',
                'WFM-04-SRCTRGSAME-desc':'Kilde- og målspråk kan ikke være det samme. Endre en av dem',
                'WFM-04-SRCTRGSAME-desc-pdf' : 'Kilde- og målspråk kan ikke være det samme. Endre en av dem',
                'USRMNG-DB-1062': 'Denne brukeren finnes allerede. Søk etter det og foreta de tilsvarende endringene.',
                'STT-DOC-04-SIZE': 'Maksimal filstørrelse ] er 200MB. Del filen og prøv på nytt.',
                'STT-fileSIZE': 'Du kan oversette filer på opptil 50MB. Fjern større filer, del dem inn i mindre filer og prøv igjen.',
                'STT-fileSIZE-zip': 'Du kan oversette zip-filer opptil 512MB. Fjern filer fra ZIP-en din og prøv igjen',
                TT_WAV_ERROR_MULTIPLE_FILES: 'Last opp en WAV-fil på det tidspunktet eller prøv dokumentoversettelse for å oversette den',
                TT_WAV_ERROR_AUTO_DETECT:'Velg det opprinnelige språket for WAV-filene dine på "Kildespråk" for å oversette dem.',
                TT_WAV_ERROR_FILE_TYPE:'Bruk dokumentoversettelse for å oversette denne typen fil',
                TT_WAV_ERROR_FILE_SIZE: 'Maksimal filstørrelse er 200MB. Del filen og prøv på nytt',
                'FILE-UPLOADS-CONTAIN-PDF-AND-WAV':'Oversett PDF- og WAV-filer separat',
                'WFM-error': 'Vi fant en feil under behandling av filen din. Prøv på nytt',
                'WFM-error-desc': 'Vi fant en feil under behandling av filen din. Prøv på nytt',
                'WFM-04-INVLANG': 'Det registrerte språket er ikke tilgjengelig i organisasjonen din. For flere språk, kontakt personen som administrerer portalen i din organisasjon.',
                'WFM-04-INVLANG-desc': 'Det registrerte språket er ikke tilgjengelig i organisasjonen din. For flere språk, kontakt personen som administrerer portalen i din organisasjon.',
                'OCR-03-A2A-FAIL': 'Vi fant en feil ved overføring av filen din. Prøv igjen',
                'OCR-03-A2A-TIMEOUT': 'Vi fant en feil ved overføring av filen din. Prøv igjen',



                copyright: ' Translations.com Med enerett.',
                statistics: 'Statistikk',
                statisticFrom: 'Fra:',
                statisticTo: 'Til:',
                statisticDownload: 'Last ned rapport',
                textTranslationReachedLimit: 'Du kan oversette opptil 10 000 tegn per forespørsel',
                users: 'Bruker',
                allUsers: 'Alle',
                allLanguages: 'Alle',
                loadTimeFrame: 'Tidsramme for innlasting',
                "OCR-03-A2A-PASSWORD": 'Feil, fjern passord fra pdf',
                "OCR-03-A2A-PASSWORD-desc-pdf": 'PDF er passordbeskyttet, fjern passordet fra pdf',
                "AP-08-001": 'Feil, fjern passord fra pdf',
                "AP-08-001-desc-pdf": 'PDF er passordbeskyttet, fjern passordet fra pdf',
                "OCR-03-A2A-SIZE-EX": 'Maks. sidestørrelse for å utføre pdf-konvertering er A4',
                quickLinksLabel: 'Hurtigkoblinger:',
                "OCR-03-A2A-HEALTH": 'OCR midlertidig utilgjengelig, prøv igjen',
                "MT-error": 'Feil med oversettelsesmotoren',
                "MT-error-desc": 'Feil med oversettelsesmotoren',
                "USRMNG-DB-1452": 'Ugyldig verdi',
                TT_WAV_ERROR_NO_FILES_DETECTED: 'Vi kunne ikke oppdage WAV-filer',
                TT_WAV_TRANSCRIPTION_STILL_PROCESSING_ALERT: 'Transkripsjon behandles fremdeles. Transkripsjonen vil gå tapt hvis du avslutter denne siden nå.',
                qualityTranslationEngineInfo: "TransPerfect hovedmotor lar deg bli informert om hvor troverdig oversettelsen vår har i henhold til dine krav. Test det!",
                infoQualityControlFeature: "Kvalitetskontroll er funksjonen som gjør at du kan bli informert om nivået av troskap som vår oversettelse har i henhold til dine behov. Nivået av troskap er definert som nøyaktigheten av oversettelsen vi gir deg som angitt av dine krav.",
                transliterationTippy: 'Overfør tekst',
                infoQualityControlFeatureTooltip: 'For å sikre kvaliteten og gjennomsiktigheten til produktet vårt, lar TransPerfect hovedmotor deg bli informert om hvor troverdig oversettelsen vår har i henhold til dine behov.',
                infoQualityControlFeatureTooltipLearnMore: 'Lær mer',
                translationQualityGood: 'Oversettelse ser bra ut!',
                translationQualityAverage: 'Oversettelse kan ha noen feil, bruk med forsiktighet!',
                translationQualityBad: 'Oversettelse er sannsynligvis problematisk, revisjon anbefales.',
                translationQualityGoodModal: 'Bra',
                translationQualityAverageModal: 'Brukes med forsiktighet',
                translationQualityBadModal: 'Sannsynlig problematisk',
                translationQualityGoodModalDescription: 'Du kan stole på denne oversettelsen.',
                translationQualityAverageModalDescription: 'Det kan være noen ufullkommenheter i oversettelsen. Vi anbefaler bruk internt. For ekstern bruk anbefaler vi at du sjekker det med en profesjonell oversetter.',
                translationQualityBadModalDescription: 'Denne oversettelsen kan ha noen store feil. Vi anbefaler deg å korrigere det med en profesjonell oversetter.',
                websiteTranslator: 'Nettsted',
                websiteTranslatorTippy: 'Nettstedsoversetter',
                urlInputPlaceHolder: 'Lim inn URL-adressen din her ...',
                websiteTranslateAction: 'Oversett',
                removeAllPreviousWebsiteTranslateRequests: 'Fjern alle',
                previousWebsiteTranslateRequestTitle: 'Forespørselshistorikk:',
                wtLoadingCaption: 'Oversetter nettsted ...',
                ocrCheckboxLabel: 'Jeg laster opp bilder og skannede dokumenter',
                ocrHelpText: 'Når dette skal aktiveres',
                ocrCheckboxTippy: 'OCR (optisk tegngjenkjenning) oppdager tekst i bilder og skannede dokumenter. Prøv dette alternativet hvis du har mottatt en tom oversettelse, eller hvis tekstinnholdet i pdf-filen ikke kan velges.',
                websitePage: 'Nettstedsoversettelsesside',
                lastWTRequestURL: 'Siste forespørsel om oversettelse av nettsted:',
                openWebsiteBtnLabel: 'Åpne',
                invalidURL: 'Ugyldig URL-format',
                ClearURLLabelandTippy: 'Fjern URL',
                inactivityLogoutTitle: 'Du har blitt logget ut',
                inactivityLogoutMessage: 'Du har blitt logget ut på grunn av inaktivitet',
                inactivityLogoutButtonLabel: 'Logg på igjen',
                logoutScreenMessage: 'Takk for at du kom innom!',
                logoutScreenLogoutButtonLabel: 'Logg på igjen',
                langSelect_NoResultsMessage: 'Ingen resultater funnet',
                reachedQuotaLimitErrorPartOne: 'Du kan ikke oversette fordi organisasjonen din har nådd sine oversettelsesgrenser.',
                reachedQuotaLimitErrorPartTwo: 'Kontakt personen som administrerer portalen i organisasjonen din for å utvide dette.',
                orgExpiredErrorPartOne: 'Du kan ikke oversette fordi organisasjonen din har nådd utløpsdatoen.',
                orgExpiredErrorPartTwo: 'Kontakt personen som administrerer portalen i organisasjonen din for å utvide disse grensene.',
                menuLabel: 'Meny',
                downloadSourceFileSubheader: 'Kilde',
                downloadTranslatedFileSubheader: 'Resultat',
                dataView: 'Data',
                graphView: 'Graf',
                mainTitleStatisticsDataView: 'Organisasjonsstatistikk for valgt periode',
                genericColumnTitleStatisticsDataView: 'Generisk',
                textTranslationTitleStatisticsDataView: 'Tekstoversettelse',
                documentTranslationTitleStatisticsDataView: 'Dokumentoversettelse',
                wordsTranslatedField: 'Ord oversatt',
                charsTranslatedField: 'Oversatte tegn',
                ratioTextDocumentField: 'Tekstoversettelse/dokumentoversettelsesforhold',
                mostUsedLangsField: 'Mest brukte språkpar',
                totalTextRequestsField: 'Totalt antall forespørsler',
                totalDocsTranslatedField: 'Totalt antall dokumenter oversatt',
                userColumn: 'Bruker',
                numberOfWordsColumn: 'Antall ord',
                currentQuota: 'Gjeldende kvotestatus',
                sortTableCancel: "Klikk for å avbryte sortering",
                sortTableAscending: "Klikk for å sortere stigende",
                sortTableDescending: "Klikk for å sortere synkende",
                af: 'Afrikansk',
                am: 'Amharisk',
                ar: 'Arabisk',
                "oc-ES": 'Aranesisk',
                auto: 'Oppdag språk',
                eu: 'Baskisk',
                bg: 'Bulgarsk',
                bn: 'Bangla',
                bs: 'Bosnisk',
                ca: 'Katalansk',
                co: 'Korsikansk',
                cs: 'Tsjekkisk',
                cy: 'Walisisk',
                da: 'Dansk',
                dv: 'Divehi',
                ee: 'Ewe',
                gl: 'Galisisk',
                de: 'Tysk',
                el: 'Gresk',
                en: 'Engelsk',
                "en-CA": 'Engelsk (Canada)',
                "en-US": 'Engelsk (USA)',
                "en-GB": 'Engelsk (Storbritannia)',
                es: 'Spansk',
                "es-LA": 'Spansk (Latin-Amerika)',
                "es-MX": 'Spansk (Mexico)',
                "es-XM": 'Spansk (Internasjonal)',
                "es-US": 'Spansk (USA)',
                et: 'Estisk',
                fa: 'Persisk',
                'fa-AF': 'Dari',
                fi: 'Finsk',
                fo: 'Færøysk',
                tl: 'Filippinsk',
                fj: 'Fijiansk',
                fr: 'Fransk',
                "fr-BE": 'Fransk (Belgia)',
                "fr-CA": 'Fransk (Canada)',
                "fr-FR": 'Fransk (Frankrike)',
                he: 'Hebraisk',
                Hans: 'Kinesisk forenklet (transliterasjon)',
                hi: 'Hindi',
                hm: 'Hmong Daw',
                hr: 'Kroatisk',
                ht: 'Haitisk kreolsk',
                hu: 'Ungarsk',
                hy: 'Armensk',
                id: 'Indonesisk',
                is: 'Islandsk',
                it: 'Italiensk',
                iu: 'Inuktitut',
                'iu-Latn': 'Inuktitut (Latinsk)',
                ja: 'Japansk',
                Jpan: 'Japansk (transliterasjon)',
                km: 'Khmer',
                ko: 'Koreansk',
                ka: 'Georgisk',
                ks: 'Kashmiri',
                "lb-LU": 'Luxembourgsk',
                lo: 'Laotisk',
                lt: 'Litauisk',
                lv: 'Latvisk',
                mg: 'Gassisk',
                mk: 'Makedonsk',
                mn: 'Mongolsk',
                ms: 'Malayisk',
                mt: 'Maltesisk',
                my: 'Burmesisk',
                multi: 'Flerspråklig',
                mww: 'Hmong Daw',
                ne: 'Nepali',
                no: 'Norsk',
                nl: 'Nederlandsk',
                "nl-BE": 'Nederlandsk (Belgia)',
                ns: 'Sepedi',
                oc: 'Occitan',
                otq: 'Querétaro stomi',
                pl: 'Polsk',
                pt: 'Portugisisk',
                "pt-PT": 'Portugisisk (Portugal)',
                "pt-BR": 'Portugisisk (Brasil)',
                ro: 'Rumensk',
                rw: 'Kinyarwanda',
                ru: 'Russisk',
                sk: 'Slovakisk',
                sl: 'Slovensk',
                si: 'Singalesisk',
                sm: 'Samoansk',
                sr: 'Serbisk',
                "sr-RS-CYRL": 'Serbisk (Kyrillisk)',
                "sr-RS-LATN": 'Serbisk (Latinsk)',
                sv: 'Svensk',
                sw: 'Kiswahili',
                ta: 'Tamilsk',
                th: 'Thai',
                ti: 'Tigrinya',
                to: 'Tongansk',
                tn: "Setswana",
                tr: 'Tyrkisk',
                ty: 'Tahitisk',
                tw: 'Twi',
                uk: 'Ukrainsk',
                ur: 'Urdu',
                vi: 'Vietnamesisk',
                yua: 'Yukatansk maya',
                yue: 'Kantonesisk',
                sq: 'Albansk',
                az: 'Aserbajdsjansk',
                be: 'Hviterussisk',
                eo: 'Esperanto',
                gd: 'Gælisk (Skottland)',
                ga: 'Gælisk (Irland)',
                kn: 'Kannada',
                gu: 'Gujarati',
                ha: 'Hausa',
                la: 'Latinsk',
                mr: 'Marathi',
                tg: 'Tadsjikisk',
                te: 'Telugu',
                uz: 'Usbekisk',
                xh: 'Xhosa',
                yi: 'Jiddisch',
                zu: 'Zulu',
                cb: 'Cebuano',
                fy: 'Frisisk',
                hw: 'Hawaiisk',
                ig: 'Igbo',
                jv: 'Javanesisk',
                kk: 'Kasakhisk',
                kmr: 'Kurdisk (Nord)',
                ku: 'Kurdisk',
                ky: 'Kirgisisk',
                ml: 'Malayalam',
                mi: 'Maori',
                ny: 'Nyanja',
                or: 'Odia',
                ps: 'Pashto',
                pa: 'Panjabi',
                rn: 'Rundi',
                st: 'Sotho',
                sn: 'Shona',
                sd: 'Sindhi',
                so: 'Somalisk',
                su: 'Sudansk',
                tt: 'Tatarisk',
                tk: 'Turkmensk',
                ug: 'Uigurisk',
                yo: 'Joruba',
                ts:'Tsonga',
                "zh-CN": 'Kinesisk (Forenklet)',
                "zh-TW": 'Kinesisk (Tradisjonell)',
                "zh-HK": 'Kinesisk (Hongkong)'
              }
            }
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'ca', 'es', 'fr', 'fr-CA', 'de', 'zh-CN', 'ja', 'ko', 'sv', 'da', 'fi', 'no'], // limiting i18n to only allow en, ca and es. All other localizations are defaulted to fallback
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    //test for no key fouund
    parseMissingKeyHandler: (key) => {
      return `common error`;
    },
    react: {
      wait: true,
    },
  });

export default i18n;
