import React from "react";
import Tippy from "@tippyjs/react";
import Spinner from "../../../icons/SpinnerComponent";
import ZipAndDownloadFolderIconComponent from "../../../icons/ZipAndDownloadFolderIconComponent";
import ZippingErrorIconComponent from "../../../icons/ZippingErrorIconComponent";
import DownloadZippedFolderIconComponent from "../../../icons/DownloadZippedFolderIconComponent";
import {htmlDecode} from "../../../utils/htmlDecode"

const style = {
    outside: {
        width: "100%",
        display: "flex",
        fontWeight: "bold",
        height: "1em",
        textAlign: "left",
        lineHeight: 1,
    },
    colSizeXXL: {
        width: "33.35%",
        paddingRight: "16px"
    },
    colSizeXL: {
        width: "25.4%",
        paddingRight: "16px"
    },
    colSizeXLNoPadding: {
        width: "25.4%",
        paddingRight: "16px"
    },
    colSizeXS: {
        width: "8.48%",
        paddingRight: "16px"
    },
};

const SubmissionContent = ({
                               submission,
                               props,
                               zipAndDownloadSubmission,
                               downloadSubmission,
                               loadingDownloadSubmission,
                               zipPossible,
                               submissionName,
                               submissionsStatus,
                               showCategory
                           }) => {


    return (
        <>
            <tr style={style.outside}>
            <td style={showCategory ? style.colSizeXL : style.colSizeXXL}>{props.t('submissionNameHeader')}
                <span style={{fontWeight: 400}}> {htmlDecode(submissionName)}</span>
            </td>
                {showCategory &&
                    <td style={style.colSizeXS}></td>
                }
                <td style={style.colSizeXS}></td>
                <td style={style.colSizeXL}></td>
                <td style={style.colSizeXS}></td>
                <td style={style.colSizeXS}></td>

                {submissionsStatus === "processing" || loadingDownloadSubmission && (
                        <td style={style.colSizeXS}>
                            <div className="centered-text-cell">
                             <Tippy content={props.t('documentSubRowZipping')}>
                                 <Spinner accessibility={props.t("documentSubRowZipping")} size={24} color={"black"}/>
                             </Tippy>
                            </div>
                         </td>
                    ) || (
                        // no zip no download
                    submissionsStatus === "" &&
                        (
                            //e.stopPropagation() to avoid expanding the submission row every time the button is clicked
                            <td style={style.colSizeXS} onClick={e => e.stopPropagation()}>
                                <div className="centered-text-cell">
                                 <Tippy content={props.t('documentSubRowZipAndDownload')}>
                                     {zipPossible ?  
                                        <div className="svgStyledOnHoverSubmissionName" onClick={() => zipAndDownloadSubmission(submission)}> 
                                            <ZipAndDownloadFolderIconComponent accessibility={props.t('documentSubRowZipAndDownload')} disabled={false}  />
                                        </div> :  
                                        <div className="svgStyledOnHoverSubmissionName"> 
                                            <ZipAndDownloadFolderIconComponent accessibility={props.t('documentSubRowZipAndDownload')} disabled={true} /> 
                                        </div>}
                                 </Tippy>
                                </div>
                             </td>
                        )
                    )
                    ||
                    // downloaded zip
                    (submissionsStatus === "zipped" &&
                        <td style={style.colSizeXS} onClick={e => e.stopPropagation()}>
                            <div className="centered-text-cell">
                            <Tippy content={props.t('documentSubRowDownloadZip')}>
                                <div className="svgStyledOnHoverSubmissionName" onClick={() => downloadSubmission(submission)}>
                                    <DownloadZippedFolderIconComponent accessibility={props.t('documentSubRowDownloadZip')} className="downloadAsZipButton"/>
                                </div>
                            </Tippy>
                            </div>
                        </td>
                    )
                    ||
                    // zip error
                    (submissionsStatus === "error" &&
                        <td style={style.colSizeXS} onClick={e => e.stopPropagation()}>
                            <div className="centered-text-cell">
                            <Tippy content={props.t('documentSubRowDownloadZipError')}>
                                <div className="svgStyledOnHoverSubmissionNameDisabled">
                                    <ZippingErrorIconComponent accessibility={props.t('documentSubRowDownloadZipError')} color={"#8A8A8A"}/>
                                </div>
                            </Tippy>
                            </div>
                        </td>)
                }
            </tr>
        </>
    );
};

export default SubmissionContent;