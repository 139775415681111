import React from "react";
import SubHeader from "../../../components/CHSubHeader";
import {getLangName} from "../../../components/LanguageSelection/LanguageStrings";
import SubmissionRow from "./submissionRow";

const DocumentTable = ({ getStatus, toggleConfirmation, props, showCategory, checkIfSubmissionContainsOnlyTranscriptions }) => {

    const isZipPossible = (files) => {
        let allDoneOrErrored = files.every(file => {
            return file.status === 'Translated' || file.status === 'TranslatedConverted' || file.status === 'Error'
        })
        let atLeastOneTranslated = files.some(file => file.status === 'Translated' || file.status === 'TranslatedConverted')
        return allDoneOrErrored && atLeastOneTranslated
    }

    const buildDate = (date) => {
        const d = new Date(date);
        let day = d.getDate();
        const month = d.toLocaleString('default', { month: 'short' });
        const hour = d.getHours();
        const minutes = String(d.getMinutes()).padStart(2, "0");

        return day + month + " - " + hour + ":" + minutes;
    }

    return (
        //transcriptions must not be included in the table
        <>
            <table style={{ width: '100%' }}>
                {props.store.userStatus.submissions.length > 0 && !checkIfSubmissionContainsOnlyTranscriptions() &&
                    <SubHeader showCategory={showCategory}></SubHeader>
                }
                <tbody className="documentTableBody">
                    {props.store.userStatus.submissions.map((el) => {
                            let subInfo = {
                                date: el.date,
                                submissionId: el.submissionId,
                                submissionName: el.submissionName,
                                category: el.category,
                                orgId: props.store.user.organizationId,
                                userId: props.store.user.id,
                                instance: window.location.hostname
                            }
                            el.files = el.files.map((item) => ({ ...item, subInfo }));
                            let zipPossible = isZipPossible(el.files);

                            let date = buildDate(el.date);
                            // in case of no submission name, a string like '26Oct - 17:46 - English to Spanish' will be displayed
                            let submissionName =  date + " - " + getLangName(el.sourceLang) + " " + props.t('documentSubRowChildLanguageTo') + " " + getLangName(el.targetLang);
                            if(el.submissionName){
                                submissionName = el.submissionName;
                            }

                        return <SubmissionRow key={el.submissionId} submission={el} props={props} showCategory={showCategory} getStatus={getStatus} toggleConfirmation={toggleConfirmation} zipPossible={zipPossible} submissionName={submissionName}/>
                    }
                    )}
                </tbody>
            </table>

        </>
    );
};

export default DocumentTable;