import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { GLAI_URL } from '../../utils/config';
import getFAQFile from "../../apiCalls/components/HeaderButton/getFAQFile";

class HeaderButton extends Component {
  state = {
    showBorder: false,
  };

  toShowBorder = () => {
    this.setState({ showBorder: true });
    if (
      !this.props.faqFileIds ||
      typeof this.props.faqFileIds !== 'object' ||
      this.props.faqFileIds === null
    ) {
      const url = "https://onlinehelp.transperfect.com/GLNow/GlobalLink_Now_Help.htm"; // general online help pages
      window.open(url);
    } else {
      let lang =
        this.props.lang in this.props.faqFileIds ? this.props.lang : 'en';
      var filename = undefined;
      var url = `${GLAI_URL}/apigateway/storage/${this.props.faqFileIds[lang]}?fileType=faq_file`; // customized org pdf
      getFAQFile(url)
        .then((res) => {
          filename = res.headers.get('Filename');
          return res.blob();
        })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          if (filename == null) throw new Error('File is null');
          let decoded = decodeURIComponent(filename.replace(/\+/g, ' '));
          a.download = decoded || this.props.faqFileIds[lang];
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the e
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  handleClickOutside = () => {
    this.setState({ showBorder: false });
  };
  render() {
    return (
      <button
        className={
          this.state.showBorder ? 'header-button-onClick' : 'header-button'
        }
        style={{ visibility: this.props.isDropdownVisible ? 'visible': 'hidden', color: this.props.colors.textColor}}
        onClick={this.toShowBorder}
        onBlur={this.handleClickOutside}
      >
        {this.props.buttonName}
      </button>
    );
  }
}
const mapStateToProps = (state) => ({
  faqFileIds: state.user.organization.uiAssets.faqFileIds,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HeaderButton)
);
